import { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Question from 'components/modals/Question'
import SnackBar from 'components/misc/SnackBar'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//providers
import { useAuthDataContext } from 'providers/Auth'

//partials
import Filter from './partials/Filter'
import Options from './partials/Options'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//modals
import Add from './partials/Add'

//images
import emailDarkImage from 'assets/img/settings/icons/email-dark.png'

let timeout
function List() {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const deleteModalRef = useRef(null)
    const addOrEditModalRef = useRef(null)
    const snackBarSuccessRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
            company_id: query.get('company_id') || auth.getUser()?.getCompany()?.id,
            search: query.get('search') || ''
        },
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                company_id: query.get('company_id') || prev.filter.company_id || '',
                search: query.get('search') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1])
                // }
            })

            history.push('?' + query.toString())
        }
    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh])

    const loadData = () => {
        if (!state.filter.company_id) return

        setLoading(true)

        let url = 'users/all?resource=1'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: new Date().getTime()
        }))
    }

    const handleAdd = () => {
        addOrEditModalRef.current.add()
    }

    // update
    const handleEdit = id => {
        history.push(`/settings?menu=6&inner_page=1&user_id=${id}`)
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            setLoading(true)
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('users/delete', {
            id
        })
            .then(() => {
                snackBarSuccessRef.current.show()

                //Зареждането спира след loadData заявката
                refresh()
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }))
    }

    return (
        <section id="users">
            <Add
                ref={addOrEditModalRef}
                companyId={state.filter.company_id}
                refresh={refresh}
            />
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"

            />
            <SnackBar ref={snackBarSuccessRef} severity="success">
                Успешно премахнахте потребител!
            </SnackBar>

            <div id="head">
                <div className="title">
                    Потребители
                </div>
                <Button
                    className="add"
                    onClick={handleAdd}
                >
                    Добави
                </Button>
            </div>

            <hr />

            <Filter
                filter={state.filter}
                handleSearch={handleSearch}
            />

            {!state.filter.company_id
                ?
                <NoSelectedCompany />
                :
                state.loading
                    ?
                    <Skeleton count={5} height={60} />
                    :
                    state.data.length === 0
                        ?
                        <Alert severity="error">
                            Все още нямате добавени потребители!
                        </Alert>
                        :
                        <>
                            <ul className="users-list">
                                {state.data.map(c =>
                                    <li
                                        key={`u_${c.id}`}
                                        onClick={e => {
                                            if (e.target) {
                                                if (e.target.classList?.contains('MuiButton-label') || e.target.classList?.contains('MuiButtonBase-root') || e.target.getAttribute('aria-hidden') === 'true') return
                                            }

                                            handleEdit(c.id)
                                        }}
                                    >
                                        <picture>
                                            <img src={c.avatar} />
                                        </picture>
                                        <div className="main">
                                            {c.role?.translation.name ?
                                                <span className="role">
                                                    {c.role.translation.name}
                                                </span>
                                                :
                                                <>
                                                </>
                                            }
                                            <h6>
                                                {c.name}
                                            </h6>
                                        </div>
                                        <div className="mail">
                                            <span>
                                                <img src={emailDarkImage} />
                                                Е-поща
                                            </span>
                                            <p>
                                                {c.email}
                                            </p>
                                        </div>
                                        <div className="companies">
                                            <span>
                                                Достъп до фирми
                                            </span>
                                            <ul>
                                                {c.companies.map((cs, i) =>
                                                    i <= 2 ?
                                                        <li key={cs.id}>
                                                            {i === 2 ? `И още ${(c.companies?.length || 0) - 2} друг${((c.companies?.length || 0) - 2) === 1 ? 'а' : 'и'}` : cs.info.name}
                                                        </li>
                                                        :
                                                        <>
                                                        </>
                                                )}
                                            </ul>
                                        </div>
                                        <MenuOptions>
                                            <Options
                                                data={c}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                            />
                                        </MenuOptions>
                                    </li>
                                )}
                            </ul>
                            <Pagination
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </>
            }
        </section >
    )
}

export default List
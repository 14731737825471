import { useEffect, useRef } from "react"
import { Button } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import Api from "helpers/Api"
import { useNestedState } from "helpers/NestedState"
import { useValidation } from "helpers/Validation"
import Layout from "../../Layout"
import Autocomplete from "../../misc/Autocomplete"
import SaveButton from '../../misc/Button'
import { CUSTOMER_TYPE } from 'constants/Customer'
import MenuItem from '@material-ui/core/MenuItem'
import Success from '../../modals/Success'
import Error from '../../modals/Error'
import { useQuery } from "helpers/Url"
import RedditSelect from "components/misc/RedditSelect"
import RedditTextField from "components/misc/RedditTextField"

function Index() {
    const params = useParams()
    const query = useQuery()
    const history = useHistory()

    const successModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const [state, setState] = useNestedState({
        loading: false,
        data: {
            type_id: CUSTOMER_TYPE.COMPANY,
            addresses: []
        }
    });

    const [validations, setValidations] = useValidation();

    const addMode = !params.id ? true : false;
    const editMode = !addMode;

    if (addMode) {
        if (!query.get('company_id')) history.goBack()
    }

    useEffect(() => {
        if (params.id) loadData()
    }, [params.id])

    // ако няма добавен ред за адреси, винаги да създава 1 празен ред
    useEffect(() => {
        if (!state.data.addresses || (state.data.addresses && state.data.addresses.length === 0)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: [
                        {

                        }
                    ]
                }
            }));
        }
    }, [state.data.addresses])

    const loadData = () => {
        if (!params.id) return

        Api.get('customers/show?id=' + params.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 404) history.push('/customers')
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    const handleAddAddress = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: prev.data.addresses.concat({})
            }
        }));
    }

    const handleRemoveAddress = index => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: prev.data.addresses.filter((row, i) => i !== index)
            }
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        const url = params.id ? 'customers/edit' : 'customers/add';

        const data = new FormData(e.target);

        if (params.id) data.append('id', params.id)

        if (addMode) data.append('company_id', query.get('company_id'))

        Api.post(url, data)
            .then(res => {
                const modal = successModalRef.current

                modal.open()

                modal.onSuccess(() => {
                    history.push('/customers?company_id=' + res.data.company_id);
                })
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else {
                    const modal = errorModalRef.current

                    modal.open()

                    modal.onSuccess(() => {
                        //
                    })
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }))
            })
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data');
    }

    const handleCountryChange = (data, index) => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.addresses[index]?.country?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: Object.values({
                        ...prev.data?.addresses,
                        [index]: {
                            ...prev.data?.addresses[index],
                            district: null,
                            municipality: null,
                            settlement: null,
                            area: null,
                            street: null,
                        }
                    })
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: Object.values({
                    ...prev.data?.addresses,
                    [index]: {
                        ...prev.data?.addresses[index],
                        country: typeof data === 'object' ? data : {
                            id: null,
                            name: data
                        },
                    }
                })
            }
        }));

    }

    const handleDistrictChange = (data, index) => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.addresses[index]?.district?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: Object.values({
                        ...prev.data?.addresses,
                        [index]: {
                            ...prev.data?.addresses[index],
                            municipality: null,
                            settlement: null,
                            area: null,
                            street: null,
                        }
                    })
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: Object.values({
                    ...prev.data?.addresses,
                    [index]: {
                        ...prev.data?.addresses[index],
                        district: typeof data === 'object' ? data : {
                            id: null,
                            name: data
                        },
                    }
                })
            }
        }));

    }

    const handleMunicipalityChange = (data, index) => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.addresses[index]?.municipality?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: Object.values({
                        ...prev.data?.addresses,
                        [index]: {
                            ...prev.data?.addresses[index],
                            settlement: null,
                            area: null,
                            street: null,
                        }
                    })
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: Object.values({
                    ...prev.data?.addresses,
                    [index]: {
                        ...prev.data?.addresses[index],
                        municipality: typeof data === 'object' ? data : {
                            id: null,
                            name: data
                        },
                    }
                })
            }
        }));
    }

    const handleSettlementChange = (data, index) => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.addresses[index]?.settlement?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: Object.values({
                        ...prev.data?.addresses,
                        [index]: {
                            ...prev.data?.addresses[index],
                            area: null,
                            street: null,
                        }
                    })
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: Object.values({
                    ...prev.data?.addresses,
                    [index]: {
                        ...prev.data?.addresses[index],
                        settlement: typeof data === 'object' ? data : {
                            id: null,
                            name: data
                        },
                    }
                })
            }
        }));
    }

    const handleAreaChange = (data, index) => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.addresses[index]?.area?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: Object.values({
                        ...prev.data?.addresses,
                        [index]: {
                            ...prev.data?.addresses[index],
                            street: null,
                        }
                    })
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: Object.values({
                    ...prev.data?.addresses,
                    [index]: {
                        ...prev.data?.addresses[index],
                        area: typeof data === 'object' ? data : {
                            id: null,
                            name: data
                        },
                    }
                })
            }
        }));
    }

    const handleStreetChange = (data, index) => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.addresses[index]?.street?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: Object.values({
                        ...prev.data?.addresses,
                        [index]: {
                            ...prev.data?.addresses[index],
                        }
                    })
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: Object.values({
                    ...prev.data?.addresses,
                    [index]: {
                        ...prev.data?.addresses[index],
                        street: typeof data === 'object' ? data : {
                            id: null,
                            name: data
                        },
                    }
                })
            }
        }));
    }

    return (
        <>
            <Success
                mainMessage="Действието е успешно!"
                ref={successModalRef}
            />
            <Error
                ref={errorModalRef}
                mainMessage="Нещо се обърка!"
            />
            <Layout>
                <section id="documents">
                    <form noValidate onSubmit={handleSave}>
                        <div id="head">
                            <div className="title">
                                Клеинт
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="col"
                            >
                                <div
                                    className="panel"
                                >
                                    <div className="heading">
                                        Персонални данни
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <RedditSelect
                                                name="type_id"
                                                value={state.data?.type_id}
                                                onChange={handleInputChange}
                                                label="Тип"
                                            >
                                                <MenuItem value={CUSTOMER_TYPE.COMPANY}>
                                                    Юридическо лице
                                                </MenuItem>
                                                <MenuItem value={CUSTOMER_TYPE.PRIVATE}>
                                                    Физическо лице
                                                </MenuItem>
                                            </RedditSelect>
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                variant="outlined"
                                                margin="dense"
                                                size="small"
                                                fullWidth
                                                label="Наименование"
                                                name="info[name]"
                                                value={state.data?.info?.name || ''}
                                                onChange={handleInputChange}
                                                error={Boolean(validations && validations.info?.name)}
                                                helperText={validations && validations.info?.name[0]}
                                                autoFocus
                                            />
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                variant="outlined"
                                                margin="dense"
                                                size="small"
                                                fullWidth
                                                label="ЕИК"
                                                name="info[eikegn]"
                                                value={state.data?.info?.eikegn || ''}
                                                onChange={handleInputChange}
                                                error={Boolean(validations && validations.info?.eikegn)}
                                                helperText={validations && validations.info?.eikegn[0]}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <RedditTextField
                                                variant="outlined"
                                                margin="dense"
                                                size="small"
                                                fullWidth
                                                label="Шифър в Ажур"
                                                name="ajur_id"
                                                value={state.data?.ajur_id || ''}
                                                onChange={handleInputChange}
                                                error={Boolean(validations && validations.ajur_id)}
                                                helperText={validations?.ajur_id && validations.ajur_id[0]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                {Array.isArray(state.data.addresses)
                                    ?
                                    state.data.addresses.map((address, index) =>
                                        <div
                                            className="panel"
                                            key={'addr-' + index}
                                        >
                                            <div className="heading">
                                                {CUSTOMER_TYPE.COMPANY === state.data?.type_id ? 'Офис' : 'Адрес'} {index + 1}
                                                {index !== 0 ?
                                                    <Button
                                                        className="small remove"
                                                        size="small"
                                                        onClick={() => {
                                                            handleRemoveAddress(index)
                                                        }}
                                                        style={{
                                                            marginLeft: '10px'
                                                        }}
                                                    >
                                                    </Button>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div>
                                            <input
                                                type="hidden"
                                                name={`addresses[${index}][id]`}
                                                value={address?.id || ''}
                                            />
                                            {CUSTOMER_TYPE.COMPANY === state.data?.type_id ?
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditTextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            size="small"
                                                            fullWidth
                                                            label="Офис"
                                                            name={`addresses[${index}][name]`}
                                                            value={address.name || ''}
                                                            onChange={handleInputChange}
                                                            error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].name && (validations.addresses[index].name[0] || validations.addresses[index].name))}
                                                            helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].name && (validations.addresses[index].name[0] || validations.addresses[index].name)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            size="small"
                                                            fullWidth
                                                            label="МОЛ"
                                                            name={`addresses[${index}][mol]`}
                                                            value={address.mol || ''}
                                                            onChange={handleInputChange}
                                                            error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].mol && (validations.addresses[index].mol[0] || validations.addresses[index].mol))}
                                                            helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].mol && (validations.addresses[index].mol[0] || validations.addresses[index].mol)}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                </>
                                            }

                                            <div className="row">

                                                <div className="col">
                                                    <Autocomplete
                                                        inputPlaceholder="Държава"
                                                        inputName={`addresses[${index}][country][name]`}
                                                        inputIdName={`addresses[${index}][country][id]`}
                                                        url="countries/all"
                                                        selected={address.country}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].country && (validations.addresses[index].country[0] || validations.addresses[index].country))}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].country && (validations.addresses[index].country[0] || validations.addresses[index].country)}
                                                        onChange={data => handleCountryChange(data, index)}
                                                        onInputChange={data => handleCountryChange(data, index)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Autocomplete
                                                        inputPlaceholder="Област"
                                                        inputName={`addresses[${index}][district][name]`}
                                                        inputIdName={`addresses[${index}][district][id]`}
                                                        url="districts/all"
                                                        params={{
                                                            country_id: address.country?.id
                                                        }}
                                                        selected={address.district}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].district && (validations.addresses[index].district[0] || validations.addresses[index].district))}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].district && (validations.addresses[index].district[0] || validations.addresses[index].district)}
                                                        onChange={data => handleDistrictChange(data, index)}
                                                        onInputChange={data => handleDistrictChange(data, index)}
                                                    />
                                                </div>

                                                <div className="col">
                                                    <Autocomplete
                                                        inputPlaceholder="Община"
                                                        inputName={`addresses[${index}][municipality][name]`}
                                                        inputIdName={`addresses[${index}][municipality][id]`}
                                                        url="municipalities/all"
                                                        params={{
                                                            district_id: address.district?.id
                                                        }}
                                                        selected={address.municipality}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].municipality && (validations.addresses[index].municipality[0] || validations.addresses[index].municipality))}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].municipality && (validations.addresses[index].municipality[0] || validations.addresses[index].municipality)}
                                                        onChange={data => handleMunicipalityChange(data, index)}
                                                        onInputChange={data => handleMunicipalityChange(data, index)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <Autocomplete
                                                        inputPlaceholder="Населено място"
                                                        inputName={`addresses[${index}][settlement][name]`}
                                                        inputIdName={`addresses[${index}][settlement][id]`}
                                                        url="settlements/all"
                                                        params={{
                                                            municipality_id: address.municipality?.id
                                                        }}
                                                        selected={address.settlement}
                                                        renderOption={option => {
                                                            return [
                                                                option.name,
                                                                option.municipality ? 'общ. ' + option.municipality.name : null,
                                                                option.municipality && option.municipality.district ? 'обл. ' + option.municipality.district.name : null
                                                            ].filter(o => {
                                                                return o !== null
                                                            }).join(', ')
                                                        }}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].settlement && (validations.addresses[index].settlement[0] || validations.addresses[index].settlement))}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].settlement && (validations.addresses[index].settlement[0] || validations.addresses[index].settlement)}
                                                        onChange={data => handleSettlementChange(data, index)}
                                                        onInputChange={data => handleSettlementChange(data, index)}
                                                    />
                                                </div>

                                                <div className="col small">
                                                    <RedditTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        label="ПК"
                                                        name={`addresses[${index}][postcode]`}
                                                        value={address?.postcode || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index]?.postcode)}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].postoce && validations.addresses[index].postcode[0]}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Autocomplete
                                                        inputPlaceholder="Район"
                                                        inputName={`addresses[${index}][area][name]`}
                                                        inputIdName={`addresses[${index}][area][id]`}
                                                        url="areas/all"
                                                        params={{
                                                            settlement_id: address?.settlement?.id
                                                        }}
                                                        selected={address?.area}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].area && (validations.addresses[index].area[0] || validations.addresses[index].area))}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].area && (validations.addresses[index].area[0] || validations.addresses[index].area)}
                                                        onInputChange={data => handleAreaChange(data, index)}
                                                    />
                                                </div>

                                                <div className="col">
                                                    <Autocomplete
                                                        inputPlaceholder="Булевард/улица"
                                                        inputName={`addresses[${index}][street][name]`}
                                                        inputIdName={`addresses[${index}][street][id]`}
                                                        url="streets/all"
                                                        params={{
                                                            settlement_id: address?.settlement?.id
                                                        }}
                                                        selected={address?.street}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].street && (validations.addresses[index].street[0] || validations.addresses[index].street))}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].street && (validations.addresses[index].street[0] || validations.addresses[index].street)}
                                                        onInputChange={data => handleStreetChange(data, index)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col small">
                                                    <RedditTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        label="№"
                                                        name={`addresses[${index}][street_no]`}
                                                        value={address?.street_no || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index]?.street_no)}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].street_no && validations.addresses[index].street_no[0]}
                                                    />
                                                </div>

                                                <div className="col">
                                                    <RedditTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        label="Блок"
                                                        name={`addresses[${index}][block]`}
                                                        value={address?.block || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].block)}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].block && validations.addresses[index].block[0]}
                                                    />
                                                </div>
                                                <div className="col small">
                                                    <RedditTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        label="Вход"
                                                        name={`addresses[${index}][entrance]`}
                                                        value={address?.entrance || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].entrance)}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].entrance && validations.addresses[index].entrance[0]}
                                                    />
                                                </div>

                                                <div className="col small">
                                                    <RedditTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        label="Етаж"
                                                        name={`addresses[${index}][floor]`}
                                                        value={address?.floor || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].floor)}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].floor && validations.addresses[index].floor[0]}
                                                    />
                                                </div>
                                                <div className="col small">
                                                    <RedditTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        label="Апартамент"
                                                        name={`addresses[${index}][apartment]`}
                                                        value={address?.apartment || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.addresses && validations.addresses[index] && validations.addresses[index].apartment)}
                                                        helperText={validations && validations.addresses && validations.addresses[index] && validations.addresses[index].apartment && validations.addresses[index].apartment[0]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    ''
                                }
                                <Button
                                    className="add"
                                    onClick={handleAddAddress}
                                    style={{
                                        marginTop: '10px',
                                        width: 'fit-content'
                                    }}
                                >
                                    Нов {CUSTOMER_TYPE.COMPANY === state.data?.type_id ? 'офис' : 'адрес'}
                                </Button>
                            </div>
                        </div>

                        <div id="footer">
                            <Button
                                className="cancel"
                                onClick={() => {
                                    history.goBack()
                                }}
                            >
                                Отказ
                            </Button>
                            <SaveButton
                                className="save"
                                loading={state.loading}
                            >
                                Запази
                            </SaveButton>
                        </div>
                    </form>
                </section>
            </Layout>
        </>
    )
}

export default Index;
import { useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory, Link } from 'react-router-dom'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//modals
import Question from 'components/modals/Question'
import AddOrEdit from './partials/AddOrEdit'

//providers
import { useAuthDataContext } from 'providers/Auth'

//partials
import Filter from './partials/Filter'
import Options from './partials/Options'

//MUI componenets
import { Tooltip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Button } from '@material-ui/core'

//images
import childrenCategoriesIcon from 'assets/img/products/icons/children-categories.png'


let timeout
let categoryPath = []
function Index(props) {
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()
    const companyId = query.get('company_id') || auth.getUser()?.getCompany()?.id
    const categoryId = query.get('category_id') || null
    const categorySearch = query.get('category_search') || ''

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        path: null,
        categoryPath: [],
        loadMorePage: 1,
        loadMoreModeOnResults: false,
        filter: {
            company_id: companyId,
            category_id: categoryId
        },
    })
    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                company_id: companyId,
                category_id: categoryId,
                category_search: categorySearch,
            },
        }))
        refresh()
    }, [companyId, categoryId, categorySearch])

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1])
                // }
            })

            history.push('?' + query.toString())
        }
    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.loadMorePage, state.refresh])

    const loadData = () => {
        setLoading(true)

        let url = 'categories/level'

        Object.entries(state.filter).map(filter => {
            if (filter[0] === 'category_search') {
                filter[0] = 'search'
            }
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        //Добавя loadMorePAge към URL на заявката. Няма общо с url адрес-а.
        if (state.loadMorePage > 1) {
            url += `${(url.indexOf('?') > -1 ? '&' : '?')}page=${state.loadMorePage}`
        }

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: prev.loadMoreModeOnResults ? prev.data.concat(res.data.items) : res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }))

                setPath(res.data.category)

                if (res.data.pages && res.data.pages < state.filter.page) handleSearch('page', res.data.pages, 0)
            })
            .finally(() => {
                setLoading(false)
                setLoadMoreModeOnResults(false)
            })
    }

    const setPath = path => {
        setState(prev => ({
            ...prev,
            path,
            categoryPath: !path ? [] : prev.categoryPath,
        }))
    }

    const setLoadMorePage = loadMorePage => {
        setState(prev => ({
            ...prev,
            loadMorePage
        }))
    }

    const setLoadMoreModeOnResults = loadMoreModeOnResults => {
        setState(prev => ({
            ...prev,
            loadMoreModeOnResults
        }))
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    const handleAdd = () => {
        const modal = addOrEditModalRef.current

        modal.add(categoryId)
        modal.onSuccess(() => {
            refresh()
        })
    }

    // update
    const handleEdit = id => {
        const modal = addOrEditModalRef.current

        modal.edit(id)
        modal.onSuccess(() => {
            refresh()
        })
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        setLoading(true)
        Api.post('categories/delete', {
            id
        })
            .then(res => {
                if (res.data.success) refresh()
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))
        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }))
        }, delay)
    }

    const handlePage = currentPage => {
        setLoadMoreModeOnResults(true)
        setLoadMorePage(Number(currentPage) + 1)
    }

    const handleBrowserCategory = categoryId => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                category_id: categoryId
            },
            setFilter: new Date().getTime()
        }))
    }

    const renderPath = category => {
        if (category) {
            categoryPath.push(category)
            if (category.parent) renderPath(category.parent)
            else {
                props.setLastCategory(categoryPath[0])
                setState(prev => ({
                    ...prev,
                    categoryPath
                }))
            }
        }
    }

    useEffect(() => {
        categoryPath = []
        renderPath(state.path)
    }, [state.path])

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
                companyId={state.filter.company_id}
            />
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <div className="categories-panel panel">
                <div id="head" className="row space-between">
                    <div className="left">
                        <h3>
                            Категории
                        </h3>
                        <ul className="path">
                            <Tooltip
                                title="Преглед на главни категории"
                            >
                                <li
                                    onClick={() => {
                                        handleBrowserCategory(null)
                                        setPath(null)
                                        setLoadMorePage(1)
                                        props.setLastCategory({})
                                    }}
                                >
                                    Всички
                                </li>
                            </Tooltip>
                            {state.categoryPath.length ?
                                state.categoryPath.reverse().map((p, i) =>
                                    <>
                                        <li className="pointer">
                                            »
                                        </li>
                                        <Tooltip
                                            title={`Върнете се към категория ${p.translation.name.toLowerCase()}`}
                                        >
                                            <li
                                                onClick={() => {
                                                    handleBrowserCategory(p.id)
                                                }}
                                                className={(i + 1) === state.categoryPath.length ? 'active' : ''}
                                            >
                                                {p.translation.name}
                                            </li>
                                        </Tooltip>
                                    </>
                                )
                                :
                                <>
                                </>
                            }

                        </ul>
                    </div>
                    <div
                        className="row right"
                        style={{
                            width: 'fit-content'
                        }}
                    >
                        <Tooltip
                            title={`Създаване на нова категория към "${categoryPath.reverse()[0]?.translation?.name || 'Всички'}"`}
                        >
                            <Button
                                className="add"
                                onClick={() => {
                                    handleAdd()
                                }}
                            >
                                Нова
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <hr />
                <Filter
                    filter={state.filter}
                    handleSearch={handleSearch}
                />

                {!state.filter.company_id
                    ?
                   <NoSelectedCompany />
                    :
                    state.loading
                        ?
                        <Skeleton count={5} height={60} />
                        :
                        state.data.length === 0
                            ?
                            <Alert severity="error">
                                Все още нямате добавени категории!
                            </Alert>
                            :
                            <>
                                <ul className="categories">
                                    {state.data.map(i =>
                                        <li
                                            key={i.id}
                                            onClick={e => {
                                                if (e.target) {
                                                    if (e.target.classList?.contains('MuiButtonBase-root') || e.target.getAttribute('aria-hidden') === 'true') return
                                                }
                                                handleBrowserCategory(i.id)
                                            }}
                                        >
                                            <Tooltip
                                                title={`Към категория ${i.translation.name.toLowerCase()}`}
                                            >
                                                <div className="holder">
                                                    <h4>
                                                        {i.translation.name}
                                                    </h4>
                                                    <div className="details">
                                                        <span className="blue">
                                                            <img src={childrenCategoriesIcon} />
                                                            {i.children_count}
                                                        </span>
                                                        <span className="purple">
                                                            <img src={childrenCategoriesIcon} />
                                                            {i.products_count_recursive}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Tooltip>
                                            <MenuOptions>
                                                <Options
                                                    data={i}
                                                    handleEdit={handleEdit}
                                                    handleDelete={handleDelete}
                                                />
                                            </MenuOptions>
                                        </li>
                                    )}
                                </ul>
                                {state.loadMorePage < state.pages ?
                                    <Tooltip
                                        title="Зареждане на още резултати"
                                    >
                                        <span
                                            className="link"
                                            onClick={() => {
                                                handlePage(state.loadMorePage)
                                            }}
                                        >
                                            Още резултати
                                        </span>
                                    </Tooltip>
                                    :
                                    <>
                                    </>
                                }
                            </>
                }
            </div>
        </>
    )
}

export default Index
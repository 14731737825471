import { useState } from 'react'
import Layout from 'components/Layout'

//partials
import ProductIndex from './products/Index'
import CategoriesIndex from './categories/Index'
//style
import 'assets/scss/pages/products.scss'


const Index = () => {
    const [state, setState] = useState({
        lastCategory: {}
    })

    const setLastCategory = lastCategory => {
        setState(prev => ({
            ...prev,
            lastCategory
        }))
    }

    return (
        <Layout>
            <section id="products">
                <div id="head" className="row space-between">
                    <div className="title">
                        Продукти
                    </div>
                </div>
                <div className="holder">
                    <CategoriesIndex
                        setLastCategory={setLastCategory}
                    />
                    <ProductIndex
                        lastCategory={state.lastCategory}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default Index
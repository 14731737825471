import { useRef, useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory, Prompt } from 'react-router-dom'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import { useQuery } from 'helpers/Url'

//miscs
import SaveButton from 'components/misc/Button'

//MUI components
import { Button, Tooltip } from '@material-ui/core'

//modals
import Question from '../../../../modals/Question'
import ChangePassword from '../profile/partials/ChangePassword'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//images
import editIcon from 'assets/img/settings/icons/edit.png'

//partials
import Permissions from './partials/Permissions'

function User() {
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()
    const app = useAppContext()
    const userId = query.get('user_id')

    const [state, setState] = useNestedState({
        loading: true,
        loadingSave: false,
        disableSave: true,
        selectedAddressPermissions: {},
        selectedCompanies: [],
        selectedOffices: [],
        data: {}
    })

    const [validations, setValidations] = useValidation()

    const snackBarFailRef = useRef(null)
    const errorModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    const changePasswordModalRef = useRef()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)

        Api.get(`users/show?resource=1&id=${userId}`)
            .then(res => {
                const { data } = res

                const selectedOffices = {}
                const selectedCompanies = []
                const selectedPermissions = {}

                data.companies.map(c => {
                    c.addresses.map(a => {
                        if (Object.keys(data.addresses_permissions).map(d => Number(d)).includes(a.id)) {
                            selectedOffices[c.id] = [...(selectedOffices[c.id] || []), a]
                            selectedCompanies.push(c.id)
                        }
                    })
                })

                Object.keys(data.addresses_permissions).map((a, i) => {
                    Object.values(data.addresses_permissions)[i].map(d => {
                        selectedPermissions[a] = [...(selectedPermissions[a] || []), d.id]
                    })
                })

                setState(prev => ({
                    ...prev,
                    data,
                    selectedPermissions,
                    selectedCompanies,
                    selectedOffices
                }))
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 404) {
                    const modal = errorModalRef.current

                    modal.open()
                    modal.onSuccess(() => {
                        alert('Да се сложи snack')
                    })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    const handleDisableSave = disableSave => {
        setState(prev => ({
            ...prev,
            disableSave
        }))
    }

    // delete
    const handleDelete = () => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            setLoading(true)
            handleDestroy(userId)
        })
    }

    const handleDestroy = id => {
        Api.post('users/delete', {
            id
        })
            .then(() => {
                // history.push('/settings?menu=6')
                window.location.href = '/'
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSave = e => {
        e.preventDefault()

        setLoadingSave(true)
        setValidations(null)

        const url = 'users/edit'
        const data = new FormData(e.target)

        data.append('id', userId)

        Api.post(url, data)
            .then(() => {
                if (Number(userId) === auth.getUser().getId()) {
                    auth.refresh()
                }

                app.handleSuccess('Вашите данни бяха запазени успешно!')

                handleDisableSave(true)
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else snackBarFailRef.current.show()
            })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    const handleChangePicture = () => {
        document.querySelector('input[type="file"]').click()
        handleDisableSave(false)
    }

    const handleUploadPicture = e => {
        const reader = new FileReader()
        reader.addEventListener('load', ev => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    avatar: ev.target.result
                }
            }))
        })
        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = () => {
        handleDisableSave(false)
    }

    return (
        <section id="user">
            {/* <Prompt
                when={state.edited}
                message="Имате незапазени промени! Сигурни ли сте, че искате да напуснете страницата?"
            /> */}
            <ChangePassword
                ref={changePasswordModalRef}
            />
            <div id="head">
                <div className="title">
                    {state.data?.name || '-'} » Редакция на профил
                </div>
            </div>
            <hr />
            <Question
                ref={deleteModalRef}
                mainMessage="Искате ли да изтриете Вашият профил?"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"

            />
            {state.loading ?
                <Skeleton count={5} height={60} />
                :
                <form noValidate onSubmit={handleSave}>
                    <input
                        type="file"
                        hidden
                        name="picture"
                        onChange={handleUploadPicture}
                        accept="image/*"
                    />
                    <div className="content">
                        <b>
                            Основна информация
                        </b>
                        <div className="top">
                            <div className="left">
                                <div className="top-part">
                                    <Tooltip title="Смяна на профилната снимка">
                                        <picture
                                            onClick={handleChangePicture}
                                        >
                                            <img src={state.data?.avatar} />
                                        </picture>
                                    </Tooltip>
                                    <div className="right-part">
                                        <span className="role">
                                            {state.data.role?.translation.name || 'Потребител'}
                                        </span>
                                        <div className="field">
                                            <img src={editIcon} />
                                            <input
                                                type="text"
                                                defaultValue={state.data.name}
                                                name="name"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {/* <div className="field">
                                            <img src={editIcon} />
                                            <input
                                                type="text"
                                                defaultValue={state.data.lastname}
                                                name="lastname"
                                                onChange={handleChange}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                                <div className="bottom-part">
                                    <p>
                                        Информация за контакти
                                    </p>
                                    <ul>
                                        <li>
                                            <span>
                                                Е-поща
                                            </span>
                                            <div className="field">
                                                <img src={editIcon} />
                                                <input
                                                    type="text"
                                                    defaultValue={state.data.email}
                                                    name="email"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <span>
                                                Телефон
                                            </span>
                                            <div className="field">
                                                <img src={editIcon} />
                                                <input
                                                    type="text"
                                                    defaultValue={state.data.phone}
                                                    name="phone"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right">
                                <h6>
                                    Смяна на парола
                                </h6>
                                <p>
                                    Сменете Вашата парола за достъп
                                </p>
                                <Button
                                    className="normal save change-password"
                                    onClick={() => {
                                        changePasswordModalRef.current.open()
                                    }}
                                >
                                    Смени парола
                                </Button>
                            </div>
                        </div>
                        <b>
                            Функционален достъп
                        </b>
                        <div className="bottom">
                            <Permissions
                                selectedC={state.selectedCompanies}
                                selectedO={state.selectedOffices}
                                selectedAP={state.selectedPermissions}
                                handleChange={handleChange}
                            />
                            <hr />
                            <div className="row delete">
                                <Button
                                    className="remove"
                                    onClick={handleDelete}
                                >
                                    Изтриване
                                </Button>
                                <div className="right">
                                    <h6>
                                        Изтриване на профила
                                    </h6>
                                    <p>
                                        С натискането на бутона "Изтриване" всички данни свързани с Вашия профил ще бъдат изтрити и няма да могат да бъдат възстановени.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="footer">
                        <Button
                            className="cancel"
                            onClick={() => {
                                history.goBack()
                            }}
                        >
                            Назад
                        </Button>
                        <SaveButton
                            className="save"
                            loading={state.loadingSave}
                            disabled={state.disableSave}
                        />
                    </div>
                </form>
            }
        </section>
    )
}

export default User
//partials
import Header from './partials/Header/Index'

function Layout(props) {
    return (
        <>
            <Header />
            <main>
                {props.children}
            </main>
        </>
    )
}

export default Layout
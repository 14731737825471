import { TextField } from "@material-ui/core";
import RedditTextField from "./RedditTextField";

function DatePicker(props) {

    const fullWidth = props.fullWidth || false;
    const onChange = typeof props.onChange === 'function' ? props.onChange : () => { };

    return (
        <div className={['custom-date', props.reddit ? 'right' : ''].join(' ')}>
            {props.reddit
                ?
                <RedditTextField
                    fullWidth={fullWidth}
                    variant="outlined"
                    size="small"
                    margin="none"
                    type="date"
                    name={props.name}
                    label={props.label}
                    value={props.value || ''}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={props.disabled || false}
                />
                :
                <>
                    {props.label &&
                        <label>
                            {props.label}
                        </label>
                    }
                    <TextField
                        fullWidth={fullWidth}
                        variant="outlined"
                        size="small"
                        margin="none"
                        type="date"
                        name={props.name}
                        // label={props.label}
                        value={props.value || ''}
                        onChange={onChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                        disabled={props.disabled || false}
                    />
                </>
            }
        </div>
    )
}

export default DatePicker;
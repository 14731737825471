//misc
import RedditTextField from "components/misc/RedditTextField"

//MUI components
import { Tooltip } from '@material-ui/core'

//images
import clearFilterIcon from 'assets/img/documents/icons/clear-filter.png'

//components
import Period from "./Period"
import DocType from "./DocType"
import Sent from "./Sent"
import Paid from "./Paid"

function Filter(props) {
    const { search, start_date, end_date, period, sent, payment_method_id, status } = props.filter
    const { typeId } = props

    const typeIds = props.filter['type_id[]']

    const handleSearch = e => {
        const { name, value } = e.target
        props.handleSearch(name, value)
    }

    const handleClearFilter = () => {
        props.clearFilter()
    }

    const areAppliedFilters = () => {
        return search || start_date || end_date || sent || payment_method_id || status || period
    }

    return (
        <>
            <div className="row filter">
                <Tooltip
                    title="Търсене по име на документ"
                >
                    <div className="col search" >
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="Търсене..."
                            name="search"
                            value={search || ''}
                            onChange={handleSearch}
                        />
                    </div>
                </Tooltip>
                <DocType
                    handleSearch={props.handleSearch}
                    typeIds={typeIds}
                    typeId={typeId}
                />
                <Period
                    period={period}
                    dates={{
                        start_date,
                        end_date,
                    }}
                    handleSearch={props.handleSearch}
                />
                <Sent
                    sent={sent}
                    handleSearch={props.handleSearch}
                />
                <Paid
                    data={{
                        status,
                        payment_method_id
                    }}
                    handleSearch={props.handleSearch}
                />
                <Tooltip
                    title="Изчистите данните от филтъра"
                >
                    <div
                        className={`col link ${areAppliedFilters() ? '' : 'disabled'}`}
                        onClick={handleClearFilter}
                        style={{
                            justifyContent: 'center',
                            width: 'fit-content',
                            flex: 'initial'
                        }}
                    >
                        <img src={clearFilterIcon} />
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

export default Filter
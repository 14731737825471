import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import Api from 'helpers/Api'

//providers
import { useLanguageContext } from 'providers/Language'

//MUI components
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Button } from '@material-ui/core'

//modals
import SelectCategory from './SelectCategory'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

function AddOrEdit(props, ref) {
    const langs = useLanguageContext()
    const initialData = {
        categories: []
    }

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        currentCategory: {},
        edit: false,
        data: initialData,
        tab: langs?.data[0]?.id,
        onSuccess: null
    })

    const [validations, setValidations] = useValidation()

    const categoriesModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: currentCategory => {
            show(currentCategory)
        },
        edit: id => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.edit])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            tab: langs?.data[0]?.id,
        }))
    }, [state.modal])

    const show = currentCategory => {
        setState(prev => ({
            ...prev,
            overlay: true,
            data: currentCategory?.id ? {
                ...prev.data,
                categories: [
                    currentCategory
                ]
            } : initialData
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }))
        }, 150)

    }

    const handleHide = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: null,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id,
            edit: new Date().getTime()
        }))

        show()
    }

    const loadData = () => {
        if (!state.id) return

        Api.get(`products/show?id=${state.id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
    }

    const handleSave = e => {
        e.preventDefault()
        setLoading(true)
        setValidations(null)

        const url = state.id ? 'products/edit' : 'products/add'
        const data = new FormData(e.target)

        if (state.id) data.append('id', state.id)

        if (props.companyId) data.append('company_id', props.companyId)
        // if (state.categoryId) data.append('categories[]', state.categoryId)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                hide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data')
    }

    const handleTabChange = (e, tab) => {
        setState(prev => ({
            ...prev,
            tab
        }))
    }

    const showCategories = () => {
        hide()

        const modal = categoriesModalRef.current

        setTimeout(() => {
            modal.show()
        }, 100)

        modal.onClose(() => {
            setTimeout(() => {
                show()
            }, 100)
        })

        modal.onSuccess(categories => {
            setCategories(categories);
        })
    }

    const setCategories = categories => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                categories
            }
        }))
    }

    return (
        <>
            <SelectCategory
                ref={categoriesModalRef}
                companyId={props.companyId}
                product={state.data}
            />

            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                    <h2 className="head">
                        Продукт
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </h2>

                    <div className="body">

                        <div className="row">
                            <Tabs
                                value={state.tab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                scrollButtons="auto"
                                textColor="primary"
                                variant="scrollable"
                                aria-label="tabs"
                            >
                                {langs.data.map(lang =>
                                    <Tab key={'lt-' + lang.id} value={lang.id} label={lang.name} />
                                )}
                            </Tabs>
                        </div>

                        {langs.data.map(lang =>
                            <div
                                key={'l-' + lang.id}
                                hidden={state.tab !== lang.id}
                                style={{
                                    marginBottom: '10px'
                                }}
                            >

                                <div className="row">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        fullWidth
                                        label="Наименование"
                                        name={`langs[${lang.id}][name]`}
                                        value={state.data?.langs && state.data?.langs[lang.id]?.name || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name))}
                                        helperText={validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                    />
                                </div>

                                <div className="row">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        fullWidth
                                        label="Описание"
                                        name={`langs[${lang.id}][description]`}
                                        value={state.data?.langs && state.data?.langs[lang.id]?.description || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id]?.description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].name))}
                                        helperText={validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id]?.description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].name)}
                                        multiline
                                        rows={4}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div
                                className="col small"
                                style={{
                                    maxWidth: '100px',
                                }}
                            >
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    fullWidth
                                    label="Цена"
                                    type="number"
                                    min="0"
                                    name={`price`}
                                    value={state.data?.price || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.price)}
                                    helperText={validations && validations.price && (validations.price[0] || validations.price)}
                                />
                            </div>
                        </div>


                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="Категории"

                            value={(state.data?.categories || []).map((c, i) =>
                                ` ${c?.translation?.name}`
                            )}
                            onClick={showCategories}
                            readOnly
                        />

                        {(state.data?.categories || []).map(c =>
                            <input key={'c-' + c.id} type="hidden" name="categories[]" value={c.id} />
                        )}

                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit);
import { useState } from 'react'

//MUI components
import { Button, Menu, Tooltip } from '@material-ui/core'

//images
import settingsIcon from 'assets/img/app/icons/settings.png'

//images
import MenuIndicator from 'components/misc/MenuIndicator'

function MenuOptions(props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div key={id}>
            <Tooltip
                title="Показване на опции"
            >
                <Button
                    onClick={handleClick}
                    className="options-menu-button"
                    data-id={id}
                >
                    <MenuIndicator
                        active={Boolean(anchorEl)}
                    />
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                className="custom-menu-options"
                anchorOrigin={{ vertical: 'bottom' }}
            >
                {props.children}
            </Menu>
        </div>
    )
}

export default MenuOptions;
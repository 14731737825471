import { useEffect, useState, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

//helpers
import Api from 'helpers/Api'

//MUI components
import { Button } from '@material-ui/core'

//partials
import Credentials from './partials/Credentials'
import QrCode from './partials/QrCode'

//images
import viberTestIcon from 'assets/img/viber-test.png'
import calendarIcon from 'assets/img/settings/calendar.svg'
import dropboxIcon from 'assets/img/settings/dropbox.svg'
import googleDriveIcon from 'assets/img/settings/google-drive.svg'
import mailchimpIcon from 'assets/img/settings/mailchimp.svg'
import messengerIcon from 'assets/img/settings/messenger.svg'
import ondriveIcon from 'assets/img/settings/ondrive.svg'
import outlookIcon from 'assets/img/settings/outlook.svg'
import slackIcon from 'assets/img/settings/slack.svg'

function Index() {
    const credentialsModalRef = useRef(null)
    const qrcodeModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        data: [],
        open: {},
    })

    /**
     * 
     * @param {boolean} loading да се добави/премахне клас от елемента
     * @param {*} id id на елемента
     */
    const loadingApp = (loading, id) => {
        const el = document.querySelector(`li.${`i-${id}`}`)

        if (loading) el.classList.add('disabled')
        else el.classList.remove('disabled')
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    useEffect(() => {
        Api.get('e-services/user/list')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [state.refresh])

    /**
     * 
     * @param {number} id 
     * @returns provider
     */
    const getProvider = async (id) => {
        const res = await Api.get('e-services/user/provider?id=' + id)

        return res.data
    }

    /**
     * 
     * @param {object} service данни на услугата 
     * @returns service auth url
     */
    const getServiceAuthUrl = async (service) => {
        const res = await Api.get('e-services/user/auth/url', {
            params: {
                provider_id: service.provider_id,
                service_id: service.id
            }
        })

        return res
    }

    /**
     * 
     * @param {object} service данни на услугата 
     */
    const connectService = async (service) => {
        loadingApp(true, service.id)

        if (service.auth_type === 'oauth2') {
            getServiceAuthUrl(service)
                .then(res => {
                    openWindow(res.data)
                })
                .finally(() => {
                    loadingApp(false, service.id)
                })
        }
    }

    /**
     * 
     * @param {object} service данни на услугата 
     */
    const disconnectService = service => {
        loadingApp(true, service.id)

        Api.get('e-services/user/disconnect', {
            params: {
                service_id: service.id
            }
        })
            .then(res => {
                if (res.data.success) handleRefresh()
            })
            .finally(() => {
                loadingApp(false, service.id)
            })
    }

    /**
     * 
     * @param {object} app данни на приложението 
     * @returns app auth url
     */
    const getAppAuthUrl = async (app) => {
        const res = await Api.get(app.auth_url)

        return res
    }

    /**
     * 
     * @param {object} app данни на приложението 
     * @returns QR код
     */
    const getAppAuthQrCode = async (app) => {
        const res = await Api.get(app.auth_qrcode_url)

        return res
    }

    /**
     * 
     * @param {object} app данни на приложението 
     * @param {boolean} viaQrCode дали е избран QR код
     */
    const connectApp = (app, viaQrCode = false) => {
        loadingApp(true, app.id)

        if (viaQrCode) {
            const modal = qrcodeModalRef.current

            modal.show()
            getAppAuthQrCode(app)
                .then(res => {
                    modal.setSrc(res.data)
                })
                .finally(() => {
                    loadingApp(false, app.id)
                })

        } else {
            getAppAuthUrl(app)
                .then(res => {
                    openWindow(res.data)
                })
                .finally(() => {
                    loadingApp(false, app.id)
                })
        }
    }

    /**
     * 
     * @param {object} app данни на приложението 
     */
    const disconnectApp = app => {
        loadingApp(true, app.id)

        if (app.disconnect_url) {
            Api.get(app.disconnect_url)
                .then(res => {
                    if (res.data.success) handleRefresh()
                })
                .finally(() => {
                    loadingApp(false, app.id)
                })
        }
    }

    /**
     * 
     * @param {string} url връзка 
     */
    const openWindow = url => {
        window.open(url)
    }

    /**
     * 
     * @returns всички приложения без техните групи
     */
    const getExtractedApps = () => {
        let apps = []

        state.data.map(app => {
            app.services.map(service => {
                if (!service.is_app) apps.push(service)

                if (service.apps.length) {
                    service.apps.map(serviceApps => {
                        apps.push(serviceApps)
                    })
                }
            })
        })

        return apps
    }

    /**
     * 
     * @param {node} target елемента, върху който е кликнато 
     * @returns дали реда е кликаем
     */
    const isRowNotClickable = target => {
        let clickable = true

        if (target) {
            if (
                target.classList?.contains('qr') ||
                target?.parentNode?.classList?.contains('qr')
            ) {
                clickable = false
            }
        }

        return clickable
    }

    function getIcon(type) {
        switch (type) {
            case 'Google Calendar':
                return calendarIcon
            case 'Dropbox':
                return dropboxIcon
            case 'Google Drive':
                return googleDriveIcon
            case '':
                return mailchimpIcon
            case 'blackbox-messenger':
                return messengerIcon
            case 'Microsoft OneDrive':
                return ondriveIcon
            case 'Microsoft Calendar':
                return outlookIcon
            case 'blackbox-slack':
                return slackIcon
            case 'blackbox-viber':
                return viberTestIcon
            default:
                break
        }
    }

    return (
        <section id="integrations">
            <Credentials
                ref={credentialsModalRef}
            />

            <QrCode
                ref={qrcodeModalRef}
            />
            <div id="head">
                <div className="title">
                    Интеграции
                </div>
            </div>
            <hr />
            {state.loading ?
                <Skeleton count={5} height={60} />
                :
                <ul>
                    {getExtractedApps().map(app =>
                        <li
                            key={`app-${app.id}`}
                            className={`i-${app.id}`}
                            onClick={e => {
                                if (!isRowNotClickable(e.target)) return

                                if (!app.is_connected) {
                                    if (app?.service?.is_app) connectApp(app)
                                    else connectService(app)
                                }
                            }}
                            style={app.is_connected ?
                                { cursor: 'default' }
                                :
                                {}
                            }
                        >
                            <div className="row top">
                                <img src={getIcon(app.name)} />
                                {app.is_connected ?
                                    <Button
                                        className="save medium ready"
                                        onClick={() => {
                                            if (app?.service?.is_app) disconnectApp(app)
                                            else disconnectService(app)
                                        }}
                                    >
                                        СВЪРЗАН
                                    </Button>
                                    :
                                    <>
                                        <Button className="save medium">
                                            СВЪРЗВАНЕ
                                        </Button>
                                        {app.auth_qrcode_url
                                            ?
                                            <Button
                                                className="save medium qr"
                                                onClick={() => {
                                                    connectApp(app, true)
                                                }}
                                            >
                                                QR
                                            </Button>
                                            :
                                            <>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <h3>
                                {app.name}
                            </h3>
                            <p>
                                Lorem Ipsum е елементарен примерен текст, използван в печатарскат
                            </p>
                        </li>
                    )}
                </ul>}
        </section>
    )
}

export default Index
import { useState, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

//MUI components
import { Button } from '@material-ui/core'

//misc
import Copy from 'components/misc/Copy'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//helpers
import { substring } from 'helpers/Substring'

//modals
import Epay from './partials/Epay'
import PayPal from './partials/PayPal'
import Stripe from './partials/Stripe'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//images
import epayLogoImage from 'assets/img/settings/epay.png'
import paypalLogoImage from 'assets/img/settings/paypal.png'
import stripeLogoImage from 'assets/img/settings/stripe.png'
import readyIcon from 'assets/img/settings/icons/ready.png'


function Index() {
    const auth = useAuthDataContext()
    const app = useAppContext()

    const epayModalRef = useRef(null)
    const paypalModalRef = useRef(null)
    const stripeModalRef = useRef(null)

    const company = auth.getUser()?.getCompany()
    const companyId = auth.getUser()?.getCompany()?.getId()

    const [state, setState] = useState({
        loading: false,
    })

    const handleEditEpay = () => {
        const modal = epayModalRef.current

        modal.show()
        modal.onSuccess(async () => {
            success('Данните за ePay бяха запазени успешно!')
            setLoading({
                epay: true
            })
            await auth.refresh()
            setLoading(false)
        })

        modal.onError(() => {
            error('Нещо се обърка!')
        })
    }

    const handleEditPayPal = () => {
        const modal = paypalModalRef.current

        modal.show()
        modal.onSuccess(async () => {
            success('Данните за PayPal бяха запазени успешно!')
            setLoading({
                payPal: true
            })
            await auth.refresh()
            setLoading(false)
        })

        modal.onError(() => {
            error('Нещо се обърка!')
        })
    }

    const handleEditStripe = () => {
        const modal = stripeModalRef.current

        modal.show()
        modal.onSuccess(async () => {
            success('Данните за Stripe бяха запазени успешно!')
            setLoading({
                stripe: true
            })
            await auth.refresh()
            setLoading(false)
        })

        modal.onError(() => {
            error('Нещо се обърка!')
        })
    }

    /**
     * 
     * @param {string|null} msg съобщение за успех 
     */
    const success = (msg = null) => {
        app.handleSuccess(msg)
    }

    /**
     * 
     * @param {string|null} msg съобщение за грешка 
     */
    const error = (msg = null) => {
        app.handleError(msg)
    }

    /**
     * 
     * @param {node} target референция към елемента, върху който е кликнато
     * @returns дали елемента е кликаем
     */
    const isTargetClickable = target => {
        let isClickable = true

        if (target.classList.contains('unclickable')) {
            isClickable = false
        }

        return isClickable
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане 
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    return (
        <>
            <Epay
                ref={epayModalRef}
            />

            <PayPal
                ref={paypalModalRef}
            />

            <Stripe
                ref={stripeModalRef}
            />

            <div id="payments">
                <div id="head">
                    <div className="title">
                        Плащания
                    </div>
                </div>
                <hr />
                <div className="row filter">
                    <div className="col">

                    </div>
                </div>
                {!companyId
                    ?
                    <NoSelectedCompany />
                    :
                    state.loading === true
                        ?
                        <Skeleton count={5} height={60} />
                        :
                        <ul className="list">
                            <li
                                onClick={e => {
                                    if (isTargetClickable(e.target)) handleEditEpay()
                                }}
                                className={`${state?.loading?.epay ? 'disabled' : ''}`}
                            >
                                <div className="row top">
                                    <img src={epayLogoImage} />
                                    {company.hasEpay() ?
                                        <img src={readyIcon} />
                                        :
                                        <>
                                        </>
                                    }

                                    <Button className="config">

                                    </Button>
                                </div>
                                <p className="row">
                                    Настройте Вашият акаунт за приемане на плащания чрез ePay.
                                </p>
                                <hr />
                                <ul className="data">
                                    <li>
                                        <span>
                                            Клиентски номер
                                        </span>
                                        {substring(company.getSetting('epay_client_id'), 50) || 'Не е посочен'}
                                    </li>
                                    <li>
                                        <span>
                                            API ключ
                                        </span>
                                        {substring(company.getSetting('epay_client_secret'), 50) || 'Не е посочен'}
                                    </li>
                                    <li>
                                        <span>
                                            Адрес за получаване на информация за плащането от ePay
                                        </span>
                                        <Copy
                                            string={company.getEpayUrl()}
                                            buttonClassName="unclickable"
                                            inputClassName="unclickable"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li
                                onClick={handleEditPayPal}
                                className={`${state?.loading?.payPal ? 'disabled' : ''}`}
                            >
                                <div className="row top">
                                    <img src={paypalLogoImage} />
                                    {company.hasPayPal() ?
                                        <img src={readyIcon} />
                                        :
                                        <>
                                        </>
                                    }
                                    <Button className="config">

                                    </Button>
                                </div>
                                <p className="row">
                                    Настройте Вашият акаунт за приемане на плащания чрез PayPal
                                </p>
                                <hr />
                                <ul className="data">
                                    <li>
                                        <span>
                                            Client ID
                                        </span>
                                        {substring(company.getSetting('paypal_client_id'), 50) || 'Не е посочено'}
                                    </li>
                                    <li>
                                        <span>
                                            Client Secret
                                        </span>
                                        {substring(company.getSetting('paypal_client_secret'), 50) || 'Не е посочен'}
                                    </li>
                                </ul>
                            </li>
                            <li
                                onClick={e => {
                                    if (isTargetClickable(e.target)) handleEditStripe()
                                }}
                                className={`${state?.loading?.stripe ? 'disabled' : ''}`}
                            >
                                <div className="row top">
                                    <img src={stripeLogoImage} />
                                    {company.hasStripe() ?
                                        <img src={readyIcon} />
                                        :
                                        <>
                                        </>
                                    }
                                    <Button className="config">

                                    </Button>
                                </div>
                                <p className="row">
                                    Настройте Вашият акаунт за приемане на плащания чрез Stripe.
                                </p>
                                <hr />
                                <ul className="data">
                                    <li>
                                        <span>
                                            Publishable key
                                        </span>
                                        {substring(company.getSetting('stripe_pub_key'), 50) || 'Не е посочен'}
                                    </li>
                                    <li>
                                        <span>
                                            Secret key
                                        </span>
                                        {substring(company.getSetting('stripe_secret_key'), 50) || 'Не е посочен'}
                                    </li>
                                    <li>
                                        <span>
                                            Webhook Secret Key
                                        </span>
                                        {substring(company.getSetting('stripe_webhook_secret_key'), 50) || 'Не е посочен'}
                                    </li>
                                    <li>
                                        <span>
                                            Адрес за получаване на информация за плащането от Stripe
                                        </span>
                                        <Copy
                                            string={company.getStripeUrl()}
                                            buttonClassName="unclickable"
                                            inputClassName="unclickable"
                                        />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                }
            </div>
        </>
    )
}

export default Index
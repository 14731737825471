import { useEffect, useState } from 'react'

//plugins
import { cloneDeep } from "lodash"

//MUI components
import { Switch, FormControlLabel, Checkbox } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

//helpers
import Api from 'helpers/Api'
import { useNestedState } from 'helpers/NestedState'

const Permissions = ({ selectedC = [], selectedO = {}, selectedAP = {}, handleChange }) => {
    const initialState = {
        selectedPermissions: selectedAP,
        companiesList: [],
        data: {
            companies: selectedC,
            offices: selectedO,
        },
        loading: true
    }

    const [permissions, setPermissions] = useState([])
    const [state, setState] = useNestedState({
        ...initialState
    })

    useEffect(() => {
        fetchPermissions()
        getUserRoles()
        handleCompanies()._fetch()
    }, [])

    const handleCompanies = () => {
        /**
         * 
         * @param {Number} companyId 
         */
        const _change = companyId => {
            let companies = []
            if (_isChecked(companyId)) {
                companies = _get().filter(id => id != companyId)
                handleOffices()._remove(companyId)
            } else {
                companies = [..._get(), companyId]
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    companies
                }
            }))
        }

        /**
         * 
         * @param {Number} companyId 
         * @returns {Boolean}
         */
        const _isChecked = companyId => {
            let isIncluded = false
            _get().map(id => {
                if (!isIncluded) {
                    if (Number(id) === Number(companyId)) {
                        isIncluded = true
                    }
                }
            })

            return isIncluded
        }

        /**
         * 
         * @returns {Array}
         */
        const _get = () => state.data?.companies || []

        const _fetch = async () => {
            const res = await Api.get('companies/all')

            setState(prev => ({
                ...prev,
                companiesList: res.data.items
            }))
        }

        return {
            _change,
            _isChecked,
            _get,
            _fetch
        }
    }

    const handleOffices = () => {
        /**
        * 
        * @param {Number} companyId 
        * @param {Object} data 
        */
        const _change = (companyId, data) => {
            let offices = _get()._full()
            if (_isChecked(companyId, data.id)) {
                offices[companyId] = _get()._full()[companyId].filter(o => o.id != data.id)
                if (_permissions()._isOpened(data.id)) _permissions()._toggle(data.id)
            } else {
                offices[companyId] = [...(offices[companyId] || []), data]
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    offices
                }
            }))
        }

        /**
         * 
         * @param {Number} companyId 
         * @returns {Boolean}
         */
        const _isChecked = (companyId, officeId) => {
            let isIncluded = false;
            (_get()._full()[companyId] || []).map(o => {
                if (!isIncluded) {
                    if (Number(o.id) === Number(officeId)) {
                        isIncluded = true
                    }
                }
            })

            return isIncluded
        }

        const _get = () => {
            /**
             * 
             * @returns {Array}
             */
            const _companyIds = () => Object.keys(_full()) || []
            /**
             * 
             * @returns {Array}
             */
            const _data = () => Object.keys(_full()) || []

            /**
             * 
             * @returns {Object}
             */
            const _full = () => cloneDeep(state.data?.offices) || {}

            return {
                _companyIds,
                _data,
                _full
            }
        }

        /**
         * 
         * @param {Number} companyId 
         */
        const _remove = companyId => {
            let offices = _get()._full()
            if (!offices[companyId]) return

            offices[companyId].map(o => {
                if (_permissions()._isOpened(o.id)) _permissions()._toggle(o.id)
            })
            delete offices[companyId]

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    offices
                }
            }))
        }

        const _permissions = () => {
            /**
             * 
             * @param {Number} officeId 
             */
            const _toggle = officeId => {
                let openedPrmissionPanels = state.data?.openedPrmissionPanels || []
                if (_permissions()._isOpened(officeId)) {
                    openedPrmissionPanels = openedPrmissionPanels.filter(p => p != officeId)
                } else {
                    openedPrmissionPanels = [...openedPrmissionPanels, officeId]
                }

                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        openedPrmissionPanels
                    }
                }))
            }

            /**
            * 
            * @param {Number} officeId 
            * @returns {Boolean}
            */
            const _isOpened = officeId => (state.data?.openedPrmissionPanels || []).includes(officeId)

            return {
                _toggle,
                _isOpened
            }
        }

        return {
            _isChecked,
            _change,
            _remove,
            _permissions
        }
    }

    const hasCompanyPermission = (officeId, permissionId) => {
        if (state.selectedPermissions && state.selectedPermissions[officeId]) {
            return state.selectedPermissions[officeId].includes(permissionId)
        }

        return false
    }

    const handleCompanyPermissionChange = (officeId, permissionId) => {
        let selectedPermissions = cloneDeep(state.selectedPermissions)

        if (hasCompanyPermission(officeId, permissionId)) {
            selectedPermissions[officeId] = selectedPermissions[officeId].filter(pId => Number(pId) !== Number(permissionId))
        } else {
            if (!selectedPermissions[officeId]) {
                selectedPermissions[officeId] = []
            }

            selectedPermissions[officeId].push(permissionId)
        }
        setState(prev => ({
            ...prev,
            selectedPermissions
        }))
    }

    const getUserRoles = async () => {
        const { data } = await Api.get('users/roles/all')

        setState(prev => ({
            ...prev,
            userRoles: data
        }))
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const fetchPermissions = () => {
        Api.get('modules/permissions')
            .then(res => {
                setPermissions(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        state.loading ?
            <>
                <Skeleton style={{ height: '70px' }} />
                <Skeleton style={{ height: '70px' }} />
            </>
            :
            <ul className="companies user-permissions">
                {state.companiesList.map(c =>
                    <li key={`company-${c.id}`}>
                        <div className="row">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={handleCompanies()._isChecked(c.id)}
                                        onChange={() => {
                                            handleCompanies()._change(c.id)
                                        }}
                                        color="primary"
                                        name="company_ids[]"
                                        value={c.id}
                                    />
                                }
                                label={c.info.name}
                            />
                        </div>
                        {handleCompanies()._isChecked(c.id) ?
                            <ul className="offices">
                                {c.addresses.map(a =>
                                    <li key={`office-${a.id}`}>
                                        <div
                                            className={`row top ${handleOffices()._permissions()._isOpened(a.id) ? 'active' : ''}`}
                                            onClick={e => {
                                                if (e.target.name === `company_addresses_ids[${c.id}][]` || !handleOffices()._isChecked(c.id, a.id)) return
                                                handleOffices()._permissions()._toggle(a.id)
                                            }}
                                        >
                                            <Checkbox
                                                checked={handleOffices()._isChecked(c.id, a.id)}
                                                onChange={() => {
                                                    handleOffices()._change(c.id, a)
                                                    if (!handleOffices()._isChecked(c.id, a.id)) handleOffices()._permissions()._toggle(a.id)
                                                }}
                                                color="primary"
                                                name={`company_addresses_ids[${c.id}][]`}
                                                value={a.id}
                                            />
                                            {a.name}
                                        </div>
                                        <div className="row permissions" style={{ display: handleOffices()._isChecked(c.id, a.id) && handleOffices()._permissions()._isOpened(a.id) ? 'flex' : 'none' }}>
                                            {permissions.map((m, i) =>
                                                <div
                                                    className="col"
                                                    key={'m-' + m.id}
                                                >
                                                    <p>
                                                        {m?.translation?.name}
                                                    </p>
                                                    <div className="access col">
                                                        {m.permissions.map(p =>
                                                            <div className="col">
                                                                <FormControlLabel
                                                                    key={'p-' + p.id}
                                                                    control={
                                                                        <Switch
                                                                            checked={hasCompanyPermission(a.id, p.id)}
                                                                            onChange={() => {
                                                                                handleCompanyPermissionChange(a.id, p.id)
                                                                                handleChange()
                                                                            }}
                                                                            color="primary"
                                                                            margin="normal"
                                                                            inputProps={{ 'aria-label': 'checkbox' }}
                                                                            name={`address_permissions[${c.id}][${a.id}][]`}
                                                                            value={p.id}
                                                                        />
                                                                    }
                                                                    label={p?.translation?.name}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                )}

                            </ul>
                            :
                            <>
                            </>
                        }
                    </li>
                )}
            </ul>
    )
}

export default Permissions
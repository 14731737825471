import { MenuItem } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'

function Options(props) {
    return (
        <>
            <Tooltip
                title="Редакция на продукт"
            >
                <MenuItem
                    onClick={() => {
                        props.handleEdit(props.data.id)
                    }}
                >
                    Редакция
                </MenuItem>
            </Tooltip>
            <Tooltip
                title="Изтриване на продукт"
            >
                <MenuItem
                    onClick={() => {
                        props.handleDelete(props.data.id)
                    }}
                >
                    Изтриване
                </MenuItem>
            </Tooltip>
        </>
    )
}

export default Options;
//providers
import { useDocumentContext } from '../../Provider'

//partials
import ExportButton from './ExportButton'
import Graphs from './Graphs'

const AdditionalOptions = props => {
    const { typeId, handleExport } = props
    const context = useDocumentContext()
    const { state } = context
    const data = state[typeId]

    const allPages = {
        grossPrice: (data.totals.all_pages.gross_price || 0).toFixed(2),
        paidPrice: (data.totals.all_pages.paid_price || 0).toFixed(2)
    }

    const currentPage = {
        grossPrice: (data.totals.this_page.gross_price || 0).toFixed(2),
        paidPrice: (data.totals.this_page.paid_price || 0).toFixed(2)
    }

    const allPagesPriceToPay = (allPages.grossPrice - allPages.paidPrice || 0).toFixed(2)
    const currentPagePriceToPay = (currentPage.grossPrice - currentPage.paidPrice || 0).toFixed(2)
    const currency = data.totals.all_pages.currency.name

    const isCurrentPageDataVisible = data.pages > 1

    return (
        <tr className="additional-options">
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td className="text-right">
                <ul className="prices">
                    {isCurrentPageDataVisible ?
                        <li>
                            За страницата:
                        </li>
                        :
                        <>
                        </>
                    }
                    <li>
                        <b>
                            Общо:
                        </b>
                    </li>
                </ul>
            </td>
            <td className="text-right">
                <ul className="prices">
                    {isCurrentPageDataVisible ?
                        <li>
                            {currentPage.grossPrice} {currency}
                        </li>
                        :
                        <>
                        </>
                    }
                    <li>
                        <b>
                            {allPages.grossPrice} {currency}
                        </b>
                    </li>
                </ul>
            </td>
            <td className="text-right">
                <ul className="prices">
                    {isCurrentPageDataVisible ?
                        <li>
                            {currentPagePriceToPay} {currency}
                        </li>
                        :
                        <>
                        </>
                    }
                    <li>
                        <b>
                            {allPagesPriceToPay} {currency}
                        </b>
                    </li>
                </ul>
            </td>
            <td className="text-center">
                <Graphs
                    allPages={allPages}
                    currentPage={currentPage}
                    isCurrentPageDataVisible={isCurrentPageDataVisible}
                />
            </td>
            <td className="text-center">
                <ExportButton
                    allPagesTotal={data.total}
                    filters={state.filter}
                    handleExport={handleExport}
                    typeId={typeId}
                />
            </td>
        </tr>
    )
}

export default AdditionalOptions
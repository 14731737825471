import { useState, useImperativeHandle, forwardRef } from 'react'
// import { trans } from '../../../_providers/Translation';
import Button from '@material-ui/core/Button';

//images
import removeIcon from 'assets/img/modals/icons/remove.png'
import questionIcon from 'assets/img/modals/icons/question.png'
import loader from 'assets/img/loader.svg'

function Question(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            setState(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    modal: true,
                }))
            }, 50);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleAccept = () => {
        handleClose();

        if (state.onSuccess && typeof state.onSuccess === 'function') {
            return state.onSuccess();
        }
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`popup-primary small question ${state.modal ? 'show' : ''}`}>
                
                <div className="body">
                    <img src={props.agreeBtnClass === 'remove' ? removeIcon : questionIcon} className="main-image" />
                    <h3>
                        {props.mainMessage}
                    </h3>
                    <p>
                        {props.secondaryMessage}
                    </p>
                </div>
                <div className="footer">
                    {state.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <Button
                                onClick={handleClose}
                                className="cancel"
                            >
                                {props.cancelBtnText || 'Отказ'}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={props.agreeBtnClass || 'save'}
                                onClick={handleAccept}
                            >
                                {props.agreeBtnText || 'ОК'}
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Question);
import { useState, useImperativeHandle, forwardRef } from 'react'

//MUI components
import { Button, Tooltip } from '@material-ui/core'

//misc
import SaveButton from 'components/misc/Button'

//images
import closeIcon from 'assets/img/modals/icons/close.png'
import addIcon from 'assets/img/documents/icons/document-hover.png'
import nextIcon from 'assets/img/documents/icons/next.png'

//style
import 'assets/scss/send.scss'
import Autocomplete from 'components/misc/Autocomplete'

//helpers
import Api from 'helpers/Api'

//providers
import { useAppContext } from 'providers/App'

const initialState = {
    mainInputValue: null
}
const formId = `add-product-form-${new Date().getTime()}`
function Send(props, ref) {
    const {
        appendData,
        getInputArticleFromListWithoutCategory
    } = props
    const app = useAppContext()

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        ...initialState
    })

    useImperativeHandle(ref, () => ({
        open: (mainInputValue, selectedCategory = null) => {
            show(mainInputValue, selectedCategory)
        },
        close: () => {
            hide()
        },
        isOpened: () => {
            if (state.modal || state.overlay) {
                return formId
            }

            return false
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const show = (mainInputValue, selectedCategory) => {
        setState(prev => ({
            ...prev,
            overlay: true,
            mainInputValue,
            categories:
                selectedCategory ? [...refactorCategories(selectedCategory)] : [{
                    id: null,
                    name: '',
                    color: '#7979FF',
                    editable: true
                }]
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    /**
     * Приема текущата избрана катеогиря, като обхожда всички нейни parent, за да изгради масив от тях.
     * Накрая масива се обръща с reverse(), защото действието е започнато отвътре навън, а визуализацията трябва да е наобратно.
     * @param {object} category 
     * @returns {array}
     */
    const refactorCategories = category => {
        let cat = []
        const recursion = c => {
            if (c) {
                c.editable = false
                c.name = c.translation.name
                cat = [...cat, c]
                recursion(c.parent)
            }
        }
        recursion(category)

        return cat.reverse()
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                ...initialState,
                categories: null
            }))
        }, 100)
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleAddCategory = () => {
        setState(prev => ({
            ...prev,
            categories: [
                ...prev.categories,
                {
                    id: null,
                    name: '',
                    color: '#7979FF',
                    editable: true
                }
            ]
        }))
    }

    /**
     * дали бутона за добавяне на категория да бъде достъпен.
     * изисква се първо да има нещо попълнено в полето
     * @returns {boolean}
     */
    const isAddCategoryEnabled = () => {
        if (state.categories[state.categories.length - 1].name.length) {
            return true
        }

        return false
    }

    /**
     * главна функция за обработка на данни от полетата и техните вложени категории (подкатегории)
     * @param {string} name 
     * @param {number} index 
     * @param {number || null} id 
     */
    const handleChange = (name, index, id = null) => {
        const categories = state.categories.map((c, i) => {
            if (i === index) {
                c.name = name
                if (c.id) {
                    c.id = null
                    removeCategoriesToCurrentLevel(i)
                }
                else {
                    c.id = id
                }

            }

            return c
        })

        setState(prev => ({
            ...prev,
            categories
        }))
    }

    /**
     * смяна на цвета на отделните полета
     * @param {string} color 
     * @param {number} index 
     */
    const handleChangeColor = (color, index) => {
        const categories = state.categories.map((c, i) => {
            if (i === index) {
                c.color = color
            }

            return c
        })

        setState(prev => ({
            ...prev,
            categories
        }))
    }

    /**
     * премахва всички добавени категории от конкретно ниво надолу
     * @param {number} index 
     */
    const removeCategoriesToCurrentLevel = index => {
        const categories = state.categories.filter((c, i) => i <= index)

        setState(prev => ({
            ...prev,
            categories
        }))
    }

    /**
     * прихваща избраните данни на елемент от падащото меню.
     * в случая данните се изпращат към главната функция за обработка на данни от полетата.
     * @param {object} data 
     * @param {number} i 
     */
    const getSelectedData = (data, i) => {
        // console.log(data)
        handleChange(data?.translation?.name || '', i, data?.id || null)
    }

    /**
     * приготвя категориите за изпращане в подходящата структура
     * @returns {object}
     */
    const prepareDataForRequest = () => {
        let preparedData = { child: {} }

        state.categories.reverse().map(d => {
            preparedData = { ...d, child: { ...preparedData } }
        })

        // data.reverse() по-горе бъгва стейта и за това се прави още един reverse, за да го нормализира
        state.categories.reverse()

        return preparedData
    }

    const handleSave = e => {
        e.preventDefault()
        const data = {
            categories: prepareDataForRequest(),
            product: {
                id: getInputArticleFromListWithoutCategory?.id || '',
                name: state.mainInputValue
            }
        }

        setLoading(true)

        Api.post('documents/products/save-categories-and-product', data)
            .then(res => {
                appendData(res.data)
                hide()
                app.handleSuccess('Продукта беше добавен успешно!')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={`${formId} ${state.overlay ? 'visible' : ''} overlay`}>
            {state.overlay ?
                <form
                    className={`${state.modal ? 'show' : ''} add-product-modal popup-primary small`}
                    noValidate
                    id={formId}
                    onSubmit={handleSave}
                >
                    <div className="body">
                        <h3 className="head">
                            Добавяне на “{state.mainInputValue}” в категория
                            <img
                                className="close-icon"
                                alt="close"
                                src={closeIcon}
                                onClick={hide}
                            />
                        </h3>
                        <p>
                            Добавете Вашият продукт към избрана категория или подкатегория или създайте нови такива.
                        </p>
                        <ul className="categories">
                            {state.categories.map((c, i) =>
                                <li
                                    key={`category-${i}`}
                                    style={{
                                        marginLeft: `${i * 30}px`,
                                        pointerEvents: !c.editable ? 'none' : 'initial'
                                    }}
                                >
                                    <span
                                        className="color"
                                        style={{
                                            backgroundColor: c.color,
                                        }}
                                    >
                                        <input
                                            type="color"
                                            value={c.color}
                                            onChange={e => {
                                                handleChangeColor(e.target.value, i)
                                            }}
                                        />
                                    </span>
                                    <Autocomplete
                                        freeSolo={false}
                                        // variant="?standard"
                                        permanentlyClosedDropdown={!(state.categories.length === 1) && !state.categories[i - 1]?.id ? true : false}
                                        variant=""
                                        size="small"
                                        inputPlaceholder={i === 0 ? 'Главна категория' : 'Категория'}
                                        inputName={`payment_method`}
                                        inputIdName={`payment_method_id`}
                                        url="documents/products/categories/simple-list"
                                        params={{
                                            parent_id: (i === 0) ? '' : !state.categories[i - 1]?.id ? 'prazno' : state.categories[i - 1]?.id
                                        }}
                                        selectedValue={c.name}
                                        onInputChange={value => {
                                            handleChange(value, i)
                                        }}
                                        onChange={data => {
                                            getSelectedData(data, i)
                                        }}
                                        getResultData={data => data.items}
                                        getOptionLabel={option => option?.translation?.name || ''}
                                        renderOption={option => option?.translation?.name || ''}
                                        inputDisabled={!c.editable}
                                    // error={Boolean(validations && validations.payment_method_id && (validations.payment_method_id[0] || validations.payment_method_id))}
                                    // helperText={validations && validations.payment_method_id && (validations.payment_method_id[0] || validations.payment_method_id)}
                                    />
                                </li>
                            )}
                            <Tooltip
                                title={!isAddCategoryEnabled() ? 'Моля, първо въведете създаденото поле за категория.' : ''}
                            >
                                <li
                                    className="add"
                                    style={{
                                        marginLeft: `${state.categories.length * 30}px`
                                    }}
                                >
                                    <Button
                                        onClick={handleAddCategory}
                                        className={!isAddCategoryEnabled() ? 'disabled' : ''}
                                    >
                                        <img src={addIcon} />
                                        Подкатегория
                                    </Button>
                                </li>
                            </Tooltip>
                            <li
                                className="product"
                                style={{
                                    marginLeft: `${(state.categories.length + 1) * 30}px`
                                }}
                            >
                                {state.mainInputValue}
                            </li>
                        </ul>
                        <ul className="bradcrumbs">
                            {state.categories.map((c, i) =>
                                c.name.length ?
                                    <li
                                        key={`breadcrumbs-${i}`}
                                    >
                                        {c.name}
                                        <img src={nextIcon} />
                                    </li>
                                    :
                                    <>
                                    </>
                            )}
                            <li>
                                {state.mainInputValue}
                            </li>
                        </ul>
                    </div>
                    <div className="footer">
                        <Button
                            onClick={hide}
                            className="cancel"
                        >
                            {props.cancelBtnText || 'Отказ'}
                        </Button>
                        <Tooltip title={(!state.categories[0]?.name?.length || 0) ? 'Моля, изберете/въведете категория преди да запазите.' : ''}>
                            <div>
                                <SaveButton
                                    loading={state.loading}
                                    text="Запазване"
                                    className="save"
                                    // form={formId}
                                    disabled={(!state.categories[0]?.name?.length || 0) ? true : false}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </form>
                :
                <>
                </>
            }
        </div>
    )
}

export default forwardRef(Send)
import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//misc
import Pagination from 'components/misc/Pagination'
import MenuOptions from 'components/misc/MenuOptions'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//helpers
import Api from 'helpers/Api'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button, Tooltip } from '@material-ui/core'

//partials
import Options from './Options'

//providers
import { useAuthDataContext } from 'providers/Auth'

function NumbersInvoice(props) {
    const { isRowNotClickable, companyId, handleAdd, handleEdit, handleDelete }   = props
    const auth                                                                    = useAuthDataContext()
    const TYPE_ID                                                                 = 3

    const [state, setState] = useState({
        loading   : true,
        refresh   : false,
        data      : [],
        pages     : 0,
        total     : 0,
        page      : 1
    })

    useEffect(() => {
        getData()
    }, [state.refresh, auth])

    const getData = () => {
        if (!companyId) return

        loading(true)

        const url           = `documents/serials/all?resource=1&page=${state.page}&company_id=${companyId}&type_id=${TYPE_ID}`

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data    : res.data.items,
                    total   : res.data.total,
                    pages   : res.data.pages
                }))
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            page    : page.selected + 1,
        }))
    }

    return (
        <div className="numbers">
            <div id="head">
                <div className="title">
                    Фактура
                </div>
                <Tooltip
                    title="Добавяне на сериен номер"
                >
                    <Button
                        className="add small"
                        onClick={() => {
                            handleAdd(TYPE_ID)
                        }}
                    >
                        {/* Добави */}
                    </Button>
                </Tooltip>
            </div>

            {!companyId
                ?
                <NoSelectedCompany />
                :
                state.loading
                    ?
                    <Skeleton count={3} height={60} />
                    :
                    state.data.length === 0
                        ?
                        <Alert severity="error">
                            Няма намерена информация!
                        </Alert>
                        :
                        <>
                            <table className="type-outer">
                                <thead>
                                    <tr>
                                        <th>Стартов номер</th>
                                        <th class="text-right">Опции</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(c =>
                                        <tr
                                            key={'c-' + c.id}
                                            onClick={e => {
                                                if (isRowNotClickable(e.target)) handleEdit(c, TYPE_ID)
                                            }}
                                        >
                                            <td>
                                                {c.start_number}
                                            </td>
                                            <td class="text-right">
                                                <MenuOptions>
                                                    <Options
                                                        data={c}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </MenuOptions>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <Pagination
                                page={state.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </>
            }
        </div>
    )
}

export default NumbersInvoice
import { Area } from '@ant-design/plots';
import { MenuItem } from '@material-ui/core';
import axios from 'axios';
import DatePicker from 'components/misc/DatePicker';
import Api from 'helpers/Api';
import { useEffect, useRef, useState } from 'react';
import DateOptions from './DateOptions';
import calendarIcon from 'assets/img/app/icons/calendar.png'
import Skeleton from 'react-loading-skeleton';

let timeout;

function Index() {
    const [state, setState] = useState({
        data: [],
        filter: {
            group: 'month',
            start: null,
            end: null,
            force_start: null,
            force_end: null,
        },
        loading: true,
        refresh: false,
        request: null,
    });

    const [data, setData] = useState([]);

    const dateOptionsRef = useRef(null);

    useEffect(() => {
        loadData();
    }, [state.refresh]);

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        Api.post('accounts/incomes-expenses', state.filter, {
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                filter: {
                    ...prev.filter,
                    group: res.data.group,
                    force_start: res.data.start,
                    end: res.data.end
                },
                loading: false,
            }));

            let data = [];

            res.data.dates.map(date => {
                let income = {
                    xField: date.date,
                    yField: date.incomes.gross,
                    seriesField: 'Приход',
                };

                let expense = {
                    xField: date.date,
                    yField: date.expenses.gross,
                    seriesField: 'Разход',
                };

                data.push(income);
                data.push(expense);
            });

            // console.log(data)

            setData(data);
        })
    };

    const handleFilterChange = (name, value, delay = 0) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [name]: value
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                refresh: new Date()
            }))
        }, delay)
    }

    const handleShowCalendar = e => {
        dateOptionsRef.current.show(e);
    }

    const config = {
        data,
        xField: 'xField',
        yField: 'yField',
        seriesField: 'seriesField',
        color: ['l(270) 0:#A6EFDB 0.5:#A6EFDB 1:#90F9A7', 'l(270) 0:#FDD9D9 0.5:#FF718D 1:#FF718D'],
        line: {
            // color: ['#90F9A7', '#FF718D']
            size: [0, 0],
        },
        areaStyle: {
            fillOpacity: 0.7,
        },
        smooth: true,
        isStack: false,
        xAxis: {
            type: 'time',
            mask: state.filter.group === 'day' ? 'DD-MM-YYYY' : 'MM-YYYY',
        },
        yAxis: {
            label: {
                formatter: (v) => Number(v).toFixed(2),
            },
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.seriesField,
                    value: datum.yField.toFixed(2),
                };
            },
        },
        // legend: {
        //     position: 'top',
        // },
        legend: false
    };

    return (
        <>
            <div className="row">
                <div className="col chart">
                    <div className="head">
                        <div>
                            <div className="title">
                                Приходи и разходи
                            </div>
                            <div className="subtitle">
                                Вижте обобщени справки за приходи и разходи, като използвате зададен от Вас период.
                            </div>
                        </div>
                        <div className="dates">
                            <div className={`btn ${state.filter.group === 'day' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'day')}>
                                Д
                            </div>
                            <div className={`btn ${state.filter.group === 'week' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'week')}>
                                С
                            </div>
                            <div className={`btn ${state.filter.group === 'month' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'month')}>
                                М
                            </div>
                            <div className={`btn`} onClick={e => handleShowCalendar(e)}>
                                <img src={calendarIcon} alt="" />
                            </div>
                            <DateOptions
                                ref={dateOptionsRef}
                            >
                                <MenuItem>
                                    <DatePicker
                                        reddit
                                        label="От дата"
                                        value={state.filter.force_start || state.filter.start || ''}
                                        onChange={e => handleFilterChange('start', e.target.value, 300)}
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <DatePicker
                                        reddit
                                        label="До дата"
                                        value={state.filter.end || ''}
                                        onChange={e => handleFilterChange('end', e.target.value, 300)}
                                    />
                                </MenuItem>
                            </DateOptions>
                        </div>
                    </div>
                    <div className="legend">
                        <div className="item">
                            <div className="label">
                                <span className="color" style={{ background: '#90F9A7' }}>

                                </span>
                                <span className="text">
                                    Приходи
                                </span>
                            </div>
                            <div className="value">
                                {state.data?.incomes?.gross_formatted}
                            </div>
                        </div>
                        <div className="item">
                            <div className="label">
                                <span className="color" style={{ background: '#FF718D' }}>

                                </span>
                                <span className="text">
                                    Разходи
                                </span>
                            </div>
                            <div className="value">
                                {state.data?.expenses?.gross_formatted}
                            </div>
                        </div>
                    </div>

                    <div className="graph">
                        {state.loading
                            ?
                            <Skeleton style={{ height: '100%' }} />
                            :
                            <Area {...config} />
                        }
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col">
                    {state.loading
                        ?
                        <Skeleton count={4} height={30} />
                        :
                        <div className="scrollable">
                            <table className="type-outer">
                                <thead>
                                    <tr>
                                        <th>
                                            Приход / Разход
                                        </th>
                                        {state.data.dates.map(date =>
                                            <th key={'th-' + date.date} style={{ textAlign: 'right' }}>
                                                {date.label}
                                            </th>
                                        )}
                                        <th style={{ textAlign: 'right' }}>
                                            Общо
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Приход
                                        </td>
                                        {state.data.dates.map(date =>
                                            <td key={'td-' + date.date} style={{ textAlign: 'right' }}>
                                                {date.incomes.gross.toFixed(2)}
                                            </td>
                                        )}
                                        <td style={{ textAlign: 'right' }}>
                                            {state.data.incomes.gross.toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Разход
                                        </td>
                                        {state.data.dates.map(date =>
                                            <td key={'td-' + date.date} style={{ textAlign: 'right' }}>
                                                {date.expenses.gross.toFixed(2)}
                                            </td>
                                        )}
                                        <td style={{ textAlign: 'right' }}>
                                            {state.data.expenses.gross.toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Баланс
                                        </td>
                                        {state.data.dates.map(date =>
                                            <td key={'td-' + date.date} style={{ textAlign: 'right', color: date.incomes.gross - date.expenses.gross < 0 ? '#FF718D' : '' }}>
                                                <b>{(date.incomes.gross - date.expenses.gross).toFixed(2)}</b>
                                            </td>
                                        )}
                                        <td style={{ textAlign: 'right' }}>
                                            <b>{(state.data.incomes.gross - state.data.expenses.gross).toFixed(2)}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Index;

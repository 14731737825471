import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//MUI components
import { Button } from '@material-ui/core'

//misc
import SaveButton from 'components/misc/Button'
import RedditTextField from 'components/misc/RedditTextField'

//images
import closeIcon from 'assets/img/modals/icons/close.png'
import templateIcon from 'assets/img/documents/icons/template.png'
import addIcon from 'assets/img/documents/icons/add-dark.png'
import loadingImage from 'assets/img/loader-2.svg'

//heleprs
import { useValidation } from 'helpers/Validation'
import Api from 'helpers/Api'

//providers
import { useLanguageContext } from 'providers/Language'

//style
import 'assets/scss/send.scss'

//partials
import EmailInput from './EmailInput'
import SendHistory from './SendHistory'

//modals
import Success from './SendSuccess'

//plugins
import { CKEditor } from '@ckeditor/ckeditor5-react'

const URLS = {
    documents: {
        templates: 'documents/mail-templates',
        send: 'documents/send'
    },
    payorders: {
        templates: 'documents/payorders/mail-templates',
        send: 'documents/payorders/send'
    },
    cashorders: {
        templates: 'documents/cashorders/mail-templates',
        send: 'documents/cashorders/send'
    }
}

function Send(props, ref) {
    const langs = useLanguageContext()

    const initialState = {
        ids: [],
        email: null,
        activeId: null,
        langId: null,
        templateId: null,
        text: null,
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onError: null,
        onClose: null,
    }
    const [state, setState] = useState({
        ...initialState
    })

    const initialTemplates = {
        data: [],
        loading: true,
        anchorEl: null,
        customerData: {}
    }
    const [templates, setTemplates] = useState({
        ...initialTemplates
    })

    const [emails, setEmails] = useState([])
    const [validations, setValidations] = useValidation()

    const successModalRef = useRef(null)
    const documentsType = props.documentsType
    const emailInputRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (ids, email) => {
            handleOpen(ids, email)
        },
        close: () => {
            handleHide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        if (!state.ids.length) return

        setLoadingTemplates(true)

        let params = {
            ids: state.ids,
            lang_id: getActiveLangId()
        }

        Api.post(URLS[documentsType].templates, params)
            .then(res => {
                const { contact, documents } = res.data
                selectTemplate(res.data.templates[0])
                if (contact) emailInputRef.current?.setEmail(contact)
                setTemplates(prev => ({
                    ...prev,
                    data: res.data,
                    customerData: getCustomerInfo(Object.values(documents)[0].customer)
                }))
            })
            .finally(() => {
                setLoadingTemplates(false)
            })
    }, [state.ids, state.langId])

    const getCustomerInfo = customer => {
        const personName = customer?.address?.mol || ''
        const companyName = customer?.info?.name || ''

        return { personName, companyName }
    }

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const hide = callback => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false
            }))
            if (callback) callback()
        }, 100)
    }

    const clearData = () => {
        onSuccess()
        setTemplates(prev => ({
            ...prev,
            ...initialTemplates,
        }))
        setState(prev => ({
            ...prev,
            ...initialState
        }))
        setEmails([])
    }

    const handleOpen = (ids, email) => {
        show()

        setState(prev => ({
            ...prev,
            ids: Array.isArray(ids) ? ids : [ids],
            activeId: Array.isArray(ids) ? ids[0] : ids,
            email
        }))
    }

    const handleHide = () => {
        hide()
        resetData()
        setValidations(null)

        if (typeof state.onClose === 'function') state.onClose()
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: null,
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleTextChange = value => {
        console.log(value.getData())
        setState(prev => ({
            ...prev,
            text: value.getData() || ''
        }))
    }

    function onSuccess() {
        if (state.onSuccess && typeof state.onSuccess === 'function') {
            return state.onSuccess()
        }
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = URLS[documentsType].send
        const data = new FormData(e.target)

        if (Array.isArray(state.ids)) {
            state.ids.map(id => {
                data.append('id[]', id)
            })
        } else data.append('id', state.ids)

        Api.post(url, data)
            .then(res => {
                if (res.data.success) {
                    showSuccessModal()
                    hide()
                } else {
                    if (typeof state.onError === 'function') state.onError(res.data)
                }

            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const setLoadingTemplates = loading => {
        setTemplates(prev => ({
            ...prev,
            loading
        }))
    }

    const getActiveLangId = () => state.langId

    const selectLang = langId => {
        setState(prev => ({
            ...prev,
            langId
        }))
    }

    const getFirstId = () => state.ids[0]

    const getTemplates = () => templates.data[getFirstId()]?.templates || templates.data.templates || []

    const getActiveTemplateId = () => {
        if (state.templateId) {
            return state.templateId
        }

        return null
    }

    const getActiveTemplate = () => {
        const id = getActiveTemplateId()
        const template = getTemplates().find(t => Number(t.id) === Number(id))

        return template
    }

    const selectTemplate = template => {
        setState(prev => ({
            ...prev,
            templateId: template.id,
            text: template.text,
            topic: template.title
        }))
    }

    const getText = () => state.text || ''

    const toggleTemplates = e => {
        setTemplates(prev => ({
            ...prev,
            anchorEl: prev.anchorEl ? null : e.target
        }))
    }

    const hideTemplates = () => {
        setTemplates(prev => ({
            ...prev,
            anchorEl: null
        }))
    }

    const createMarkup = text => ({ __html: text })

    const nl2br = text => text.replaceAll(/(\r\n|\r|\n)/g, '<br/>')

    const handleChangeEmails = data => {
        setEmails(data)
    }

    const showSuccessModal = () => {
        const modal = successModalRef.current
        const newContacts = emails.filter(contact => typeof contact !== 'object')

        if (modal) modal.open(newContacts)
    }

    /**
     * 
     * @returns броя на различни клиенти, които се съдържат различните документи
     */
    const getClientsCount = () => {
        let clients = []

        Object.values(templates.data?.documents || {}).map(d => {
            if (!clients.includes(d.author)) clients.push(d.author)
        })

        return clients
    }

    const getSendCaseType = () => {
        let people = {}
        let data = {}
        const doc = emails.length ? templates.data?.documents?.[state?.ids[0]] : {}
        if ((Number(emails.length) === 1)) {
            if (Number(getClientsCount().length) === 1) {
                // 1 епоща, 1 документ
                //Клиент
                //Визия с тикче
                people = {
                    author: doc?.customeraddress?.mol || '',
                    guessType: 'client',
                    company: doc?.customer?.info?.name || ''
                }
                data = {
                    people,
                    caseType: 1
                }
            } else {
                // 1 епоща, повече от 1 документ
                // Счетоводител
                // Предложение за маркиране като счетоводител
                // Визия с полета
                people = {
                    author: '',
                    guessType: 'accountant',
                    company: ''
                }
                data = {
                    people,
                    caseType: 2
                }
            }
        } else {
            if (Number(getClientsCount().length) === 1) {
                // повече от 1 епоща, 1 документ
                // Клиент 
                // Визия с полета
                people = {
                    author: doc?.customeraddress?.mol || '',
                    guessType: 'client',
                    company: doc?.customer?.info?.name || '',
                    email: doc?.companyinfo?.email || '',
                }
                data = {
                    people,
                    caseType: 3
                }
            } else {
                // повече от 1 епоща, повече от 1 документ
                // СчетоводителИ
                // Визия с полета
                people = {
                    author: '',
                    guessType: 'accountant',
                    company: ''
                }
                data = {
                    people,
                    caseType: 4
                }
            }
        }

        return data
    }

    const getFirstDocument = () => {
        if (Object.values(templates.data?.documents || {})[0]?.was_sent || false) {
            return {
                ...Object.values(templates.data?.documents || {})[0]
            }
        }

        return { was_sent: false }
    }

    const handleAppendTemplate = (text, topic, email) => {
        setState(prev => ({
            ...prev,
            text,
            topic
        }))
        setEmails([email])

        emailInputRef.current?.setEmail(email)
    }

    return (
        <>
            <Success
                ref={successModalRef}
                getSendCaseType={getSendCaseType()}
                getClientsCount={getClientsCount().length}
                onClose={clearData}
                clearParentData={clearData}
            />
            <div className={`${state.overlay ? 'visible' : ''} overlay`}>
                {state.overlay ?
                    <form
                        className={`${state.modal ? 'show' : ''} send-modal popup-primary large`}
                        noValidate
                        onSubmit={handleSave}
                    >
                        <div className="body">
                            <h3 className="head">
                                Изпращане на документ
                                <img
                                    className="close-icon"
                                    alt="close"
                                    src={closeIcon}
                                    onClick={handleHide}
                                />
                            </h3>
                            <p>
                                Изпратете документа на Е-пощата на Вашия клиент
                            </p>
                            <div className="row label-holder">
                                <div className="col label">
                                    До
                                </div>
                                <div className="col">
                                    <EmailInput
                                        state={state}
                                        relatedContacts={templates.data.contacts}
                                        defaultEmail={templates.data.contact}
                                        setState={setState}
                                        validations={validations}
                                        onChange={handleChangeEmails}
                                        ref={emailInputRef}
                                    />
                                </div>
                            </div>
                            <div className="row label-holder">
                                <div className="col label">
                                    Тема
                                </div>
                                <div className="col">
                                    <div className="email-input-wrapper">
                                        <div className="email-input">
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    name="topic"
                                                    value={state.topic || ''}
                                                    onChange={handleInputChange}
                                                    className={Boolean(validations && validations.topic) ? 'val' : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                <div className="col">
                                    <div className="row label-holder">
                                        <div className="col label">
                                            Съобщение
                                        </div>
                                        <div className="col">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <ClickAwayListener onClickAway={hideTemplates}>
                                                    <div className="active-template">
                                                        <div
                                                            onClick={toggleTemplates}
                                                            className="link row align-center"
                                                            style={{ marginBottom: '0' }}
                                                        >
                                                            <img
                                                                src={templateIcon}
                                                                style={{
                                                                    marginRight: '5px'
                                                                }}
                                                            />
                                                            {getActiveTemplate() ? getActiveTemplate().name : 'Няма избран шаблон'}
                                                        </div>

                                                        <div className={`dropdown ${Boolean(templates.anchorEl) && 'show'}`}>
                                                            <div className="heading">
                                                                <div className="title">
                                                                    Шаблони със съобщения
                                                                </div>
                                                                <div className="lang">
                                                                    <select
                                                                        value={getActiveLangId()}
                                                                        onChange={e => selectLang(e.target.value)}
                                                                    >
                                                                        {langs.data.map(lang =>
                                                                            <option value={lang.id}>{lang.code}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="body">
                                                                {getTemplates().map(template =>
                                                                    <div
                                                                        className={`template ${getActiveTemplateId() === template.id && 'active'}`}
                                                                        onClick={() => {
                                                                            selectTemplate(template)
                                                                        }}
                                                                    >
                                                                        <div className="name">
                                                                            {template.name}
                                                                        </div>
                                                                        <div
                                                                            className="text"
                                                                            dangerouslySetInnerHTML={createMarkup(nl2br(template.text.substring(0, 20)))}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ClickAwayListener>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`message-holder ${Boolean(validations && validations?.text) ? 'val' : ''}`}>
                                        <input type="hidden" name="text" value={getText()} />
                                        <CKEditor
                                            editor={window.ClassicEditor}
                                            data={getText()}
                                            config={{
                                                placeholder: 'Въведете съобщение...',
                                            }}
                                            onChange={(_, editor) => {
                                                handleTextChange(editor)
                                            }}
                                        />
                                        <div className="documents-holder">
                                            <p>
                                                <span>
                                                    Документи за изпращане
                                                </span>
                                            </p>
                                            {Array.isArray(state.ids) &&
                                                <>
                                                    <ul className="mini-documents">
                                                        {state.ids.map(id =>
                                                            <li
                                                                key={'doc-' + id}
                                                                onClick={() => {
                                                                    if (!(templates.data?.documents || {})[id]) return

                                                                    window.open((templates.data?.documents || {})[id].signed_pdf_url, '_blank')
                                                                }}
                                                            >
                                                                {(templates.data?.documents || {})[id] ?
                                                                    <>
                                                                        {templates.data?.documents && templates.data?.documents[id]?.type?.translation?.name} - № {templates.data?.documents && templates.data?.documents[id]?.no}/{templates.data?.documents && templates.data?.documents[id]?.date_dmy} г.
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <img src={loadingImage} className="loader" />
                                                                    </>
                                                                }
                                                            </li>
                                                        )}
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            {(state.ids.length === 1) && getFirstDocument().was_sent ?
                                <SendHistory
                                    getFirstDocument={getFirstDocument()}
                                    appendTemplate={handleAppendTemplate}
                                />
                                :
                                <>
                                </>
                            }
                            <Button
                                onClick={handleHide}
                                className="cancel"
                            >
                                {props.cancelBtnText || 'Отказ'}
                            </Button>
                            <SaveButton
                                loading={state.loading}
                                text="Изпращане"
                                className="save send-mail"
                            />
                        </div>
                    </form>
                    :
                    <>
                    </>
                }
            </div>
        </>
    )
}

export default forwardRef(Send)
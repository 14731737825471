import { forwardRef, useEffect, useImperativeHandle } from 'react'

//providers
import { useLanguageContext } from 'providers/Language'

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import Api from 'helpers/Api'

//MUI components
import { Tabs } from '@material-ui/core'
import { Tab } from '@material-ui/core'
import { Button } from '@material-ui/core'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

function AddOrEdit(props, ref) {
    const langs = useLanguageContext()

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        edit: false,
        data: null,
        tab: langs?.data[0]?.id,
        categoryId: null,
        onSuccess: null
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        add: categoryId => {
            setState(prev => ({
                ...prev,
                categoryId
            }))

            show()
        },
        edit: id => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData();
    }, [state.edit])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            tab: langs?.data[0]?.id,
        }));
    }, [state.modal])

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
            data: null,
            id: null,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }))
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id,
            edit: new Date().getTime()
        }))

        show();
    }

    const loadData = () => {
        if (!state.id) return

        Api.get(`categories/show?id=${state.id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
    }

    const handleSave = e => {
        e.preventDefault()
        setLoading(true)
        setValidations(null)

        const url = state.id ? 'categories/edit' : 'categories/add';
        const data = new FormData(e.target);

        if (state.id) data.append('id', state.id)
        if (state.categoryId) data.append('parent_id', state.categoryId)
        if (props.companyId) data.append('company_id', props.companyId)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                hide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data')
    }

    const handleTabChange = (e, tab) => {
        setState(prev => ({
            ...prev,
            tab
        }))
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Категория
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>
                <div className="body">
                    <div className="row">
                        <Tabs
                            value={state.tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            scrollButtons="auto"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="tabs"
                        >
                            {langs.data.map(lang =>
                                <Tab
                                    key={'lt-' + lang.id}
                                    value={lang.id}
                                    label={lang.name}
                                />
                            )}
                        </Tabs>
                    </div>

                    {langs.data.map(lang =>
                        <div
                            key={'l-' + lang.id}
                            hidden={state.tab !== lang.id}
                        >
                            <RedditTextField
                                size="small"
                                variant="filled"
                                margin="dense"
                                fullWidth
                                label="Наименование"
                                name={`langs[${lang.id}][name]`}
                                value={state.data?.langs[lang.id]?.name || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.langs && validations.langs[lang.id]?.name)}
                                helperText={validations && validations.langs && validations.langs[lang.id] && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                            />
                        </div>
                    )}
                </div>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);
import { Switch } from '@material-ui/core'

const Index = () => {
    return (
        <section id="notifications">
            <div id="head">
                <div className="title">
                    Известия
                </div>
            </div>

            <hr />

            <table className="type-outer">
                <thead>
                    <tr>
                        <th>
                            Документи
                        </th>
                        <th>
                            Е-поща
                        </th>
                        <th>
                            В системата
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Промяна на статус
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Промяна на статус
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Промяна на статус
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="type-outer">
                <thead>
                    <tr>
                        <th>
                            Интеграции
                        </th>
                        <th>
                            Е-поща
                        </th>
                        <th>
                            В системата
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Промяна на статус
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Промяна на статус
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Промяна на статус
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                        <td>
                            <Switch
                                defaultChecked
                                color="primary"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    )
}

export default Index
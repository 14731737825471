import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useLocation, useHistory } from 'react-router-dom'

//MUI components
import { Alert } from '@material-ui/lab'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//partials
import Filter from './partials/Filter'
import Options from './partials/Options'

//providers
import { useDocumentContext } from 'components/pages/documents/Provider'

//modals
import AddOrEdit from './partials/AddOrEdit'
import Question from 'components/modals/Question'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'

let timeout
function Index(props, ref) {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const document = useDocumentContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    })

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd()
        },
        refresh: () => {
            refresh(true)
        }
    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                start_date: query.get('start_date') || '',
                end_date: query.get('end_date') || '',
                search: query.get('search') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1])
                // }
            })

            history.push('?' + query.toString())
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh])

    const loadData = () => {
        loading(true)

        let url = 'incomes/all?resource=1'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: new Date().getTime()
        }))
    }

    const handleAdd = () => {
        const modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = id => {
        const modal = addOrEditModalRef.current

        modal.edit(id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('incomes/delete', {
            id
        })
            .then(res => {
                if (res.data.success) refresh()
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }))
    }

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
                companyId={state.filter.company_id}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />


            {/* <div id="head" className="row space-between">
                <div className="title">
                    Приходи
                </div>
                <div
                    className="row right"
                    style={{
                        width: 'fit-content'
                    }}
                >
                    <Button
                        className="add"
                        onClick={handleAdd}
                    >
                        Добави
                    </Button>

                    <Button
                        className="normal"
                        onClick={() => {
                            history.push('/settings?menu=3&submenu=3_1')
                        }}
                        style={{ marginLeft: '10px' }}
                    >
                        Списък с категории
                    </Button>
                </div>
            </div> */}

            {/* <div className="panel"> */}
            <>
                <Filter
                    filter={state.filter}
                    handleSearch={handleSearch}
                />

                {state.loading
                    ?
                    <Skeleton count={5} height={60} />
                    :
                    state.data.length === 0 ?
                        <Alert severity="error">
                            Няма намерена информация!
                        </Alert>
                        :
                        <>
                            <table className="type-outer">
                                <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Приход</th>
                                        <th>Клиент</th>
                                        <th>Начин на плащане</th>
                                        <th>Документ</th>
                                        <th className="text-right">Сума нето</th>
                                        <th className="text-right">ДДС</th>
                                        <th className="text-right">Сума бруто</th>
                                        <th className="text-center">Файлове</th>
                                        <th className="text-center">Опции</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(c =>
                                        <tr
                                            key={'c-' + c.id}
                                            onClick={e => {
                                                if (e.target) {
                                                    if (e.target.classList?.contains('MuiButtonBase-root') || (e.target.getAttribute('aria-hidden') === 'true') || e?.target?.classList?.contains('link')) return
                                                }

                                                handleEdit(c.id)
                                            }}
                                        >
                                            <td className='date'>
                                                {c.date_dmy}
                                            </td>

                                            <td className='product-with-cat'>
                                                {c.category?.translation?.name || ''} {c.category?.translation?.name && (c.product?.translation?.name || c.product?.nam) ? '→' : ''} {c.product?.translation?.name || c.product?.name || '-'}
                                            </td>
                                            <td>
                                                {c?.customer?.info?.name || '-'}
                                            </td>
                                            <td>
                                                {c.paymentmethod?.translation?.name || '-'}
                                            </td>
                                            <td>
                                                {c.document
                                                    ?
                                                    <u
                                                        onClick={() => {
                                                            document.handlePreviewInvoice(c.document)
                                                        }}
                                                        className="link"
                                                    >
                                                        №{c.document?.no}
                                                    </u>
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td className="text-right">
                                                {c.net_formatted} {c.currency?.name}
                                            </td>
                                            <td className="text-right">
                                                {Number(c.vat_perc)}% ({c.vat_formatted} {c.currency?.name})
                                            </td>
                                            <td className="text-right">
                                                {c.gross_formatted} {c.currency?.name}
                                            </td>
                                            <td className="text-center">
                                                {c.files_count}
                                            </td>
                                            <td className="text-center">
                                                <MenuOptions>
                                                    <Options
                                                        data={c}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </MenuOptions>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <Pagination
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </>
                }
            </>
            {/* // </div> */}


        </>
    )
}

export default forwardRef(Index)
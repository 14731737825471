//partials
import IncomeCategories from "./incomes/Index"
import ExpenseCategories from "./expenses/Index"

function Categories(props) {
    const screen = props.screen || '3_1'

    return (
        screen === '3_1' ?
            <IncomeCategories />
            :
            screen === '3_2' ?
                <ExpenseCategories />
                :
                <>
                </>
    )
}

export default Categories
//MUI components
import { Button } from '@material-ui/core'

//images
import emptyListIcon from 'assets/img/app/icons/empty-list.png'

const EmptyList = props => {
    const { infoText, btnText } = props

    return (
        <div className="empty-list">
            <img src={emptyListIcon} className="main-image" />
            <h2>
                Списъкът е празен
            </h2>
            <p>
                {infoText || "Не бяха намерени данни от Вашето търсене"}
            </p>
            <Button
                className="add"
                onClick={() => {
                    document.querySelector('.add.with-dropdown').click()
                }}
            >
                {btnText || 'Нов документ'}
            </Button>
        </div>
    )
}

export default EmptyList
//miscs
import Autocomplete from "components/misc/Autocomplete"
import useCompanySettlementInputFieldHooks from "../useCompanySettlementInputFieldHook"

const SettlementInputField = () => {
    const { 
        register,
        handleCustomerSettlementChange
     } = useCompanySettlementInputFieldHooks()
    const { companyData } = register

    return (
        <>
            <Autocomplete
                inputPlaceholder="Населено място"
                inputName={`customeraddress[settlement][name]`}
                inputIdName={`customeraddress[settlement][id]`}
                url="settlements/all"
                selectedId={companyData?.data?.settlementId || null}
                selectedValue={companyData?.data?.settlement || ''}
                getOptionLabel={data => data?.name || ''}
                renderOption={option => {
                    return [
                        option.name,
                        option.municipality ? 'общ. ' + option.municipality.name : null,
                        option.municipality && option.municipality.district ? 'обл. ' + option.municipality.district.name : null
                    ].filter(o => {
                        return o !== null
                    }).join(', ')
                }}
                onChange={handleCustomerSettlementChange}
                onInputChange={handleCustomerSettlementChange}
            />
        </>
    )
}

export default SettlementInputField
import { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Layout from '../../Layout'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'

//misc
import Pagination from '../../misc/Pagination'
import MenuOptions from '../../misc/MenuOptions'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//modals
import Question from '../../modals/Question'
import AddOrEdit from './partials/AddOrEdit'

//providers
import { useAuthDataContext } from 'providers/Auth'

//partials
import Filter from './partials/Filter'
import Options from './partials/Options'

let timeout
function Index() {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
            company_id: query.get('company_id') || auth.getUser()?.getCompany()?.id,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                start: query.get('start') || '',
                end: query.get('end') || '',
                company_id: query.get('company_id') || prev.filter.company_id || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1]);
                // }
            });

            history.push('?' + query.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh]);

    const loadData = () => {
        if (!state.filter.company_id) return

        loading(true)

        let url = 'customers/all?resource=1'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            })
            .finally(() => {
                loading(false);
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: new Date().getTime()
        }));
    }

    const handleAdd = () => {
        history.push('/customers/add?company_id=' + state.filter.company_id)
    }

    // update
    const handleEdit = id => {
        history.push('/customers/edit/' + id)
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('customers/delete', {
            id
        })
            .then(res => {
                if (res.data.success) refresh()
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
            />

            <Layout>
                <section id="documents">
                    <div id="head" className="row space-between">
                        <div className="title">
                            Клиенти
                        </div>
                        <div
                            className="row right"
                            style={{
                                width: 'fit-content'
                            }}
                        >
                            <Button
                                className="add"
                                onClick={handleAdd}
                            >
                                Добави
                            </Button>
                        </div>
                    </div>

                    {!state.filter.company_id
                        ?
                        <NoSelectedCompany />
                        :
                        <div className="panel">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />
                            {state.loading
                                ?
                                <Skeleton count={5} height={60} />
                                :
                                state.data.length === 0
                                    ?
                                    <Alert severity="error">
                                        Все още нямате добавени клиенти!
                                    </Alert>
                                    :
                                    <>

                                        <table className="type-outer">
                                            <thead>
                                                <tr>
                                                    <th>Наименование</th>
                                                    <th>ЕИК/ЕГН</th>
                                                    <th>Адрес</th>
                                                    <th className="text-center">Опции</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.map(c =>
                                                    <tr
                                                        key={'c-' + c.id}
                                                        onClick={e => {
                                                            if (e.target) {
                                                                if (e.target.classList?.contains('MuiButtonBase-root') || e.target.getAttribute('aria-hidden') === 'true') return
                                                            }

                                                            handleEdit(c.id)
                                                        }}
                                                    >
                                                        <td>
                                                            {c?.info?.name}
                                                        </td>
                                                        <td>
                                                            {c?.info?.eikegn}
                                                        </td>
                                                        <td>
                                                            {c.address?.full_address}
                                                        </td>
                                                        <td className="text-center">
                                                            <MenuOptions>
                                                                <Options
                                                                    data={c}
                                                                    handleEdit={handleEdit}
                                                                    handleDelete={handleDelete}
                                                                />
                                                            </MenuOptions>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                        />
                                    </>
                            }
                        </div>
                    }
                </section>
            </Layout>
        </>
    )
}

export default Index;
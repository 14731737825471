import { useEffect, useState } from 'react'
import moment from 'moment'

//MUI components
import { Button, Tooltip, Popover } from '@material-ui/core'

//images
import sendNoticeIncon from 'assets/img/app/icons/send-notice.png'
import Api from 'helpers/Api'

const SendHistory = props => {
    const { getFirstDocument, appendTemplate }  = props
    const { no, date_dmy }                      = getFirstDocument
    const [state, setState] = useState({
        historyPopover    : null,
        data              : []
    })

    useEffect(() => {
        if (!getFirstDocument.id) return

        Api.get(`documents/sent-emails?id=${getFirstDocument.id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data    : res.data.items
                }))
            })
    }, [])

    /**
     * 
     * @param {node|null} el референция към елемента, към който искаме да закачим popover | скриване на popover
     */
    const setPopoverElement = (el = null) => {
        setState(prev => ({
            ...prev,
            historyPopover    : el,
        }))
    }

    const parsDate = date => moment(date).format('DD.MM.YYYY')

    const parsTime = date => moment(date).format('HH:mm')

    const handleAppendTemplate = data => {
        const { message, title }  = data
        const email               = data.contact.email

        appendTemplate(message, title, email)
        setPopoverElement()
    }
    
    return (
        <>
            <Popover
                open            = {Boolean(state.historyPopover)}
                anchorEl        = {state.historyPopover}
                onClose={() => {
                    setPopoverElement(null)
                }}
                anchorOrigin={{
                    vertical    : 'top',
                    horizontal  : 'left',
                }}
                className       = "popover-send-history"
            >
                <b>
                    Фактура {no} / {date_dmy} г.
                </b>
                <p>
                    Изпратена до:
                </p>
                <table className="type-doc">
                    <thead>
                        <tr>
                            <th>
                                ДАТА
                            </th>
                            <th>
                                КЛИЕНТ / Е-ПОЩА
                            </th>
                            <th>
                                ФИРМА
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(state.data || []).map(d =>
                            <tr>
                                <td>
                                    {parsTime(d.sent_at)} ч.
                                    <br />
                                    {parsDate(d.sent_at)} г.
                                </td>
                                <td>
                                    <div
                                        className="person"
                                    >
                                        <div className="heading">
                                            <picture>
                                                <img src={d.contact?.avatar || ''} />
                                            </picture>
                                            {d.contact?.name || ''}
                                        </div>
                                        <a href={`mailto:${d.contact?.email || ''}`} className="email link">
                                            {d.contact?.email || ''}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    {d.contact?.company_name || '-'}
                                </td>
                                <td>
                                    <Button
                                        className   = "save send-mail"
                                        onClick={() => {
                                            handleAppendTemplate(d)
                                        }}
                                    >
                                        Изпращане
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Popover>
            <Tooltip title="Вижте история на изпращанията">
                <div
                    className   = "notice clickable"
                    onClick={e => {
                        setPopoverElement(e.target)
                    }}
                >
                    <img src={sendNoticeIncon} />
                    Вижте  клиентите, до които вече сте<br /> изпратили избрания документ
                </div>
            </Tooltip>
        </>
    )
}

export default SendHistory
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import loader from 'assets/img/loader.svg';
import closeIcon from 'assets/img/modals/icons/close.png';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import SaveButton from 'components/misc/Button';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Autocomplete from 'components/misc/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useAuthDataContext } from 'providers/Auth';
import { useLanguageContext } from 'providers/Language';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();
    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const defaultData = {
        files: [],
        materials: [],
        users: [],
        machines: [],
        tags: [],
    }

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        edit: false,
        data: defaultData,
        tab: langs?.data[0]?.id,
        onSuccess: null
    });

    const [validations, setValidations] = useValidation();

    const inputFileRef = useRef(null);

    // useEffect(() => {
    //     console.log(validations);
    // }, [validations]);

    useImperativeHandle(ref, () => ({
        add: () => {
            show();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
            data: defaultData
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
            data: defaultData,
            id: null,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 150);

        setValidations(null);
    }

    const handleHide = e => {
        e.preventDefault();

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('projects/tasks/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'projects/tasks/edit' : 'projects/tasks/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id);
        }

        if (props.companyId) {
            data.append('company_id', props.companyId);
        }

        if (state.data.files) {
            state.data.files.map(file => {
                if (file.was_recently_attached) {
                    data.append('files[]', file);
                }
            });
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }

                hide();

            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    // console.log(_err.data.errors);
                    setValidations(_err.data.errors);
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const handleDateChange = e => {
        let name = e.target.name + '_ymd';
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const handleTabChange = (e, value) => {
        setState(prev => ({
            ...prev,
            tab: value
        }));
    }

    const handleProjectChange = data => {
        if (data?.id || data === null) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    project: data,
                    stage: null,
                }
            }))
        }
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: (prev.data.files || []).concat(files)
            }
        }));
    }

    const handleDetach = id => {

        let file = state.data.files.filter((f, i) => {
            return Number(f.id) === Number(id);
        })[0];

        if (!file.was_recently_attached) {
            Api.post('projects/tasks/remove-file', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    removeFile(id);
                }
            });
        } else {
            removeFile(id);
        }
    }

    const removeFile = id => {
        let files = state.data.files.filter((f, i) => {
            return Number(f.id) !== Number(id);
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: files
            }
        }));
    }

    const handleAddMaterialRow = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                materials: (prev.data.materials || []).concat({})
            }
        }));
    }

    const handleRemoveMaterialRow = index => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                materials: prev.data.materials.filter((row, i) => i !== index)
            }
        }));
    }

    const handleAddMachineRow = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                machines: (prev.data.machines || []).concat({})
            }
        }));
    }

    const handleRemoveMachineRow = index => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                machines: prev.data.machines.filter((row, i) => i !== index)
            }
        }));
    }

    const handleAddTagRow = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                tags: (prev.data.tags || []).concat({})
            }
        }));
    }

    const handleRemoveTagRow = index => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                tags: prev.data.tags.filter((row, i) => i !== index)
            }
        }));
    }

    const handleChangeTag = (data, index) => {

        if (!data?.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                tags: Object.values({
                    ...prev.data.tags,
                    [index]: {
                        ...prev.data.tags[index],
                        tag: data
                    }
                })
            }
        }));
    }

    const tagIsSelected = (id, currentId) => {

        if (Number(id) === Number(currentId)) {
            return false;
        }

        let tags = state.data.tags || [];
        let ids = tags.map(t => t.tag?.id).filter(id => id !== undefined);

        return ids.includes(id);
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Задача
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <Box className="body">

                    <Tabs
                        value={state.tab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        scrollButtons="auto"
                        textColor="primary"
                        variant="scrollable"
                        aria-label="tabs"
                    >
                        {langs.data.map(lang =>
                            <Tab key={'lt-' + lang.id} value={lang.id} label={lang.name} />
                        )}
                    </Tabs>

                    <Autocomplete
                        freeSolo={false}
                        inputIdName="type_id"
                        inputPlaceholder="Тип"
                        url="projects/tasks/types/all"
                        params={{
                            company_id: props.companyId
                        }}
                        requiredParams={['company_id']}
                        selected={state.data?.type || null}
                        getOptionLabel={option => option?.translation?.name}
                        renderOption={option => option?.translation?.name}
                        // onChange={handleCategoryChange}
                        error={Boolean(validations && validations.type_id)}
                        helperText={validations && validations.type_id && (validations.type_id[0] || validations.type_id)}
                    />

                    <Autocomplete
                        freeSolo={false}
                        inputIdName="project_id"
                        inputPlaceholder="Проект"
                        url="projects/all"
                        params={{
                            company_id: props.companyId
                        }}
                        requiredParams={['company_id']}
                        selected={state.data?.project || null}
                        getOptionLabel={option => option?.translation?.name}
                        renderOption={option => option?.translation?.name}
                        onChange={handleProjectChange}
                        error={Boolean(validations && validations.project_id)}
                        helperText={validations && validations.project_id && (validations.project_id[0] || validations.project_id)}
                    />

                    <Autocomplete
                        freeSolo={false}
                        inputIdName="stage_id"
                        inputPlaceholder="Етап"
                        url="projects/stages"
                        params={{
                            project_id: state.data?.project?.id
                        }}
                        requiredParams={['project_id']}
                        selected={state.data?.stage || null}
                        getResultData={data => data}
                        getOptionLabel={option => option?.translation?.name}
                        renderOption={option => option?.translation?.name}
                        // onChange={handleCategoryChange}
                        inputDisabled={!state.data?.project?.id}
                        error={Boolean(validations && validations.stage_id)}
                        helperText={validations && validations.stage_id && (validations.stage_id[0] || validations.stage_id)}
                    />

                    <Grid spacing={2} container>
                        <Grid item xs={6}>
                            <Autocomplete
                                freeSolo={false}
                                inputIdName="priority_id"
                                inputPlaceholder="Приоритет"
                                url="projects/tasks/priorities/all"
                                params={{
                                    company_id: props.companyId
                                }}
                                requiredParams={['company_id']}
                                selected={state.data?.priority || null}
                                getOptionLabel={option => option?.translation?.name}
                                renderOption={option => option?.translation?.name}
                                // onChange={handleCategoryChange}
                                error={Boolean(validations && validations.priority_id)}
                                helperText={validations && validations.priority_id && (validations.priority_id[0] || validations.priority_id)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Autocomplete
                                freeSolo={false}
                                inputIdName="show_after_id"
                                inputPlaceholder="Да се показва след изпълнение на"
                                url="projects/tasks/find-show-after-tasks"
                                params={{
                                    company_id: props.companyId,
                                    id: state.data?.id
                                }}
                                requiredParams={['company_id']}
                                selected={state.data?.showafter || null}
                                getOptionLabel={option => option?.translation?.name}
                                renderOption={option => option?.translation?.name}
                                // onChange={handleCategoryChange}
                                error={Boolean(validations && validations.show_after_id)}
                                helperText={validations && validations.show_after_id && (validations.show_after_id[0] || validations.show_after_id)}
                            />
                        </Grid>
                    </Grid>

                    {langs.data.map(lang =>
                        <div
                            key={'l-' + lang.id}
                            hidden={state.tab !== lang.id}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Заглавие"
                                name={`langs[${lang.id}][name]`}
                                value={state.data?.langs && state.data?.langs[lang.id]?.name || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.langs && validations.langs[lang.id]?.name)}
                                helperText={validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Описание"
                                name={`langs[${lang.id}][description]`}
                                value={state.data?.langs && state.data?.langs[lang.id]?.description || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.langs && validations.langs[lang.id]?.description)}
                                helperText={validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id]?.description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].name)}
                                multiline
                                rows={4}
                            />
                        </div>
                    )}

                    <Grid spacing={2} container>
                        <Grid item xs={5}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Планирано начало"
                                name="start_at"
                                type="datetime-local"
                                value={state.data?.start_at ? moment(state.data.start_at).utc().format('YYYY-MM-DDTHH:MM') : ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.start_at)}
                                helperText={validations && validations.start_at && (validations.start_at[0] || validations.start_at)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Време за изпълнение (мин.)"
                                name="recommended_exec_time"
                                type="number"
                                min="0"
                                step="0.1"
                                value={state.data?.recommended_exec_time || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.recommended_exec_time)}
                                helperText={validations && validations.recommended_exec_time && (validations.recommended_exec_time[0] || validations.recommended_exec_time)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Цвят"
                                name="color"
                                type="color"
                                value={state.data?.color || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.color)}
                                helperText={validations && validations.color && (validations.color[0] || validations.color)}
                            />
                        </Grid>
                    </Grid>

                    <Autocomplete
                        variant="outlined"
                        freeSolo={false}
                        multiple
                        inputPlaceholder="Отговорници"
                        inputIdName="users[]"
                        url="users/all"
                        params={{
                            resource: 1,
                            company_id: props.companyId
                        }}
                        value={state.data?.users || []}
                        // onChange={handleCompanyChange}
                        // onInputChange={handleCompanyChange}
                        error={Boolean(validations && validations.users)}
                        helperText={validations && validations.users && (validations.users[0] || validations.users)}
                    />

                    {Array.isArray(state.data.materials)
                        ?
                        state.data.materials.length === 0
                            ?
                            <p style={{ margin: '10px 0' }}>Няма използвани материали</p>
                            :
                            state.data.materials.map((row, index) => (
                                <Grid key={'material-' + index} spacing={2} container>
                                    <Grid item xs={5}>
                                        <Autocomplete
                                            variant="outlined"

                                            inputPlaceholder="Материал"
                                            inputName={`materials[${index}][material][name]`}
                                            inputIdName={`materials[${index}][material][id]`}
                                            url="projects/materials/all"
                                            params={{
                                                resource: 1,
                                                company_id: props.companyId
                                            }}
                                            selected={row.material}
                                            getOptionLabel={option => option?.translation?.name || ''}
                                            renderOption={option => option?.translation?.name || ''}
                                            // onChange={handleCompanyChange}
                                            // onInputChange={handleCompanyChange}
                                            error={Boolean(validations && validations.materials && validations.materials[index] && validations.materials[index].material && validations.materials[index].material.name && (validations.materials[index].material.name[0] || validations.materials[index].material.name))}
                                            helperText={validations && validations.materials && validations.materials[index] && validations.materials[index].material && validations.materials[index].material.name && (validations.materials[index].material.name[0] || validations.materials[index].material.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Количество"
                                            name={`materials[${index}][units]`}
                                            type="number"
                                            min="0"
                                            step="0.1"
                                            value={row.units || ''}
                                            onChange={handleInputChange}
                                            error={Boolean(validations && validations.materials && validations.materials[index] && validations.materials[index].units && (validations.materials[index].units[0] || validations.materials[index].units))}
                                            helperText={validations && validations.materials && validations.materials[index] && validations.materials[index].units && (validations.materials[index].units[0] || validations.materials[index].units)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} style={{ display: 'flex' }}>
                                        <Autocomplete
                                            inputPlaceholder="Мярка"
                                            inputName={`materials[${index}][unit][name]`}
                                            inputIdName={`materials[${index}][unit][id]`}
                                            url="units/all"
                                            selected={row.unit || ''}
                                            getOptionLabel={option => option?.translation?.name || ''}
                                            renderOption={option => option?.translation?.name || ''}
                                            error={Boolean(validations && validations.materials && validations.materials[index] && validations.materials[index].unit && validations.materials[index].unit.name && (validations.materials[index].unit.name[0] || validations.materials[index].unit.name))}
                                            helperText={validations && validations.materials && validations.materials[index] && validations.materials[index].unit && validations.materials[index].unit.name && (validations.materials[index].unit.name[0] || validations.materials[index].unit.name)}
                                            autoSelect={row.id ? false : true}
                                        />

                                    </Grid>
                                    <Grid item xs={1}>
                                        <DeleteIcon
                                            style={{ marginTop: '30px', cursor: 'pointer' }}
                                            onClick={e => handleRemoveMaterialRow(index)}
                                        />
                                    </Grid>
                                </Grid>
                            ))
                        :
                        ''
                    }

                    <br />

                    <Button
                        variant="outlined"
                        onClick={e => handleAddMaterialRow()}
                    >
                        Добави материал
                    </Button>

                    <br />

                    {Array.isArray(state.data.machines)
                        ?
                        state.data.machines.length === 0
                            ?
                            <p style={{ margin: '10px 0' }}>Няма използвани машини</p>
                            :
                            state.data.machines.map((row, index) => (
                                <Grid key={'machines-' + index} spacing={2} container>
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            variant="outlined"

                                            inputPlaceholder="Машина"
                                            inputName={`machines[${index}][type][name]`}
                                            inputIdName={`materials[${index}][type][id]`}
                                            url="projects/machines/types/all"
                                            params={{
                                                resource: 1,
                                                company_id: props.companyId
                                            }}
                                            selected={row.type}
                                            getOptionLabel={option => option?.translation?.name || ''}
                                            renderOption={option => option?.translation?.name || ''}
                                            // onChange={handleCompanyChange}
                                            // onInputChange={handleCompanyChange}
                                            error={Boolean(validations && validations.machines && validations.machines[index] && validations.machines[index].type && validations.machines[index].type.name && (validations.machines[index].type.name[0] || validations.machines[index].type.name))}
                                            helperText={validations && validations.machines && validations.machines[index] && validations.machines[index].type && validations.machines[index].type.name && (validations.machines[index].type.name[0] || validations.machines[index].type.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Брой"
                                            name={`machines[${index}][count]`}
                                            type="number"
                                            min="0"
                                            step="0.1"
                                            value={row.count || ''}
                                            onChange={handleInputChange}
                                            error={Boolean(validations && validations.machines && validations.machines[index] && validations.machines[index].count && (validations.machines[index].count[0] || validations.machines[index].count))}
                                            helperText={validations && validations.machines && validations.machines[index] && validations.machines[index].count && (validations.machines[index].count[0] || validations.machines[index].count)}
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <DeleteIcon
                                            style={{ marginTop: '30px', cursor: 'pointer' }}
                                            onClick={e => handleRemoveMachineRow(index)}
                                        />
                                    </Grid>
                                </Grid>
                            ))
                        :
                        ''
                    }

                    <br />

                    <Button
                        variant="outlined"
                        onClick={e => handleAddMachineRow()}
                    >
                        Добави машина
                    </Button>

                    <br />

                    {Array.isArray(state.data.tags)
                        ?
                        state.data.tags.length === 0
                            ?
                            <p style={{ margin: '10px 0' }}>Няма добавени етикети</p>
                            :
                            state.data.tags.map((row, index) => (
                                <Grid key={'tags-' + index} spacing={2} container>
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            variant="outlined"
                                            inputPlaceholder="Етикет"
                                            inputName={`tags[${index}][tag][name]`}
                                            inputIdName={`tags[${index}][tag][id]`}
                                            url="projects/tags/all"
                                            params={{
                                                resource: 1,
                                                company_id: props.companyId,
                                            }}
                                            selected={row.tag}
                                            getOptionLabel={option => option?.translation?.name || ''}
                                            renderOption={option => option?.translation?.name || ''}
                                            getOptionDisabled={option => tagIsSelected(option.id, row.tag?.id)}
                                            onChange={data => handleChangeTag(data, index)}
                                            // onInputChange={handleCompanyChange}
                                            error={Boolean(validations && validations.tags && validations.tags[index] && validations.tags[index].tag && validations.tags[index].tag.name && (validations.tags[index].tag.name[0] || validations.tags[index].tag.name))}
                                            helperText={validations && validations.tags && validations.tags[index] && validations.tags[index].tag && validations.tags[index].tag.name && (validations.tags[index].tag.name[0] || validations.tags[index].tag.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Цвят"
                                            name={`tags[${index}][tag][color]`}
                                            type="color"
                                            value={row?.tag?.color || ''}
                                            onChange={handleInputChange}
                                            error={Boolean(validations && validations.tags && validations.tags[index] && validations.tags[index].tag && validations.tags[index].tag.color && (validations.tags[index].tag.color[0] || validations.tags[index].tag.color))}
                                            helperText={validations && validations.tags && validations.tags[index] && validations.tags[index].tag && validations.tags[index].tag.color && (validations.tags[index].tag.color[0] || validations.tags[index].tag.color)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <DeleteIcon
                                            style={{ marginTop: '30px', cursor: 'pointer' }}
                                            onClick={e => handleRemoveTagRow(index)}
                                        />
                                    </Grid>
                                </Grid>
                            ))
                        :
                        ''
                    }

                    <br />

                    <Button
                        variant="outlined"
                        onClick={e => handleAddTagRow()}
                    >
                        Добави етикет
                    </Button>

                    {(state.data?.files || []).length === 0
                        ?
                        <p style={{ margin: '10px 0' }}>Няма прикачени файлове</p>
                        :
                        (state.data?.files || []).map(f =>
                            <div key={'f-' + f.id} style={{
                                marginBottom: '10px',
                                alignItems: 'center'
                            }}>
                                <div className="col">
                                    <label>
                                        <a href={f.url} target="_blank">{f.original_name || f.name}</a>
                                    </label>
                                </div>
                                <div className="col auto" style={{ width: 'fit-content' }}>
                                    <a onClick={() => handleDetach(f.id)}>
                                        X
                                    </a>
                                </div>
                            </div>
                        )}

                    <Button variant="outlined" onClick={handleShowAttach}>
                        Прикачи файл
                    </Button>

                    <input ref={inputFileRef} type="file" onChange={handleAttach} hidden multiple />

                </Box>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div >
    )
}

export default forwardRef(AddOrEdit);
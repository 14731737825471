import { useRef, useState } from "react"

//partials
import NumbersOffer from "./partials/Offer"
import NumbersProforma from "./partials/Proforma"
import NumbersInvoice from "./partials/Invoice"
import NumbersCreditNote from "./partials/CreditNote"
import NumbersDebitNote from "./partials/DebitNote"

//modals
import Question from 'components/modals/Question'
import AddOrEdit from './partials/AddOrEdit'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from "providers/App"

//helpers
import Api from 'helpers/Api'

function Numbers() {
    const app = useAppContext()
    const auth = useAuthDataContext()
    const companyId = auth.getUser()?.getCompany()?.id

    const [state, setState] = useState({
        refresh: {}
    })

    const deleteModalRef = useRef(null)
    const addOrEditModalRef = useRef(null)

    const handleRefresh = typeId => {
        setState(prev => ({
            ...prev,
            refresh: {
                ...prev,
                [typeId]: new Date()
            }
        }))
    }

    /**
     * 
     * @param {number} typeId type_id на номера 
     */
    const handleAdd = typeId => {
        const modal = addOrEditModalRef.current

        modal.add(typeId)

        modal.onSuccess(data => {
            handleRefresh(data.type_id)
            app.handleSuccess()
        })
    }

    /**
     * 
     * @param {object} data данните на номера
     * @param {number} typeId type_id на номера 
     */
    const handleEdit = (data, typeId) => {
        const modal = addOrEditModalRef.current

        modal.edit(data, typeId)

        modal.onSuccess(data => {
            handleRefresh(data.type_id)
            app.handleSuccess()
        })
    }

    const handleDelete = data => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(data)
        })
    }

    const handleDestroy = data => {
        const { id, type_id } = data

        Api.post('documents/serials/delete', {
            id
        })
            .then(res => {
                if (res.data.success) handleRefresh(type_id)
            })
    }

    /**
     * 
     * @param {node} target елемента, върху който е кликнато 
     * @returns дали реда е кликаем
     */
    const isRowNotClickable = target => {
        let clickable = true

        if (target) {
            if (
                target.classList?.contains('MuiButtonBase-root') ||
                (target.getAttribute('aria-hidden') === 'true') ||
                (document.querySelector('body').getAttribute('style') === 'overflow: hidden;')
            ) {
                clickable = false
            }
        }

        return clickable
    }

    return (
        <section id="numbers">
            <AddOrEdit
                ref={addOrEditModalRef}
                companyId={companyId}
            />
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <div className="row">
                <div className="col">
                    <NumbersOffer
                        isRowNotClickable={isRowNotClickable}
                        companyId={companyId}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        key={state.refresh[1]}
                    />
                </div>
                <div className="col">
                    <NumbersProforma
                        isRowNotClickable={isRowNotClickable}
                        companyId={companyId}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        key={state.refresh[2]}
                    />
                </div>
                <div className="col">
                    <NumbersInvoice
                        isRowNotClickable={isRowNotClickable}
                        companyId={companyId}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        key={state.refresh[3]}
                    />
                </div>
            </div>
        </section>
    )
}

export default Numbers
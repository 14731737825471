import { useState, useRef, useEffect } from 'react'
import printJS from 'print-js'
import { PDFReader, MobilePDFReader } from 'reactjs-pdf-reader'
import { useHistory, useParams } from 'react-router-dom'

//MUI components
import { Menu, Tooltip } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'

//providers
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'
import { useLanguageContext } from 'providers/Language'
import { usePublicPreviewContext } from './PublicPreviewProvider'

//modals
import Question from 'components/modals/Question'

//partials
import Options from '../PublicOptions'
import PageLoader from 'components/misc/PageLoader'
import MenuIndicator from 'components/misc/MenuIndicator'
import PublicPreviewAuthScreen from './PublicPreviewAuthScreen'

//images
import brandImage from 'assets/img/app/brand-full-gray.png'

let timeout
function Index() {
    const publicPreview = usePublicPreviewContext()
    const params = useParams()
    const history = useHistory()

    const langs = useLanguageContext()
    const auth = useAuthDataContext()
    const app = useAppContext()

    const [state, setState] = useState({
        download: false,
        loading: true,
        refresh: null,
        showMenu: null,
        successCopied: false,
        successCopiedOnMobile: false,
    });

    const fullWidth = 793;

    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(fullWidth);

    //refs
    const makeUnpaidModalRef = useRef(null)
    const publicPreviewAuthScreenRef = useRef(null)
    //end refs

    useEffect(() => {
        setLoading(true)

        Api.get('documents/find-by-hash', {
            params: {
                hash: params.hash
            }
        })
            .then(res => {
                const { customer, customeraddress, has_registered_company } = res.data
                publicPreview.setDocument(prev => ({
                    ...prev,
                    data: res.data,
                    address: customer?.address.address || '',
                    mol: customer?.address?.mol || '',
                    eik: customer?.info?.eikegn || '',
                    'settlement[name]': customeraddress?.settlement?.name || '',
                    'settlement[id]': customeraddress?.settlement?.id || '',
                    'settlement[municipality_id]': customeraddress?.settlement?.municipality_id || '',
                    name: customer?.info?.name || ''
                }))

                publicPreview.setMode(has_registered_company ? 'login' : 'register')
            })
            .catch(err => {
                const _err = err.response

                if (_err && _err.status && _err.status === 404) {
                    // history.replace('/')
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [params.hash])

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @returns езикът по подразбиране
     */
    const getDefaultLang = () => langs.data.find(l => l.default === 1)


    const handleDownload = () => {
        window.open(publicPreview.document.data.download_pdf_url)
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date()
        }))
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }

    /**
     * 
     * @param {node|null} showMenu референця към менюто чрез e.target | менюто да е скрито 
     */
    const handleShowMenu = showMenu => {
        setState(prev => ({
            ...prev,
            showMenu
        }))
    }
    const handlePrint = () => {
        printJS({
            printable: publicPreview.document.data.print_url,
            onLoadingStart: () => {
                console.log('start')
            },
            onLoadingEnd: () => {
                console.log('end')
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(publicPreview.document.data.url);

        setState(prev => ({
            ...prev,
            successCopied: true
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                successCopied: false
            }))
        }, 2000)
    }

    const handleCopyLinkMobile = () => {
        navigator.clipboard.writeText(publicPreview.document.data.url);

        setState(prev => ({
            ...prev,
            successCopiedOnMobile: true
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                successCopiedOnMobile: false
            }))
        }, 2000)
    }


    const handleNavigateToMyDocuments = () => {
        if (auth.isLogged()) history.push('/')
        else showAuthMenu()
    }

    const showAuthMenu = () => {
        publicPreviewAuthScreenRef.current.open(true)
    }

    const onLoad = e => {
        const iframe = e.target.innerHTML
    }

    useEffect(() => {
        const onResize = () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                calculateWidth();
            }, 50);
        }

        const calculateWidth = () => {
            let pageWidth = window.innerWidth;
            let width = fullWidth;

            if (pageWidth <= fullWidth) {
                width = pageWidth - 40;
            }

            let isMobile = pageWidth <= fullWidth;

            setWidth(width);
            setIsMobile(isMobile);
        }

        calculateWidth();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    return (
        state.loading
            ?
            <PageLoader show />
            :
            <section id="preview">

                <Question
                    ref={makeUnpaidModalRef}
                    mainMessage="Сигурни ли сте?"
                />

                <div className="header">
                    <img className="brand" src={brandImage} />

                    <div className="buttons">
                        <button
                            className="normal download hidden-mobile"
                            onClick={() => {
                                handleDownload()
                            }}
                        >
                            Изтегляне
                        </button>

                        <button
                            className={`normal print hidden-mobile`}
                            onClick={() => {
                                handlePrint()
                            }}
                        >
                            Принтиране
                        </button>

                        <Tooltip
                            title="Връзката беше копирана."
                            open={state.successCopied}
                        >
                            <button
                                className={`normal link hidden-mobile`}
                                onClick={() => {
                                    handleCopyLink()
                                }}
                            >
                                Копиране на линк
                            </button>
                        </Tooltip>

                        <Tooltip
                            title="Връзката беше копирана."
                            open={state.successCopiedOnMobile}
                        >
                            <button
                                className="small hidden-desktop"
                                onClick={e => {
                                    handleShowMenu(e.currentTarget)
                                }}
                            >
                                <MenuIndicator
                                    active={Boolean(state.showMenu)}
                                    style={{
                                        margin: '0 auto',
                                    }}
                                />
                            </button>
                        </Tooltip>

                        <Menu
                            anchorEl={state.showMenu}
                            keepMounted
                            open={Boolean(state.showMenu)}
                            onClose={() => {
                                handleShowMenu(null)
                            }}
                            onClick={() => {
                                handleShowMenu(null)
                            }}
                            anchorOrigin={{ vertical: 'bottom' }}
                            className="custom-menu-options no-shadow"
                        >
                            <Options
                                data={publicPreview.document.data}
                                handleCopyLink={handleCopyLinkMobile}
                                handlePrint={handlePrint}
                                handleDownload={handleDownload}
                                handleLogin={handleNavigateToMyDocuments}
                            />
                        </Menu>

                    </div>

                    <button
                        className="normal login-button hidden-mobile"
                        onClick={handleNavigateToMyDocuments}
                    >
                        Моите фактури
                    </button>
                </div>


                <div className="doc">
                    {isMobile
                        ?
                        <div>
                            <PDFReader
                                key={publicPreview.document.data.signed_pdf_url + width}
                                url={publicPreview.document.data.signed_pdf_url}
                                width={width}
                                showAllPage={true}
                            />
                        </div>
                        :
                        <embed
                            type="text/html"
                            src={publicPreview.document.data.signed_html_url}
                            className="iframe"
                            onLoad={onLoad}
                        />
                    }
                </div>
                <PublicPreviewAuthScreen
                    data={publicPreview.document.data}
                    ref={publicPreviewAuthScreenRef}
                />
            </section>
    )
}

export default Index
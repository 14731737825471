import { useEffect } from 'react'

//MUI components
import { Tooltip } from '@material-ui/core'

//images
import closeSmallIcon from 'assets/img/app/icons/close-small.png'

//providers
import { useAppContext } from 'providers/App'

//constants
import { CANCEL_DOCUMENT_ACTIVITY_WARNING } from 'constants/Messages'

const Tabs = props => {
    const app = useAppContext()
    const subtabEls = document.querySelectorAll('.active.bordered ul li')
    const { 
        tabs, 
        handleCloseSubtab,
        handleChange, 
        active,
        companyId
    } = props
        
    const { 
        tabIndex, 
        subtabIndex, 
        typeId 
    } = active

    /**
     * 
     * @param {array} subtabs всички подраздели (видими и изтрити) 
     * @returns всички видими подраздели 
     */
    const getVisibleSubtabs = subtabs => {
        const visibleSubtabs    = subtabs.filter(t => {
            return !t.deleted && !isDocumentCreatedByDifferentCompany(t)
        })

        return visibleSubtabs
    }
    
    /**
     * 
     * @param {object} tab подраздел 
     * @returns дали подрадела е създаден от различна фирма от текущо избраната
     */
    const isDocumentCreatedByDifferentCompany = tab => (tab.companyId && (Number(tab.companyId) !== Number(companyId)))

    /**
     * 
     * @param {number} ti (tab index) индекса на раздела
     * @param {boolean} d (deletable) дали подраздела може да бъде изтриван
     * @returns 
     */
    const defineWhenSubtabsAreVisible = (ti, s) => {
        let visible   = false

        if ((tabIndex != ti) && s.deletable || isDocumentCreatedByDifferentCompany(s)) {
            visible   = true
        }

        return visible
    }

    /**
     * 
     * @param {number} i индекс на раздел 
     * @returns дали раздела с този индекс е активен
     */
    const isActiveTab = i => {
        let active    = false

        if (i === tabIndex) {
            active    = true
        }

        return active
    }

    /**
     * 
     * @param {object} tab раздел 
     * @returns данни за раздела
     */
    const getUnactiveTabData = tab => {
        const data = {
            // - 1, защото изключваме раздела по подразбиране
            subtabsCount    : getVisibleSubtabs(tab.subtabs).length - 1
        }

        return data
    }

    /**
     * 
     * @param {object} tab раздел
     * @param {object} subtab подраздел 
     * @returns името на подраздела (съставено от номер / дата) или името на раздела (което ще се ползва в подраздела по подразбиране, напр. Фактури)
     */
    const getSubtabName = (tab, subtab) => {
        const { number, date }  = subtab
        const { name }          = tab

        let subtabName          = name

        if (subtab.number && subtab.date) {
            subtabName          = `${number} / ${date}`
        }

        return subtabName
    }

    /**
     * 
     * @param {number} subtabId ид на подраздела, чрез което да се направи проверка дали това е същия елемент от видимите подраздели 
     * @returns дали това е последния видим подраздел в списъка
     */
    const getIfSubtabIsLast = subtab => {
        let isLast                = false
        const currentTabSubtabs   = tabs[tabIndex].subtabs
        const doc                 = getVisibleSubtabs(currentTabSubtabs)[getVisibleSubtabs(currentTabSubtabs).length - 1]
        
        if ((doc.id === subtab.id) || Number(doc.companyId) !== (companyId) ) {
            isLast                = true
        }
  
        return isLast
    }

    useEffect(() => {
        subtabEls[0]?.click()
    }, [companyId])

    const getDocumentsFromCompany = docs => {
        return docs.filter(d => {
            return (d.companyId === companyId) || !d.companyId
        })
    }
    
    return (
        <ul className="tabs">
            {(tabs || []).map((t, i) =>
                <li
                    key         = {`tab_${i}`}
                    className   = {`${isActiveTab(i) ? 'active' : ''} ${isActiveTab(i) && getVisibleSubtabs(t.subtabs).length > 1 ? 'bordered' : ''}`}
                >
                    <ul className="subtabs">
                        {t.subtabs.map((s, si) =>
                            defineWhenSubtabsAreVisible(i, s) ?
                                <>
                                </>
                                :
                                <Tooltip
                                    title={getSubtabName(t, s)}
                                >
                                    <li
                                        key             = {`subtab_${si}`}
                                        className       = {`${subtabIndex === si ? 'active-subtab' : ''} ${s?.deleted ? 'hidden' : ''} ${!subtabEls[si + 1] ? 'last' : ''}`}
                                        onClick={e => {
                                            if (!e.target.classList.contains('close')) handleChange(i, si, t.typeId)
                                        }}
                                        data-id         = {s.id}
                                        data-index      = {i}
                                        data-type-id    = {t.typeId}
                                    >
                                        <span style={si === 0 ? { display: 'flex' } : {}}>
                                            {getSubtabName(t, s)} 
                                            {!isActiveTab(i) && (t.typeId != 'home') && (getUnactiveTabData(t).subtabsCount > 0) ? 
                                                <i className="count">
                                                    {getUnactiveTabData(t).subtabsCount}
                                                </i>
                                                : 
                                                <></>
                                            }
                                        </span>
                                        {s.deletable ?
                                            <i
                                                onClick={() => {
                                                    if (app.isStartedDocumentActivity(s.id)) {
                                                        if (window.confirm(CANCEL_DOCUMENT_ACTIVITY_WARNING)) {
                                                            handleCloseSubtab(i, si)
                                                            app.setStartedDocumentActivities(false, s.id)
                                                        }
                                                    } else handleCloseSubtab(i, si)
                                                }}
                                                className   = "close"
                                                data-id     = {s.id}
                                            >
                                                <img src={closeSmallIcon} />
                                            </i>
                                            :
                                            <>
                                            </>
                                        }
                                    </li>
                                </Tooltip>
                        )}
                    </ul>

                </li>
            )}
        </ul>
    )
}

export default Tabs
//MUI components
import { Button, Tooltip } from '@material-ui/core'

const NextNumber = ({ handleInsertNextNumber, nextNumberFromServer }) => {
    return (
        <div className="number-suggestion">
            Въведеният номер е вече използван!<br /> Следващият свободен номер е <u>{nextNumberFromServer}</u>.<br /> Искате ли да го използвате?
            <div className="buttons">
                <Tooltip title="Автоматично попълване на следващият свободен номер в полето">
                    <Button
                        className="small save"
                        onClick={() => {
                            handleInsertNextNumber(nextNumberFromServer)
                        }}
                    >
                        Да
                    </Button>
                </Tooltip>
                <Button
                    className="small cancel"
                    onClick={() => {
                        handleInsertNextNumber(false)
                    }}
                >
                    Не
                </Button>
            </div>
        </div>
    )
}

export default NextNumber
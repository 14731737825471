import { useRef, useState } from "react"

//images
import addIcon from "assets/img/documents/icons/document-hover.png"
import sendIcon from "assets/img/documents/icons/send-mail.png"
import zipIcon from "assets/img/documents/icons/zip.png"
import caseIcon from "assets/img/documents/icons/case.png"
import exportIcon from "assets/img/documents/icons/export.png"
import printIcon from "assets/img/documents/icons/print.png"

//libraries
import { Button, Menu, MenuItem } from "@material-ui/core"

//components
import AddEditAccountant from "../modals/AddEditAccountant"
import SendToAccountant from "../modals/SendToAccountant"

function AccountingButton() {
  const addEditAccountantRef = useRef()
  const sendToAccountantRef = useRef()

  const [anchorEl, setAnchorEl] = useState(null)

  function handleClick(e) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function hasAccounting() {
    return true
  }

  return (
    <>
      <AddEditAccountant ref={addEditAccountantRef} />
      <SendToAccountant ref={sendToAccountantRef} />
      <div className="accounting-holder">
        {/* <Button className="normal accounting" onClick={handleClick}>
          Счетоводство
        </Button> */}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
          className="custom-menu-options"
          anchorOrigin={{ vertical: "bottom" }}
        >
          {hasAccounting() ? (
            <>
              <MenuItem
                onClick={() => {
                  addEditAccountantRef.current.open(12)
                }}
              >
                <div className="accounting-list-holder">
                  <img src={caseIcon} alt="" />
                  <div className="right">
                    <span>Моят счетоводител</span>
                    <p>Костадин Костадинов</p>
                  </div>
                </div>
              </MenuItem>
              <hr />
              <MenuItem
                onClick={() => {
                  sendToAccountantRef.current.open()
                }}
              >
                <div className="accounting-list-holder">
                  <img src={sendIcon} alt="" />
                  <p>Изпрати документи до счетоводител</p>
                </div>
              </MenuItem>
              <MenuItem disabled>
                <div className="accounting-list-holder">
                  <img src={exportIcon} alt="" />
                  <p>Експорт на документи за счетоводител</p>
                </div>
              </MenuItem>
              <MenuItem disabled>
                <div className="accounting-list-holder">
                  <img src={zipIcon} alt="" />
                  <p>Изтегляне на документи за счетоводител</p>
                </div>
              </MenuItem>
              <MenuItem disabled>
                <div className="accounting-list-holder">
                  <img src={printIcon} alt="" />
                  <p>Принтиране на документи за счетоводител</p>
                </div>
              </MenuItem>
            </>
          ) : (
            <MenuItem
              onClick={() => {
                addEditAccountantRef.current.open()
              }}
            >
              <div className="accounting-list-holder new-accounting">
                <p>Моят счетоводител</p>
                <b>
                  <img src={addIcon} alt="" />
                  Добави счетоводител
                </b>
              </div>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  )
}

export default AccountingButton

import { Link, useLocation } from 'react-router-dom'

//images
import documentsIcon from 'assets/img/header/icons/documents.png'
import productsIcon from 'assets/img/header/icons/products.png'
import financesIcon from 'assets/img/header/icons/finances.png'
import clientsIcon from 'assets/img/header/icons/clients.png'
import settingsIcon from 'assets/img/header/icons/settings.png'

//partials
import Company from './partials/Company'
import Notifications from './partials/Notifications'
import User from './partials/User'

//MUI components
import { Tooltip } from '@material-ui/core'

//providers
import { useAuthDataContext } from 'providers/Auth'

const menuItems = [
    {
        id        : 1,
        base      : 'invoices',
        path      : '/invoices',
        title     : 'Фактури',
        icon      : documentsIcon,
        module    : 'documents',
    },
    // {
    //     id        : 9,
    //     base      : 'notes',
    //     path      : '/notes',
    //     title     : 'Разписки',
    //     icon      : documentsIcon,
    //     module    : 'documents',
    // },
    // {
    //     id        : 10,
    //     base      : 'offers',
    //     path      : '/offers',
    //     title     : 'Оферта',
    //     icon      : documentsIcon,
    //     module    : 'documents',
    // },
    // {
    //     id        : 2,
    //     base      : 'payorders',
    //     path      : '/payorders',
    //     title     : 'Платежни',
    //     icon      : documentsIcon,
    //     module    : 'documents',
    // },
    // {
    //     id        : 3,
    //     base      : 'cashorders',
    //     path      : '/cashorders',
    //     title     : 'Касови ордери',
    //     icon      : documentsIcon,
    //     module    : 'documents',
    // },
    // {
    //     id        : 4,
    //     base      : 'products',
    //     path      : `/products`,
    //     title     : 'Продукти',
    //     icon      : productsIcon,
    // },
    {
        id        : 'finances',
        base      : 'finances',
        path      : '/finances/incomes',
        title     : 'Финанси',
        icon      : financesIcon,
    },
    {
        id        : 'accounts',
        base      : 'accounts',
        path      : '/accounts',
        title     : 'Справки',
        icon      : financesIcon,
    },
    {
        id        : 7,
        base      : 'customers',
        path      : '/customers',
        title     : 'Клиенти',
        icon      : clientsIcon
    },
    {
        id        : 8,
        base      : 'settings',
        path      : '/settings',
        title     : 'Настройки',
        icon      : settingsIcon
    },
]

const Header = () => {
    const { pathname, search }  = useLocation()
    const auth                  = useAuthDataContext()

    /**
     * 
     * @param {array} menuItems всички модули
     * @returns достъпните за потребителя модули
     */
    const getModules = menuItems => {
        let modules             = []
        modules = menuItems.filter(item => {
            if (item.module) {
                if (auth.getUser()?.module(item.module)) {
                    return true
                }

                return false
            }

            return true
        })

        return modules
    }

    /**
     * 
     * @param {object} item модул от менюто 
     * @returns дали модула е активен
     */
    const getActiveModule = item => {
        let active            = false
        const { path, base }  = item

        if (path.includes(pathname + search) || `${pathname}${search}`.includes(base)) {
            active            = true
        }

        return active
    }

    return (
        <header>
            <div className="wrapper">
                <Company />
                <ul className="menu">
                    {getModules(menuItems).map(item =>
                        <li key={item.id}>
                            <Tooltip
                                title={`Продължете към модул ${item.title.toLowerCase()}`}
                            >
                                <Link
                                    to          = {item.path}
                                    className   = {getActiveModule(item) ? 'active' : ''}
                                >
                                    <img src={item.icon} />
                                    {item.title}
                                </Link>
                            </Tooltip>
                        </li>
                    )}

                </ul>
                <div className="more">
                    <Notifications />
                    <User />
                </div>
            </div>
        </header>
    )
}

export default Header
import { Link } from "react-router-dom"

//providers
import { useRegisterContext } from "../RegisterProvider"

//miscs
import RedditTextField from "components/misc/RedditTextField"

//MUI components
import { Checkbox, FormControlLabel } from '@material-ui/core'

//hooks
import useAuthDataHook from "../useAuthDataHook"

//images
import eyeIcon from 'assets/img/login/icons/eye.png'

//partials
import PasswordStrengthIndicator from "./PasswordStrengthIndicator"

const AuthData = () => {
    const register = useRegisterContext()
    const { companyData, authData } = register

    const {
        handleChange,
        handleSubmit,
        authDataState,
    } = useAuthDataHook()

    return (
        <li className="auth-data">
            <form onSubmit={handleSubmit} id="register-form">
                <input type="hidden" name="name" value={companyData.data.name} />
                <input type="hidden" name="eik" value={companyData.eik} />
                <input type="hidden" name="mol" value={companyData.data.mol} />
                <input type="hidden" name="vat" value={companyData.data.vatNumber} />
                <input type="hidden" name="address" value={companyData.data.address} />
                <input type="hidden" name={`settlement[name]`} value={companyData.data.settlement} />
                <input type="hidden" name={`settlement[id]`} value={companyData.data.settlementId} />
                <input type="hidden" name="settlement[municipality_id]" value={companyData.data?.municipalityId} />
                <div className="row">
                    <div className="col">
                        <RedditTextField
                            size="small"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="email"
                            value={authData.data.email || ''}
                            label="Е-поща"
                            required
                            onChange={handleChange}
                            error={Boolean(register.validationData && register.validationData?.email)}
                            helperText={register.validationData && register.validationData?.email}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col pw-col">
                        <RedditTextField
                            size="small"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="password"
                            value={authData.data.password || ''}
                            label="Парола"
                            type={authDataState.viewPw ? 'text' : 'password'}
                            required
                            onChange={handleChange}
                            error={Boolean(register.validationData && register.validationData?.password)}
                            helperText={register.validationData && register.validationData?.password}
                        />
                        <span
                            className={`view-pw ${authDataState.viewPw
                                ? 'on' : ''}`}
                        >
                            <img src={eyeIcon} />
                        </span>
                        <PasswordStrengthIndicator
                            password={authData.data.password}
                        />
                    </div>
                </div>
                <div
                    className={`row checkbox-holder ${Boolean(register.validationData && register.validationData?.terms) ? 'validate' : ''}`}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{
                                    padding: '0',
                                    marginRight: '5px',
                                }}
                                name="terms"
                                value="1"
                                checked={authData.data.terms || false}
                                onChange={handleChange}
                            />
                        }
                        label={
                            <>
                                Със създаването на профил се съгласявам с <a href="/terms" target="_blank">Условията за ползване</a> на Docuela
                            </>
                        }
                    />
                </div>
            </form>
        </li>
    )
}

export default AuthData
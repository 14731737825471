import { useState, useImperativeHandle, forwardRef } from "react"

//MUI components
import { Button } from "@material-ui/core"

//misc
import SaveButton from "components/misc/Button"

//images
import closeIcon from "assets/img/modals/icons/close.png"

//style
import "assets/scss/send.scss"

function SendToAccountant(props, ref) {
  const [fieldValues, setFieldsValues] = useState({})
  const [state, setState] = useState({
    overlay: false,
    modal: false,
    id: null,
  })

  useImperativeHandle(ref, () => ({
    open: (id) => {
      show(id)
    },
    close: () => {
      hide()
    },
    onSuccess: (fn) => {
      setState((prev) => ({
        ...prev,
        onSuccess: fn,
      }))
    },
    onError: (fn) => {
      setState((prev) => ({
        ...prev,
        onError: fn,
      }))
    },
    onClose: (fn) => {
      setState((prev) => ({
        ...prev,
        onClose: fn,
      }))
    },
  }))

  function show(id = null) {
    setState((prev) => ({
      ...prev,
      overlay: true,
      id,
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        modal: true,
      }))
    }, 50)
  }

  function hide() {
    setState((prev) => ({
      ...prev,
      modal: false,
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        overlay: false,
        id: null,
      }))
    }, 100)
  }

  function handleSave(e) {
    e.preventDefault()
  }

  function handleChange(e) {
    const { name, value } = e.target

    setFieldsValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <div className={`${state.overlay ? "visible" : ""} overlay`}>
      {state.overlay ? (
        <form
          className={`${
            state.modal ? "show" : ""
          } add-accountant-modal popup-primary small`}
          noValidate
          onSubmit={handleSave}
        >
          <div className="body">
            <h3 className="head">
              Изпращане до счетоводството
              <img
                className="close-icon"
                alt="close"
                src={closeIcon}
                onClick={hide}
              />
            </h3>
            <p>
              Lorem Ipsum е елементарен примерен текст, използван в
              типографската индустрия
            </p>
          </div>
          <div className="footer">
            <Button onClick={hide} className="cancel">
              {props.cancelBtnText || "Отказ"}
            </Button>
            <SaveButton
              loading={state.loading}
              text={state.id ? "Запазване" : "Добавяне"}
              className="save"
            />
          </div>
        </form>
      ) : (
        <></>
      )}
    </div>
  )
}

export default forwardRef(SendToAccountant)

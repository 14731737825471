import { forwardRef, useImperativeHandle } from 'react'
import closeIcon from 'assets/img/modals/icons/close.png'
import { Button } from '@material-ui/core'
import SaveButton from 'components/misc/Button'
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import RedditTextField from 'components/misc/RedditTextField'

const initialState = {
    overlay: false,
    modal: false,
    onSuccess: null,
    data: {},
    parentId: null
}
function AddOrEdit(props, ref) {
    const { companyId } = props
    const [state, setState] = useNestedState({
        ...initialState
    })

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        add: parentId => {
            console.log(parentId)
            setState(prev => ({
                ...prev,
                parentId
            }))

            show();
        },
        edit: data => {
            edit(data)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                ...initialState,
                overlay: false,
            }))
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const edit = data => {
        setState(prev => ({
            ...prev,
            data,
        }))

        show()
    }
    console.log(state.data)
    const handleSave = e => {
        e.preventDefault()

        setLoading(true)

        setValidations(null)

        const url = state.data?.id ? 'expenses/categories/edit' : 'expenses/categories/add';
        const data = new FormData(e.target)

        if (state.data?.id) data.append('id', state.data?.id)

        if (state.parentId) data.append('parent_id', state.parentId)

        data.append('company_id', companyId)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                hide()
            })
            .catch(error => {
                const _err = error.response;
                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading,
        }))
    }
    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary small`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Категория
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">

                    <div className="row">
                        <div
                            className="col"
                            style={{
                                width: 'fit-content',
                                flex: '0'
                            }}
                        >
                            <RedditTextField
                                size="small"
                                variant="filled"
                                margin="dense"
                                label="Цвят"
                                fullWidth
                                name="color"
                                type="color"
                                value={state.data?.color || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.color)}
                                helperText={validations && validations.color && (validations.color[0] || validations.color)}
                                style={{
                                    width: '80px'
                                }}
                                required
                            />
                        </div>

                        <div className="col">
                            <RedditTextField
                                size="small"
                                variant="filled"
                                margin="dense"
                                fullWidth
                                label="Наименование"
                                name="name"
                                value={state.data?.name || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.name)}
                                helperText={validations && validations.name && (validations.name[0] || validations.name)}
                                autoFocus
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="Данък (%)"
                            name="vat"
                            value={state.data?.vat || ''}
                            onChange={handleInputChange}
                            error={Boolean(validations && validations.vat)}
                            helperText={validations && validations.vat && (validations.vat[0] || validations.vat)}
                            type="number"
                        />
                    </div>
                </div>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);
import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
// import { trans } from '../../../_providers/Translation';
import Button from '@material-ui/core/Button';
import SaveButton from '../../../../misc/Button';
import TextField from '@material-ui/core/TextField';

import { useValidation } from 'helpers/Validation'

//helpers
import Api from 'helpers/Api'

//misc
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useAuthDataContext } from 'providers/Auth';
import RedditSelect from 'components/misc/RedditSelect';

import uploadIcon from 'assets/img/app/icons/upload.png'
import closeIcon from 'assets/img/modals/icons/close.png'
import deleteIcon from 'assets/img/app/icons/delete.png'
import { useHistory } from 'react-router-dom';

function Import(props, ref) {

    const auth = useAuthDataContext();
    const history = useHistory();

    const [state, setState] = useState({
        data: null,
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onError: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen();
        },
        close: () => {
            handleHide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50);
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);

        resetData();
        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: null,
        }))
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'documents/import';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                if (res.data.import) {
                    history.push('/imports/' + res.data.import.id);
                }

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }

                handleHide();

            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    if (typeof state.onError === 'function') {
                        state.onError(error)
                    }
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleAttach = e => {
        let file = e.target.files[0];

        if (!file) {
            return;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: file
            }
        }));
    }

    const handleDetach = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: null
            }
        }));
    }


    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary small`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Вмъкване на данни
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    <div className="row">
                        <div className="col">
                            <RedditSelect
                                label="От програма"
                                name="type"
                                value={state.data?.type || ''}
                                error={Boolean(validations && validations.type)}
                                helperText={validations && validations.type && (validations.type[0] || validations.type)}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="auto">
                                    Автоматично разпознаване
                                </MenuItem>
                                <MenuItem value="microinvest_delta_pro">
                                    Microinvest Delta Pro
                                </MenuItem>
                                <MenuItem value="ajur_l">
                                    АжурL
                                </MenuItem>
                            </RedditSelect>
                        </div>
                    </div>

                    {state.data?.file &&
                        <ul
                            className="uploaded-files"
                        >

                            <li
                                className="row"
                            >

                                {state.data.file.name}

                                <div
                                    onClick={() => {
                                        handleDetach()
                                    }}
                                >
                                    <img src={deleteIcon} />
                                </div>
                            </li>

                        </ul>
                    }

                    <div className="row">
                        <div className="col">
                            <div className="upload">
                                <img src={uploadIcon} />
                                <h6>
                                    Изберете файл
                                </h6>
                                <input type="file" name="file" onChange={handleAttach} accept=".zip,.txt" title="" />
                                <p>
                                    Допустими формати: .zip, .txt
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                        text="Запази"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Import);
import { useLocation } from "react-router-dom";

export const segment = index => {
    const location = window && window.location;

    if (location) {
        let path = location.pathname;
        let array = path.replace('/', '').split('/');
        let segment = array[index] || '';

        return segment;
    }

    return null;
}

// old
// export const useQuery = (query = null) => {
//     if (query === null) {
//         query = window && window.location.search;
//     }

//     return new URLSearchParams(query);
// }

export const useQuery = (query = null) => {
    const location = useLocation();

    if (query === null) {
        query = location.search;
    }

    return new URLSearchParams(query);
};
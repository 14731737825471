import { TextField as MuiTextField } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

function TextField(props) {
    return <MuiTextField
        InputProps={{
            endAdornment: (
                props.helperText &&
                <Tooltip title={props.helperText} placement="top" arrow>
                    <span className="error-info">

                    </span>
                </Tooltip>
            )
        }}
        {...props}
        helperText={null}
        onWheel={e => {
            if (props.type === 'number') e.target.blur()
        }}
    />
}

export default TextField
import { forwardRef, useImperativeHandle, useRef } from 'react'

//MUI components
import { Button } from '@material-ui/core'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//images
import closeIcon from 'assets/img/app/icons/close.png'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

function AddOrEdit({ refresh }, ref) {
    const auth = useAuthDataContext()
    const app = useAppContext()

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        data: null,
        onSuccess: null
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        add: () => {
            show()
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                data: null,
                overlay: false,
                modal: false
            }))
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)

        setValidations(null)

        const url = 'companies/add'
        const data = new FormData(e.target)

        Api.post(url, data)
            .then(res => {
                app.handleSuccess('Фирмата беше добавена успешно!')
                auth.getUser().addCompany(res.data)

                hide()
                refresh()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                    <h2 className="head">
                        Добавяне на фирма
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </h2>

                    <div className="body">

                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Наименование"
                                    name="info[name]"
                                    value={state.data?.info?.name || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info?.name)}
                                    helperText={validations && validations.info?.name && (validations.info?.name[0] || validations.info?.name)}
                                    autoFocus
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="ЕИК"
                                    name="info[eikegn]"
                                    value={state.data?.info?.eikegn || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info?.eikegn)}
                                    helperText={validations && validations.info?.eikegn && (validations.info?.eikegn[0] || validations.info?.eikegn)}
                                    required
                                />
                            </div>

                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="ИН по ЗДДС"
                                    name="info[vat_number]"
                                    value={state.data?.info?.vat_number || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info?.vat_number)}
                                    helperText={validations && validations.info?.vat_number && (validations.info?.vat_number[0] || validations.info?.vat_number)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Телефон"
                                    name="info[phone]"
                                    value={state.data?.info?.phone || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info?.phone)}
                                    helperText={validations && validations.info?.phone && (validations.info?.phone[0] || validations.info?.phone)}
                                />
                            </div>

                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Е-поща"
                                    name="info[email]"
                                    value={state.data?.info?.email || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info?.email)}
                                    helperText={validations && validations.info?.email && (validations.info?.email[0] || validations.info?.email)}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            className="save"
                            loading={state.loading}
                        />
                    </div>
                </form >
            </div >
        </>
    )
}

export default forwardRef(AddOrEdit)
//images
import mailIcon from 'assets/img/app/icons/send-colored.png'
import exportIcon from 'assets/img/app/icons/export-colored.png'
import downloadIcon from 'assets/img/app/icons/download-colored.png'
import downloadPdfIcon from 'assets/img/app/icons/download-pdf.png'
import printIcon from 'assets/img/app/icons/print-colored.png'

//MUI components
import { Button, Tooltip } from '@material-ui/core'

function MultipleActions(props) {
    const {
        selectedCount,
        handleSendSelected,
        handleDownloadSelected,
        handleExportSelected,
        handlePrinttSelected,
        printLoading
    }             = props
    const count   = Number(selectedCount)

    return (
        <div
            className="multiple-actions"
        >
            Избрани елементи: {count}
            <ul>
                <li>
                    <Tooltip
                        title={`Започване на процедура по изпращане на ${count} ${count === 1 ? 'документ' : 'документа'} към клиент`}
                    >
                        <Button
                            onClick={() => {
                                handleSendSelected()
                            }}
                        >
                            <img src={mailIcon} />
                            Изпращане
                        </Button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip
                        title={`Изтегляне на ${count} ${count === 1 ? 'документ като .pdf' : 'документа като .zip архив'}`}
                    >
                        <Button
                            onClick={() => {
                                handleDownloadSelected(count)
                            }}
                        >
                            <img
                                src={count === 1 ? downloadPdfIcon : downloadIcon}
                            />
                            Изтегляне
                        </Button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip
                        title={`Започване на процедура по експортиране на ${count} ${count === 1 ? 'документ' : 'документа'}`}
                    >
                        <Button
                            onClick={() => {
                                handleExportSelected()
                            }}
                        >
                            <img
                                src={exportIcon}
                                style={{
                                    marginRight: '-5px'
                                }}
                            />
                            Експорт
                        </Button>
                    </Tooltip>
                </li>
                <li
                    className={printLoading ? 'disabled' : ''}
                >
                    <Tooltip
                        title={`Принтиране на ${count} ${count === 1 ? 'документ' : 'документа'}`}
                    >
                        <Button
                            onClick={() => {
                                handlePrinttSelected()
                            }}
                        >
                            <img
                                src={printIcon}
                                style={{
                                    marginRight: '-5px'
                                }}
                            />
                            Принтиране
                        </Button>
                    </Tooltip>
                </li>
            </ul>
        </div>
    )
}

export default MultipleActions
import { useState, useRef, useEffect } from 'react'
import printJS from 'print-js'

//MUI components
import { Menu, Tooltip, Button } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'

//providers
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'
import { useLanguageContext } from 'providers/Language'
import { useDocumentContext } from '../Provider'

//modals
import Question from 'components/modals/Question'

//partials
import MakePaid from './payment/MakePaid'
import Send from 'components/partials/documents/Send'
import Options from './Options'
import RepeatOptions from './RepeatOptions'
import AddPayment from './payment/AddPayment'
import PreviewTest from './PreviewTest'
import MenuIndicator from 'components/misc/MenuIndicator'

//images
import loader from 'assets/img/loader.svg'
import uploadIcon from 'assets/img/app/icons/upload.png'
import uploadLogoIcon from 'assets/img/documents/icons/upload.png'
import deleteLogoIcon from 'assets/img/documents/icons/delete.png'
import Export from './export/Export'


function Preview(props, ref) {
    const langs = useLanguageContext()
    const auth = useAuthDataContext()
    const app = useAppContext()
    const context = useDocumentContext()
    const { refreshPreview } = context?.state || {}

    const { data, handleAddNewSubtab, handleChangeTabs, htmlUrl, updatedAt, appendData, hasWritePermission, handleForceEditMode } = props

    const [state, setState] = useState({
        data,
        htmlUrl,
        download: false,
        loading: false,
        refresh: null,
        showMenu: null,
        successCopied: false,
        refreshDoc: null
    })

    //refs
    const deleteModalRef = useRef(null)
    const makePaidModalRef = useRef(null)
    const makeUnpaidModalRef = useRef(null)
    const successSnackbarRef = useRef(null)
    const errorSnackbarRef = useRef(null)
    const addPaymentModalRef = useRef(null)
    const sendModalRef = useRef(null)
    const repeatOptionsModalRef = useRef(null)
    const deleteLogoModalRef = useRef(null)
    const exportModalRef = useRef(null)
    //end refs

    useEffect(() => {
        handleRefreshDoc()
    }, [refreshPreview])

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @returns езикът по подразбиране
     */
    const getDefaultLang = () => langs.data.find(l => l.default === 1)

    /**
     * 
     * @param {number} langId id на езика 
     */
    const download = langId => {
        window.open(`${data.pdf_url}&download=1&options[copy]=1&lang_id=${langId}&token=${auth.getToken()}`)
    }


    const handleDelete = () => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy()
        })
    }

    const handleDestroy = () => {
        Api.post('documents/delete', {
            id: data.id
        })
            .then(res => {
                if (res.data.success) {
                    app.handleSuccess('Документа беше изтрит успешно!')
                    document.querySelector(`.active-subtab i.close`).click()
                }
            })
    }

    const handleMakePaid = () => {
        const modal = makePaidModalRef.current

        modal.open(data.id)
        modal.onSuccess(() => {
            refresh()
            success()
        })
    }

    const handleMakeUnpaid = () => {
        const modal = makeUnpaidModalRef.current

        modal.open()
        modal.onSuccess(() => {
            makeUnpaid()
        })
    }

    const makeUnpaid = () => {
        Api.post('documents/make-unpaid', {
            id: data.id
        })
            .then(res => {
                if (res.data.success) {
                    refresh()
                    success()
                }
            })
    }

    const handleAddPayment = () => {
        const modal = addPaymentModalRef.current

        modal.open(data.id)
        modal.onSuccess(() => {
            refresh()
            success()
        })
    }

    const handleSend = () => {
        const email = data?.customerinfo?.email
        const modal = sendModalRef.current

        modal.open(data.id, email)
        modal.onSuccess(() => {
            success()
        })
        modal.onError(() => {
            error()
        })
    }

    const handleConvertToProforma = () => {
        Api.post('documents/convert-to-proforma', {
            id: data.id
        })
            .then(() => {
                refresh(true)
            })
    }

    const handleConvertToInvoice = () => {
        Api.post('documents/convert-to-invoice', {
            id: data.id
        })
            .then((res) => {
                refresh(true)

                handleChangeTabs(2, 0, 3)
                handleAddNewSubtab(1, res.data, false, 2, 0, 3)
            })
    }

    const handleConvertToCreditNote = () => {
        Api.post('documents/convert-to-creditnote', {
            id: data.id
        })
            .then(() => {
                refresh(true)
            })
    }

    const handleConvertToDebitNote = () => {
        Api.post('documents/convert-to-debitnote', {
            id: data.id
        })
            .then(() => {
                refresh(true)
            })
    }

    /**
     * 
     * @param {object} data данните на документа
     */
    const handleShowRepeatOptions = data => {
        const modal = repeatOptionsModalRef.current

        modal.open(data)

        modal.onSuccess(() => {
            refresh()
            success()
        })

        modal.onError(() => {
            error()
        })
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date()
        }))
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }

    /**
     * 
     * @param {node|null} showMenu референця към менюто чрез e.target | менюто да е скрито 
     */
    const handleShowMenu = showMenu => {
        setState(prev => ({
            ...prev,
            showMenu
        }))
    }

    const handleSuccessCopied = () => {
        setState(prev => ({
            ...prev,
            successCopied: true
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                successCopied: false
            }))
        }, 2000)
    }

    /**
     * @param {string} pdfUrl връзка към .pdf файла
     */
    const handlePrint = pdfUrl => {
        printJS({
            printable: pdfUrl,
            onLoadingStart: () => {
                console.log('start')
            },
            onLoadingEnd: () => {
                console.log('end')
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    const handleChangeLogo = e => {
        if (!e.target?.files?.length) return

        const d = new FormData(document.querySelector(`.upload-${data.id}`))
        setLoading(true)
        Api.post('documents/update-preview', d)
            .then(() => {
                handleRefreshDoc()
                setHasLogo(true)
                app.handleSuccess(data.has_logo ? 'Вашето лого беше променено успешно!' : 'Вашето лого беше добавено успешно!')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleRefreshDoc = () => {
        setState(prev => ({
            ...prev,
            refreshDoc: new Date().getTime()
        }))
    }

    const handleDeleteLogo = () => {
        const modal = deleteLogoModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroyLogo()
        })
    }

    const handleDestroyLogo = () => {
        setLoading(true)
        Api.post('documents/delete-logo', {
            id: data.id
        })
            .then(() => {
                handleRefreshDoc()
                app.handleSuccess('Вашето лого беше изтрито успешно!')
                setHasLogo(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setHasLogo = hasLogo => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                has_logo: hasLogo
            }
        }))
    }

    const handleExport = id => {
        const modal = exportModalRef.current

        modal.open(id)
        modal.onSuccess(() => {
            success('Експортирането завърши успешно!')
        })
        modal.onError(() => {
            error()
        })
    }

    return (
        <section id="documents" className={`preview ${state.loading ? 'disabled' : ''}`}>
            <Question
                ref={deleteLogoModalRef}
                mainMessage="Изтриване на Вашето лого?"
                secondaryMessage="Логот ще бъде премахнето за цялата фирма."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <MakePaid
                ref={makePaidModalRef}
            />
            <RepeatOptions
                ref={repeatOptionsModalRef}
            />
            <Send
                ref={sendModalRef}
                documentsType="documents"
            />
            <AddPayment
                ref={addPaymentModalRef}
            />
            <Question
                ref={makeUnpaidModalRef}
                mainMessage="Сигурни ли сте?"
            />
            <Export
                ref={exportModalRef}
                typeId={data.type_id}
            />
            <div className="row document-options">
                <div className="buttons">
                    {/* {data.is_payable && data.is_paid &&
                        <button
                            className="make-paid"
                        // onClick={handleMakeUnpaid}
                        >
                            Неплатена
                        </button>
                    } */}
                    {data.allow_edit ?
                        <button
                            className="normal edit"
                            onClick={() => {
                                // handleAddNewSubtab(2, data)
                                handleForceEditMode(data)
                            }}
                        >
                            Редакция
                        </button>
                        :
                        <>
                        </>
                    }
                    <button
                        className="normal download"
                        onClick={() => {
                            download(getDefaultLang().id)
                        }}
                    >
                        Изтегляне
                    </button>

                    <button
                        className={`normal print`}
                        onClick={() => {
                            handlePrint(data.print_url)
                        }}
                    >
                        Принтиране
                    </button>

                    <button
                        className="normal send"
                        onClick={() => {
                            handleSend(data.id)
                        }}
                    >
                        Изпращане
                    </button>

                    <Tooltip
                        title="Връзката беше копирана."
                        open={state.successCopied}
                    >
                        <button
                            className="small"
                            onClick={e => {
                                handleShowMenu(e.target)
                            }}
                        >
                            <MenuIndicator
                                active={Boolean(state.showMenu)}
                                style={{
                                    margin: '0 auto',
                                }}
                            />
                        </button>
                    </Tooltip>
                    <Menu
                        anchorEl={state.showMenu}
                        keepMounted
                        open={Boolean(state.showMenu)}
                        onClose={() => {
                            handleShowMenu(null)
                        }}
                        onClick={() => {
                            handleShowMenu(null)
                        }}
                        anchorOrigin={{ vertical: 'bottom' }}
                        className="custom-menu-options"
                    >
                        <Options
                            data={data}
                            handleDelete={handleDelete}
                            handleMakePaid={handleMakePaid}
                            handleMakeUnpaid={handleMakeUnpaid}
                            handleAddPayment={handleAddPayment}
                            handleSend={handleSend}
                            handleConvertToProforma={handleConvertToProforma}
                            handleConvertToInvoice={handleConvertToInvoice}
                            handleConvertToCreditNote={handleConvertToCreditNote}
                            handleConvertToDebitNote={handleConvertToDebitNote}
                            handleShowRepeatOptions={handleShowRepeatOptions}
                            fromPreview={true}
                            handleSuccessCopied={handleSuccessCopied}
                            handleAddNewSubtab={handleAddNewSubtab}
                            handlePrint={handlePrint}
                            handleDownload={() => {
                                download(getDefaultLang().id)
                            }}
                            handleExport={handleExport}
                        />
                    </Menu>
                    {data.allow_delete &&
                        <button
                            className="remove small"
                            onClick={handleDelete}
                        >

                        </button>
                    }

                </div>
            </div>
            <div className="doc">
                {/* <PreviewTest
                    logoUrl={data?.company?.logo || '-'}
                    qrCodeUrl={data?.qrcode_url || '-'}
                    client={data.customerinfo?.name || '-'}
                    clientTown={data.customeraddress?.settlement?.name || '-'}
                    clientAddress={data.customeraddress?.address || '-'}
                    clientEikEgn={data.customerinfo?.eikegn || '-'}
                    clientVatNumber={data.customerinfo?.vat_number || '-'}
                    clientMol={data.customerinfo?.mol || '-'}
                    company={data.company?.info?.name || '-'}
                    companyTown={data.company?.address?.settlement?.name || '-'}
                    companyAddress={data.company?.address?.address || '-'}
                    companyEikEgn={data.company?.info?.eikegn || '-'}
                    companyVatNumber={data.company?.info?.vat_number || '-'}
                    companyMol={data.company?.info?.mol || '-'}
                    companyIban={data.bank?.iban || '-'}
                    companyBank={data.bank?.name || '-'}
                    companyBic={data.bank?.bic || '-'}
                    documentName={data?.type?.translation?.name}
                    number={data.no || ''}
                    date={data.date_dmy || ''}
                /> */}
                <div className="frame-wrapper">
                    <embed key={state.refreshDoc} type="text/html" src={`${htmlUrl}&updated_at=${updatedAt}&token=${auth.getToken()}`} className="doc-iframe" />
                    {state.data.has_logo ?
                        <form className={`upload-logo upload-${data.id}`} style={!hasWritePermission ? { pointerEvents: 'none' } : {}}>
                            <input type="hidden" value={data.id} name="id" />
                            <div className="options">
                                <Tooltip title="Прикачване на лого">
                                    <Button
                                        className="btn-upload"
                                        onClick={() => {
                                            document.getElementById('logo-field').click()
                                        }}
                                    >
                                        <img src={uploadLogoIcon} />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Изтриване на лого">
                                    <Button
                                        className="btn-delete"
                                        onClick={handleDeleteLogo}
                                    >
                                        <img src={deleteLogoIcon} />
                                    </Button>
                                </Tooltip>
                            </div>
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                name="data[documents_logo]"
                                onChange={handleChangeLogo}
                                id="logo-field"
                            />
                        </form>
                        :
                        <form className={`upload upload-${data.id}`} style={!hasWritePermission ? { pointerEvents: 'none' } : {}}>
                            <input type="hidden" value={data.id} name="id" />
                            <img src={uploadIcon} />
                            <div className="right">
                                <h6>
                                    Прикачване на лого
                                </h6>

                                <p>
                                    Препоръчителен размер: 80x190 px.
                                    <br />
                                    Допустими формати: .jpg, .jpeg, png, gif
                                </p>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                name="data[documents_logo]"
                                onChange={handleChangeLogo}
                            />
                        </form>
                    }
                </div>
            </div>
        </section>
    )
}

export default Preview
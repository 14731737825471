import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import AppProvider from './providers/App';
import AuthDataProvider from './providers/Auth';
import LanguageProvider from './providers/Language';
import CurrencyProvider from './providers/Currency';
import TranslationProvider from 'providers/Translation';
import DocumentProvider from 'components/pages/documents/Provider'
import PayOrderProvider from 'components/pages/payorders/Provider'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const history = require("history").createLocation;

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#388ad0'
        }
    }
});

ReactDOM.render(
    <Router history={history}>
        <AuthDataProvider>
            <LanguageProvider>
                <CurrencyProvider>
                    <TranslationProvider>
                        <DocumentProvider>
                            <PayOrderProvider>
                                <AppProvider>
                                    <MuiThemeProvider theme={theme}>
                                        <App />
                                    </MuiThemeProvider>
                                </AppProvider>
                            </PayOrderProvider>
                        </DocumentProvider>
                    </TranslationProvider>
                </CurrencyProvider>
            </LanguageProvider>
        </AuthDataProvider>
    </Router>,
    document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { useEffect, useState, useRef } from 'react'

//MUI components
import { Tooltip, Button, Popover, ClickAwayListener, MenuItem } from '@material-ui/core'

//images
import checkIcon from 'assets/img/app/icons/check-white.png'
import checkGreenIcon from 'assets/img/app/icons/check.png'
import crossIcon from 'assets/img/app/icons/close.png'
import deleteIcon from 'assets/img/app/icons/delete.png'
import replayIcon from 'assets/img/app/icons/replay.png'
import bankPaymentIcon from 'assets/img/app/icons/payment-bank.png'
import postPaymentIcon from 'assets/img/app/icons/payment-post.png'
import cashPaymentIcon from 'assets/img/app/icons/payment-cash.png'
import addExtendedIcon from 'assets/img/app/icons/add-extended.png'
import partlyIcon from 'assets/img/app/icons/partly-payment.png'

//Misc
import DatePicker from 'components/misc/DatePicker'

//plugins
import moment from 'moment'

//providers
import { useAppContext } from 'providers/App'

//modals
import Question from 'components/modals/Question'

//helpers
import Api from 'helpers/Api'

//constants
import { PAYMENT_EXTRA_DATA } from 'constants/PaymentMethod'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

//components
import PaymentPie from './PaymentPie'

const Config = props => {
    const app = useAppContext()
    const today = moment().format('YYYY-MM-DD')
    const {
        doc,
        handleMakeUnpaid,
        refresh,
        appendData
    } = props
    const deleteModalRef = useRef(null)

    const getUnpaidPrice = () => doc?.unpaid_price || "0.00"
    const getPaidPrice = () => doc?.paid_price || "0.00"
    const getPaitmendMethodId = () => doc?.payment_method_id || null

    const initialState = {
        configPaymentPopover: null,
        date: today,
        price: getUnpaidPrice(),
        priceToPay: getUnpaidPrice(),
        paidPrice: getPaidPrice(),
        method: getPaitmendMethodId(),
        editPrice: false,
        description: false,
    }
    const [state, setState] = useState({
        ...initialState,
        methods: [],
        loading: false,
    })

    useEffect(() => {
        let methods = app.state.paymentMethods

        if (state.configPaymentPopover && !state.methods.length) {
            //само физически методи за плащане
            methods = methods.filter(m => m.physical === 1)

            //добавяне на икони
            methods = methods.map(m => {
                m.icon = PAYMENT_EXTRA_DATA[m.name].icon

                return m
            })

            sortAndStorePaymentMethods(methods)
        } else sortAndStorePaymentMethods(state.methods)
    }, [state.configPaymentPopover])

    const resetData = () => {
        setState(prev => ({
            ...prev,
            ...initialState
        }))
    }

    /**
     * 
     * @param {array} methods методи на плащане 
     */
    const sortAndStorePaymentMethods = methods => {
        //на 1во място поставя избрания в документа метод на плащане
        methods.sort((a, b) => {
            if (a.id == state.method) {
                return -1
            } else {
                return 0
            }
        })

        setState(prev => ({
            ...prev,
            methods
        }))
    }

    /**
     * 
     * @param {node|null} el референция към елемента, към който искаме да закачим popover | скриване на popover
     */
    const setPopoverElement = el => {
        resetData()
        setState(prev => ({
            ...prev,
            configPaymentPopover: el,
        }))
    }

    /**
     * 
     * @param {string} date датата в YYYY.MM.DD формат 
     * @returns датата в DD.MM.YYYY формат 
     */
    const formatDate = date => {
        if (!date) return '-'

        date = date.split('-')

        date = `${date[2]}.${date[1]}.${date[0]}`

        return date
    }

    /**
     * 
     * @param {number} method id на активния метод за плащане 
     */
    const handleActiveMethod = e => {
        setState(prev => ({
            ...prev,
            method: e.target.value
        }))

        // handlePay(method)
    }

    const handleClickAwayFromPrice = () => {
        handlePrieInputVisibility(false)
    }

    /**
     * 
     * @param {boolean} editPrice дали да се покаже input вместо цена с опция за редакция
     */
    const handlePrieInputVisibility = editPrice => {
        setState(prev => ({
            ...prev,
            price: validatePrice(),
            editPrice
        }))
    }

    /**
     * 
     * @returns валидирана цена
     */
    const validatePrice = () => {
        let price = state.price

        if (Number(price) > Number(state.priceToPay)) {
            price = state.priceToPay
        } else if (Number(price) < 0) {
            price = '0.00'
        }

        return price
    }

    /**
     * 
     * @param {string} price цената, която се подава от полето за цена при редакция 
     */
    const handleChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    /**
     * 
     * @param {string} date дата
     */
    const handleDateChange = date => {
        setState(prev => ({
            ...prev,
            date
        }))
    }

    const handleRemovePayment = id => {
        setPopoverElement(null)
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroyPayment(id)
        })
    }

    const handleDestroyPayment = id => {
        seLoading(true)
        Api.post('documents/delete-payment', {
            id
        })
            .then(() => {
                refresh()
                app.handleSuccess(`Плащането беше премахнато успешно!`)
            })
            .finally(() => {
                seLoading(false)
            })
    }

    /**
     * 
     * @returns съдържанието на popover
     */
    const popoverContent = () => {
        let content = <></>

        if (doc.is_paid) {
            content = <div className="paid">
                <span className="sign">
                    Платена
                </span>
                <h3>
                    Премахване на плащане
                </h3>
                <ul>
                    {doc.payments.map(p =>
                        <li key={p.id}>
                            {moment(p.date).format('DD.MM.YYYY')} г. / {p.price.toFixed(2)} лв.
                            <img
                                src={deleteIcon}
                                onClick={() => {
                                    handleRemovePayment(p.id)
                                }}
                            />
                        </li>
                    )}
                </ul>
                <Button
                    onClick={() => {
                        setPopoverElement(null)
                        handleMakeUnpaid(doc.id)
                    }}
                >
                    <img src={replayIcon} />
                    Върни за плащане
                </Button>
            </div>
        } else {
            content = <div className="not-paid">
                <span className="sign">
                    Неплатена
                </span>
                <h3>
                    Отбелязване като платена
                </h3>
                <div className={`row ${state.loading ? 'disabled' : ''}`}>
                    <div className="col">
                        <span>
                            Дата:
                        </span>
                        <p>
                            {formatDate(state.date)} г.
                        </p>
                        <DatePicker
                            reddit
                            fullWidth
                            label=""
                            name="date"
                            value={state.date || today}
                            onChange={e => handleDateChange(e.target.value)}
                        />
                    </div>
                    <ClickAwayListener onClickAway={handleClickAwayFromPrice}>
                        <div
                            className="col"
                            onClick={() => {
                                handlePrieInputVisibility(true)
                            }}
                        >
                            <span>
                                Сума:
                            </span>
                            <p>
                                {state.editPrice ?
                                    <input
                                        type="number"
                                        value={state.price}
                                        name="price"
                                        onChange={handleChange}
                                        id={`input-${doc.id}`}
                                        autoFocus
                                    />
                                    :
                                    `${state.price} лв.`
                                }
                            </p>
                        </div>
                    </ClickAwayListener>
                </div>
                <div className={`row ${state.loading ? 'disabled' : ''}`}>
                    <div
                        className="link"
                        onClick={handleDescription}
                    >
                        {typeof state.description === 'string' ? 'Премахване' : 'Добавяне'} на забележка
                    </div>
                </div>
                {typeof state.description === 'string' ?
                    <div className="row">
                        <RedditTextField
                            variant="outlined"
                            fullWidth
                            // label="Забележки"
                            name="description"
                            value={state.description || ''}
                            multiline
                            rows="3"
                            onChange={handleChange}
                            autoFocus
                        />
                    </div>
                    :
                    <>
                    </>
                }
                <h4>
                    Изберете метод на плащане
                </h4>
                {/* <ul className={`row payment ${state.loading ? 'disabled' : ''}`}>
                    {state.methods.map(m =>
                        <li
                            key   = {m.id}
                            // className={m.id === state.method ? 'active' : ''}
                            onClick={() => {
                                handleActiveMethod(m.id)
                            }}
                        >
                            <img src={m.icon} />
                            {m.translation.name}
                        </li>
                    )}
                </ul> */}
                <RedditSelect
                    // label="Изберете метод на плащане"
                    value={state.method}
                    onChange={handleActiveMethod}
                >
                    {state.methods.map(m =>
                        <MenuItem
                            key={m.id}
                            value={m.id}
                        >
                            <img src={m.icon} />
                            {m.translation.name}
                        </MenuItem>
                    )}
                </RedditSelect>
                <Button
                    className={`add ${state.loading ? 'disabled' : ''}`}
                    style={{
                        margin: '10px 0',
                        // display: 'block',
                        width: '100%'
                    }}
                    onClick={handlePay}
                >
                    {Number(state.price) < Number(state.priceToPay) ? 'Частично плащане' : 'Маркирай като платена'}
                </Button>
                {doc.paid_price ?
                    <div className="sum">
                        <div className="row">
                            <span className="col label">
                                Дата:
                            </span>
                            <span className="col label">
                                Сума:
                            </span>
                        </div>
                        {doc.payments.map(p =>
                            <div className="row" key={p.id}>
                                <p className="col">
                                    {moment(p.date).format('DD.MM.YYYY')} г.
                                </p>
                                <p className="col">
                                    {p.price.toFixed(2)} лв.
                                </p>
                            </div>
                        )}
                        <div className="total">
                            <div className="row">
                                <span className="col">
                                    Общо платени:
                                </span>
                                <p className="col green">
                                    {state.paidPrice} лв.
                                </p>
                            </div>
                            <div className="row">
                                <span className="col">
                                    За плащане:
                                </span>
                                <p className="col red">
                                    {state.priceToPay} лв.
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                    </>
                }
            </div>
        }

        return content
    }

    /**
     * 
     * @param {number} paymentId id на начин на плащане 
     */
    const handlePay = () => {
        seLoading(true)
        const url = 'documents/add-payment'
        const data = new FormData()

        data.append('id', doc.id)
        data.append('price', Number(state.price))
        data.append('payment_method_id', state.method)
        data.append('date', state.date)
        if (state.description) data.append('description', state.description)

        Api.post(url, data)
            .then(res => {
                appendData(res.data)
                setPopoverElement(null)
                app.handleSuccess(`${Number(state.price) < Number(state.priceToPay) ? 'Частичното плащане' : 'Плащането'} беше успешно!`)
            })
            .catch(error => {
                const _err = error.response.data
                app.handleError(_err.message)
            })
            .finally(() => {
                seLoading(false)
            })
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const seLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleDescription = () => {
        setState(prev => ({
            ...prev,
            description: (typeof prev.description === 'string') ? false : ''
        }))
    }

    const isPaymentDisabled = () => {
        if (!doc.is_payable && doc.paid_status !== 'paid') {
            return true
        }

        return false
    }

    function calcPriceToPayPercentage(totalPrice, paidPrice) {
        if (paidPrice === 0) {
            return 0
        }
        if (totalPrice === 0) {
            return 0
        }

        return Math.round((paidPrice / totalPrice) * 100)
    }

    function renderPie(paid = false) {
        const paidPercentage = paid ? 100 : calcPriceToPayPercentage(Number(getPaidPrice()) + Number(getUnpaidPrice()), Number(getPaidPrice()))
        return <div className={`payment-indicator ${!paid ? 'not-paid' : ''}`}>
            {paid ?
                <img src={checkGreenIcon} alt="" height={12} />
                :
                <PaymentPie paidPercentage={paidPercentage} />
            }
        </div>
    }

    return (
        <>
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <Button
                className={`options-menu-button omb omb-${doc.id}`}
                onClick={() => {
                    if (!isPaymentDisabled()) setPopoverElement(document.querySelector(`.omb-${doc.id}`))
                }}
                style={isPaymentDisabled() ? { cursor: 'default' } : {}}
            >
                {doc.paid_status === 'paid'
                    ?
                    <Tooltip
                        title="Преглед и редакция на плащанията"
                    >
                        {renderPie(true)}
                    </Tooltip>
                    :
                    <Tooltip
                        title={isPaymentDisabled() ? doc.type.id === 3 ? 'Този документ има създадено кредитно известие и не може да бъде платен.' : 'Документът не може да бъде платен.' : 'Добавяне на плащане'}
                    >
                        {doc.paid_status === 'partially' ?
                            renderPie()
                            :
                            <i>
                                <img src={doc.paid_status === 'not-paid' ? crossIcon : partlyIcon} alt="" width={doc.paid_status === 'not-paid' ? 14 : ''} className="paid-indicator" />
                            </i>
                        }
                    </Tooltip>
                }
            </Button>

            <Popover
                open={Boolean(state.configPaymentPopover)}
                anchorEl={state.configPaymentPopover}
                onClose={() => {
                    setPopoverElement(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-complexed"
            >
                {popoverContent()}
            </Popover>
        </>
    )
}

export default Config

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import loader from '../../../../assets/img/loader.svg';
import closeIcon from '../../../../assets/img/modals/icons/close.png';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import SaveButton from '../../../misc/Button';
import Api from '../../../../helpers/Api';
import { useValidation } from '../../../../helpers/Validation';
import Autocomplete from '../../../misc/Autocomplete';
import { buildNestedState } from '../../../../helpers/State';
import Grid from '@material-ui/core/Grid';
import { useNestedState } from '../../../../helpers/NestedState';

function AddOrEdit(props, ref) {

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        edit: false,
        data: null,
        onSuccess: null
    });

    const [validations, setValidations] = useValidation();

    // useEffect(() => {
    //     console.log(validations);
    // }, [validations]);

    useImperativeHandle(ref, () => ({
        add: () => {
            show();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
            data: null,
            id: null,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 150);

        setValidations(null);
    }

    const handleHide = e => {
        e.preventDefault();

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('customers/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'customers/edit' : 'customers/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post(url, data)
            .then(res => {

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }

                hide();

            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    // console.log(_err.data.errors);
                    setValidations(_err.data.errors);
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data');
    }

    const handleCountryChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.address?.country?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: {
                        ...prev.data?.address,
                        district: null,
                        municipality: null,
                        settlement: null,
                        area: null,
                        street: null,
                    }
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: {
                    ...prev.data?.address,
                    country: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }));

    }

    const handleDistrictChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.address?.district?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: {
                        ...prev.data?.address,
                        municipality: null,
                        settlement: null,
                        area: null,
                        street: null,
                    }
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: {
                    ...prev.data?.address,
                    district: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }));

    }

    const handleMunicipalityChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.address?.municipality?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: {
                        ...prev.data?.address,
                        settlement: null,
                        area: null,
                        street: null,
                    }
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: {
                    ...prev.data?.address,
                    municipality: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }));
    }

    const handleSettlementChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.address?.settlement?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: {
                        ...prev.data?.address,
                        area: null,
                        street: null,
                    }
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: {
                    ...prev.data?.address,
                    settlement: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }));
    }

    const handleAreaChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.address?.area?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: {
                        ...prev.data?.address,
                        street: null,
                    }
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: {
                    ...prev.data?.address,
                    area: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }));
    }

    const handleStreetChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.address?.street?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: {
                        ...prev.data?.address,
                    }
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: {
                    ...prev.data?.address,
                    street: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }));
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Фирма
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <Box className="body">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Наименование"
                        name="info[name]"
                        value={state.data?.info?.name || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.info?.name)}
                        helperText={validations && validations.info?.name[0]}
                        autoFocus
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="ЕИК"
                        name="info[eikegn]"
                        value={state.data?.info?.eikegn || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.info?.eikegn)}
                        helperText={validations && validations.info?.eikegn[0]}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="МОЛ"
                        name="address[mol]"
                        value={state.data?.address?.mol || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.address?.mol)}
                        helperText={validations && validations.address && validations.address.mol && (validations.address.mol[0] || validations.address.mol)}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Офис"
                        name="address[name]"
                        value={state.data?.address?.name || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.address?.name)}
                        helperText={validations && validations.address && validations.address.name && validations.address.name[0]}
                    />

                    <Autocomplete
                        inputPlaceholder="Държава"
                        inputName="address[country][name]"
                        inputIdName="address[country][id]"
                        url="countries/all"
                        selected={state.data?.address?.country}
                        error={Boolean(validations && validations.address?.country)}
                        helperText={validations && validations.address && validations.address.country && (validations.address.country[0] || validations.address.country.name)}
                        onChange={handleCountryChange}
                    />

                    <Autocomplete
                        inputPlaceholder="Област"
                        inputName="address[district][name]"
                        inputIdName="address[district][id]"
                        url="districts/all"
                        params={{
                            country_id: state.data?.address?.country?.id
                        }}
                        selected={state.data?.address?.district}
                        error={Boolean(validations && validations.address?.district)}
                        helperText={validations && validations.address && validations.address.district && (validations.address.district[0] || validations.address.district.name)}
                        onChange={handleDistrictChange}
                    />

                    <Autocomplete
                        inputPlaceholder="Община"
                        inputName="address[municipality][name]"
                        inputIdName="address[municipality][id]"
                        url="municipalities/all"
                        params={{
                            district_id: state.data?.address?.district?.id
                        }}
                        selected={state.data?.address?.municipality}
                        error={Boolean(validations && validations.address?.municipality)}
                        helperText={validations && validations.address && validations.address.municipality && (validations.address.municipality[0] || validations.address.municipality.name)}
                        onChange={handleMunicipalityChange}
                    />

                    <Autocomplete
                        inputPlaceholder="Населено място"
                        inputName="address[settlement][name]"
                        inputIdName="address[settlement][id]"
                        url="settlements/all"
                        params={{
                            municipality_id: state.data?.address?.municipality?.id
                        }}
                        selected={state.data?.address?.settlement}
                        renderOption={option => {
                            return [
                                option.name,
                                option.municipality ? 'общ. ' + option.municipality.name : null,
                                option.municipality && option.municipality.district ? 'обл. ' + option.municipality.district.name : null
                            ].filter(o => {
                                return o !== null
                            }).join(', ')
                        }}
                        error={Boolean(validations && validations.address?.settlement)}
                        helperText={validations && validations.address && validations.address.settlement && (validations.address.settlement[0] || validations.address.settlement.name)}
                        onChange={handleSettlementChange}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Пощенски код"
                        name="address[postcode]"
                        value={state.data?.address?.postcode || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.address?.postcode)}
                        helperText={validations && validations.address && validations.address.postcode && validations.address.postcode[0]}
                    />

                    <Autocomplete
                        inputPlaceholder="Район"
                        inputName="address[area][name]"
                        inputIdName="address[area][id]"
                        url="areas/all"
                        params={{
                            settlement_id: state.data?.address?.settlement?.id
                        }}
                        selected={state.data?.address?.area}
                        error={Boolean(validations && validations.address?.area)}
                        helperText={validations && validations.address && validations.address.area && (validations.address.area[0] || validations.address.area.name)}
                        onChange={handleAreaChange}
                    />

                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Autocomplete
                                inputPlaceholder="Булевард/улица"
                                inputName="address[street][name]"
                                inputIdName="address[street][id]"
                                url="streets/all"
                                params={{
                                    settlement_id: state.data?.address?.settlement?.id
                                }}
                                selected={state.data?.address?.street}
                                error={Boolean(validations && validations.address?.street)}
                                helperText={validations && validations.address && validations.address.street && (validations.address.street[0] || validations.address.street.name)}
                                onChange={handleStreetChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="№"
                                name="address[street_no]"
                                value={state.data?.address?.street_no || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.address?.street_no)}
                                helperText={validations && validations.address && validations.address.street_no && validations.address.street_no[0]}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Блок"
                                name="address[block]"
                                value={state.data?.address?.block || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.address?.block)}
                                helperText={validations && validations.address && validations.address.block && validations.address.block[0]}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Вход"
                                name="address[entrance]"
                                value={state.data?.address?.entrance || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.address?.entrance)}
                                helperText={validations && validations.address && validations.address.entrance && validations.address.entrance[0]}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Етаж"
                                name="address[floor]"
                                value={state.data?.address?.floor || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.address?.floor)}
                                helperText={validations && validations.address && validations.address.floor && validations.address.floor[0]}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Апартамент"
                                name="address[apartment]"
                                value={state.data?.address?.apartment || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.address?.apartment)}
                                helperText={validations && validations.address && validations.address.apartment && validations.address.apartment[0]}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);
import { useHistory } from "react-router"

//plugins
import moment from "moment"

//misc
import DatePicker from 'components/misc/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'

//helpers
import { useQuery } from 'helpers/Url'

//images
import clearFilterIcon from 'assets/img/documents/icons/clear-filter.png'

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const handleDateChange = (date, name) => {
        props.handleSearch(name, date)
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleClearFilter = () => {
        history.push(history.location.pathname)
    }

    const areAppliedFilters = () => {
        if (props.filter.search || props.filter.start_date || props.filter.end_date) return true
        else return false
    }

    return (
        <div className="row filter">
            <div
                className="col"
                style={{
                    maxWidth: '300px'
                }}
            >
                <RedditTextField
                    size="small"
                    variant="filled"
                    margin="dense"
                    fullWidth
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>
            <div
                className="col"
                style={{
                    maxWidth: '150px'
                }}
            >
                <DatePicker
                    reddit
                    fullWidth
                    label="От дата"
                    name="date"
                    disableToolbar
                    format="dd/MM/yyyy"
                    value={props.filter.start_date || ''}
                    onChange={e => {
                        handleDateChange(e.target.value, 'start_date')
                    }}
                />
            </div>

            <div
                className="col"
                style={{
                    maxWidth: '150px'
                }}
            >
                <DatePicker
                    reddit
                    fullWidth
                    label="До дата"
                    name="date"
                    disableToolbar
                    format="dd/MM/yyyy"
                    value={props.filter.end_date || ''}
                    onChange={e => {
                        handleDateChange(e.target.value, 'end_date')
                    }}
                />
            </div>
            <div
                className={`col link ${areAppliedFilters() ? '' : 'disabled'}`}
                onClick={handleClearFilter}
                style={{
                    justifyContent: 'center',
                    width: 'fit-content',
                    flex: 'initial'
                }}
            >
                <img src={clearFilterIcon} />
            </div>
        </div>
    )
}

export default Filter
//misc
import RedditTextField from 'components/misc/RedditTextField'

//MUI components
import { Tooltip } from '@material-ui/core'

function Filter(props) {
    const handleSearch = e => {
        const { name } = e.target
        const { value } = e.target

        props.handleSearch(name, value)
    }

    return (
        <div className="row filter">
            <div
                className="col"
                style={{
                    maxWidth: '300px'
                }}
            >
                <Tooltip
                    title="Търсене на продукт по име"
                >
                    <RedditTextField
                        size="small"
                        variant="filled"
                        margin="dense"
                        fullWidth
                        label="Търсене..."
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </Tooltip>
            </div>
        </div>
    )
}

export default Filter
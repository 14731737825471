import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
// import { trans } from '../../../_providers/Translation';
import Button from '@material-ui/core/Button';
import SaveButton from '../../../misc/Button';
import TextField from '@material-ui/core/TextField';
import closeIcon from '../../../../assets/img/modals/icons/close.png';
import { useValidation } from '../../../../helpers/Validation';
import Api from '../../../../helpers/Api';
import { plural, trans } from 'providers/Translation';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';

const PERIODS = {
    day: 1,
    week: 2,
    month: 3,
    year: 4,
    threeMonths: 5,
    sixMonths: 6,
};

const WEEK_DAYS = [
    {
        id: 1,
        name: trans('dates.weekdaysObj.monday')
    },
    {
        id: 2,
        name: trans('dates.weekdaysObj.tuesday')
    },
    {
        id: 3,
        name: trans('dates.weekdaysObj.wednesday')
    },
    {
        id: 4,
        name: trans('dates.weekdaysObj.thursday')
    },
    {
        id: 5,
        name: trans('dates.weekdaysObj.friday')
    },
    {
        id: 6,
        name: trans('dates.weekdaysObj.saturday')
    },
    {
        id: 7,
        name: trans('dates.weekdaysObj.sunday')
    }
];

const MONTHS = [
    {
        id: 1,
        name: trans('dates.months')[0]
    },
    {
        id: 2,
        name: trans('dates.months')[1]
    },
    {
        id: 3,
        name: trans('dates.months')[2]
    },
    {
        id: 4,
        name: trans('dates.months')[3]
    },
    {
        id: 5,
        name: trans('dates.months')[4]
    },
    {
        id: 6,
        name: trans('dates.months')[5]
    },
    {
        id: 7,
        name: trans('dates.months')[6]
    },
    {
        id: 8,
        name: trans('dates.months')[7]
    },
    {
        id: 9,
        name: trans('dates.months')[8]
    },
    {
        id: 10,
        name: trans('dates.months')[9]
    },
    {
        id: 11,
        name: trans('dates.months')[10]
    },
    {
        id: 12,
        name: trans('dates.months')[11]
    }
];

function RepeatOptions(props, ref) {

    const defaultData = {
        repeat: false,
        disable_repeat_end_date: false,
        repeat_after: 1,
        repeat_on_day: 1,
        repeat_on_month: 1,
        repeat_on_last_day: false,
    };

    const [state, setState] = useState({
        id: null,
        overlay: false,
        modal: false,
        data: defaultData,
        onSuccess: null,
        onError: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: (data) => {
            handleOpen(data);
        },
        close: () => {
            handleHide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleOpen = (data) => {

        if (!data?.id) {
            throw new Error('not valid data');
        }

        setState(prev => ({
            ...prev,
            overlay: true,
            data: data,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50);
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);

        resetData();
        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }));
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd';
        const value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleStartDateChange = e => {
        const value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                repeat_start_date_ymd: value,
                repeat_end_date_ymd: value > prev.data.repeat_end_date_ymd ? value : prev.data.repeat_end_date_ymd,
            }
        }))
    }

    const handleEndDateChange = e => {
        const value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                repeat_end_date_ymd: value < prev.data.repeat_start_date_ymd ? prev.data.repeat_start_date_ymd : value,
            }
        }))
    }

    const handleSave = e => {
        e.preventDefault();

        setLoading(true)
        setValidations(null)

        const url = 'documents/make-repeat'
        const data = new FormData(e.target)

        if (state.data.id) data.append('id', state.data.id)

        Api.post(url, data)
            .then(res => {
                if (res.data.success) {
                    if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                    handleHide()
                } else {
                    if (typeof state.onError === 'function') state.onError(res.data)
                }

            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const getRepeatNumbers = () => {
        let n = [];

        for (let i = 1; i <= 31; i++) {
            n.push(i);
        }

        return n;
    }

    const getWeekValues = () => {
        return [
            {
                id: 1,
                name: 'седмица'
            },
            {
                id: 2,
                name: '2 седмици'
            },
            {
                id: 3,
                name: '3 седмици'
            },
            {
                id: 4,
                name: '4 седмици'
            },
        ]
    }

    const getMonthValues = () => {
        let n = [];

        for (let i = 1; i <= 12; i++) {
            n.push({
                id: i,
                name: i === 1 ? 'месец' : (i + ' месеца')
            });
        }

        return n;
    }

    const repeatAfterIsVisible = () => {
        if (isDay() || isWeek() || isMonth()) {
            return true;
        }

        return false;
    }

    const getWeekDays = () => {
        return WEEK_DAYS;
    }

    const getMonths = () => {
        return MONTHS;
    }

    const getMonthDays = month => {
        if (!month) {
            return [];
        }

        let days = moment(moment().format('YYYY') + month.toFixed(2), 'YYYY-MM').daysInMonth();

        let n = [];

        for (let i = 1; i <= days; i++) {
            if (Number(month) === 2) {
                if (i > 28) {
                    break;
                }
            }

            n.push(i);
        }

        return n;
    }

    const isDay = () => {
        return state.data.repeat_period === PERIODS.day;
    }

    const isWeek = () => {
        return state.data.repeat_period === PERIODS.week;
    }

    const isMonth = () => {
        return state.data.repeat_period === PERIODS.month;
    }

    const isThreeMonths = () => {
        return state.data.repeat_period === PERIODS.threeMonths;
    }

    const isSixMonths = () => {
        return state.data.repeat_period === PERIODS.sixMonths;
    }

    const isYear = () => {
        return state.data.repeat_period === PERIODS.year;
    }

    const isMonthPeriod = () => {
        if (isMonth() || isThreeMonths() || isSixMonths()) {
            return true;
        }

        return false;
    }

    const isWeekPeriod = () => {
        if (isWeek()) {
            return true;
        }

        return false;
    }

    const isYearPeriod = () => {
        if (isYear()) {
            return true;
        }

        return false;
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary small`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Периодично издаване
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.data && state.data.repeat ? true : false}
                                onChange={handleCheckboxChange}
                                name="repeat"
                                value={1}
                                color="primary"
                            />
                        }
                        label={trans('documents/index.repeat')}
                    />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Издаване от дата"
                                name="repeat_start_date"
                                type="date"
                                value={Number(state.data?.repeat) === 0 ? '' : (state.data?.repeat_start_date_ymd || '')}
                                onChange={handleStartDateChange}
                                error={Boolean(validations && validations.repeat_start_date)}
                                helperText={validations && validations.repeat_start_date && (validations.repeat_start_date[0] || validations.repeat_start_date)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={Number(state.data?.repeat) === 0}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Издаване до дата"
                                name="repeat_end_date"
                                type="date"
                                value={Number(state.data?.repeat) === 0 || Number(state.data?.disable_repeat_end_date) === 1 ? '' : (state.data?.repeat_end_date_ymd || '')}
                                onChange={handleEndDateChange}
                                error={Boolean(validations && validations.repeat_end_date)}
                                helperText={validations && validations.repeat_end_date && (validations.repeat_end_date[0] || validations.repeat_end_date)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={Number(state.data?.repeat) === 0 || Number(state.data?.disable_repeat_end_date) === 1}
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.data && state.data.disable_repeat_end_date ? true : false}
                                        onChange={handleCheckboxChange}
                                        name="disable_repeat_end_date"
                                        value={1}
                                        color="primary"
                                        disabled={Number(state.data?.repeat) === 0}
                                    />
                                }
                                // label={trans('documents/index.repeat')}
                                label="Без крайна дата"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={Boolean(validations && validations.repeat_period)}
                            >
                                <InputLabel>
                                    Период
                                </InputLabel>
                                <Select
                                    name="repeat_period"
                                    value={Number(state.data?.repeat) === 0 ? '' : (state.data?.repeat_period || '')}
                                    onChange={handleInputChange}
                                    label="Период"
                                    disabled={Number(state.data?.repeat) === 0}

                                >
                                    {/* <MenuItem value={PERIODS.day}>
                                        {plural('documents/index.plural_periods.day', state.data.repeat_after || 1)}
                                    </MenuItem> */}
                                    <MenuItem value={PERIODS.week}>
                                        седмица
                                    </MenuItem>
                                    <MenuItem value={PERIODS.month}>
                                        месец
                                    </MenuItem>
                                    {/* <MenuItem value={PERIODS.threeMonths}>
                                        3 месеца
                                    </MenuItem>
                                    <MenuItem value={PERIODS.sixMonths}>
                                        6 месеца
                                    </MenuItem> */}
                                    <MenuItem value={PERIODS.year}>
                                        година
                                    </MenuItem>
                                </Select>
                                <FormHelperText>
                                    {validations && validations.repeat_period && (validations.repeat_period[0] || validations.repeat_period)}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>

                        {repeatAfterIsVisible() &&
                            <Grid item xs={6}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={Boolean(validations && validations.repeat_after)}
                                >
                                    <InputLabel>
                                        Издавай веднъж на
                                    </InputLabel>
                                    <Select
                                        name="repeat_after"
                                        value={Number(state.data?.repeat) === 0 ? '' : (state.data?.repeat_after || '')}
                                        onChange={handleInputChange}
                                        label="Издавай веднъж на"
                                        disabled={Number(state.data?.repeat) === 0}
                                    >
                                        {isWeek() ?
                                            getWeekValues().map(n =>
                                                <MenuItem key={n.id} value={n.id}>
                                                    {n.name}
                                                </MenuItem>
                                            )
                                            :
                                            getMonthValues().map(n =>
                                                <MenuItem key={n.id} value={n.id}>
                                                    {n.name}
                                                </MenuItem>
                                            )}
                                    </Select>
                                    <FormHelperText>
                                        {validations && validations.repeat_after && (validations.repeat_after[0] || validations.repeat_after)}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={repeatAfterIsVisible() ? 6 : 12}>

                            {isWeekPeriod()
                                ?
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={Boolean(validations && validations.repeat_on_day)}
                                >
                                    <InputLabel>
                                        Ден от седмицата
                                    </InputLabel>
                                    <Select
                                        name="repeat_on_day"
                                        value={Number(state.data?.repeat) === 0 ? '' : (state.data?.repeat_on_day || '')}
                                        onChange={handleInputChange}
                                        label="Ден от седмицата"
                                        disabled={Number(state.data?.repeat) === 0}
                                    >
                                        {getWeekDays().map(day =>
                                            <MenuItem key={day.id} value={day.id}>
                                                {day.name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>
                                        {validations && validations.repeat_on_day && (validations.repeat_on_day[0] || validations.repeat_on_day)}
                                    </FormHelperText>
                                </FormControl>
                                :
                                isMonthPeriod()
                                    ?
                                    <>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Число от месеца"
                                            name="repeat_on_day"
                                            type="number"
                                            value={Number(state.data?.repeat) === 0 || Number(state.data?.repeat_on_last_day) === 1 ? '' : (state.data?.repeat_on_day || '')}
                                            onChange={handleInputChange}
                                            error={Boolean(validations && validations.repeat_on_day)}
                                            helperText={validations && validations.repeat_on_day && (validations.repeat_on_day[0] || validations.repeat_on_day)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={Number(state.data?.repeat) === 0 || Number(state.data?.repeat_on_last_day) === 1}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.data && state.data.repeat_on_last_day ? true : false}
                                                    onChange={handleCheckboxChange}
                                                    name="repeat_on_last_day"
                                                    value={1}
                                                    color="primary"
                                                />
                                            }
                                            // label={trans('documents/index.repeat')}
                                            label="Последно число от месеца"
                                        />
                                    </>
                                    :
                                    isYearPeriod()
                                        ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    error={Boolean(validations && validations.repeat_on_month)}
                                                >
                                                    <InputLabel>
                                                        Издаване през месец
                                                    </InputLabel>
                                                    <Select
                                                        name="repeat_on_month"
                                                        value={Number(state.data?.repeat) === 0 ? '' : (state.data?.repeat_on_month || '')}
                                                        onChange={handleInputChange}
                                                        label="Издаване през месец"
                                                        disabled={Number(state.data?.repeat) === 0}
                                                    >
                                                        {getMonths().map(month =>
                                                            <MenuItem key={month.id} value={month.id}>
                                                                {month.name}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {validations && validations.repeat_on_month && (validations.repeat_on_month[0] || validations.repeat_on_month)}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    error={Boolean(validations && validations.repeat_on_day)}
                                                >
                                                    <InputLabel>
                                                        Число от месеца
                                                    </InputLabel>
                                                    <Select
                                                        name="repeat_on_day"
                                                        value={Number(state.data?.repeat) === 0 ? '' : (state.data?.repeat_on_day || '')}
                                                        onChange={handleInputChange}
                                                        label="Число от месеца"
                                                        disabled={Number(state.data?.repeat) === 0}
                                                    >
                                                        {getMonthDays(state.data.repeat_on_month).map(day =>
                                                            <MenuItem key={day} value={day}>
                                                                {day}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {validations && validations.repeat_on_day && (validations.repeat_on_day[0] || validations.repeat_on_day)}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        :
                                        ''
                            }
                        </Grid>

                    </Grid>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Number(state.data?.repeat) === 0 ? false : (state.data && state.data.repeat_auto_send ? true : false)}
                                onChange={handleCheckboxChange}
                                name="repeat_auto_send"
                                value={1}
                                color="primary"
                                disabled={Number(state.data?.repeat) === 0}
                            />
                        }
                        label={trans('documents/index.repeat_auto_send')}
                    />

                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(RepeatOptions);
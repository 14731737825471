import { useState, useImperativeHandle, forwardRef } from "react"

//MUI components
import { Button, FormControlLabel, Checkbox } from "@material-ui/core"

//misc
import SaveButton from "components/misc/Button"

//images
import closeIcon from "assets/img/modals/icons/close.png"

//style
import "assets/scss/send.scss"

//components
import RedditTextField from "components/misc/RedditTextField"

function AddEditAccountant(props, ref) {
  const [fieldValues, setFieldsValues] = useState({})
  const [state, setState] = useState({
    overlay: false,
    modal: false,
    id: null,
  })

  useImperativeHandle(ref, () => ({
    open: id => {
      show(id)
    },
    close: () => {
      hide()
    },
    onSuccess: (fn) => {
      setState((prev) => ({
        ...prev,
        onSuccess: fn,
      }))
    },
    onError: (fn) => {
      setState((prev) => ({
        ...prev,
        onError: fn,
      }))
    },
    onClose: (fn) => {
      setState((prev) => ({
        ...prev,
        onClose: fn,
      }))
    },
  }))

  function show(id = null) {
    setState((prev) => ({
      ...prev,
      overlay: true,
      id
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        modal: true,
      }))
    }, 50)
  }

  function hide() {
    setState((prev) => ({
      ...prev,
      modal: false,
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        overlay: false,
        id: null,
      }))
    }, 100)
  }

  function handleSave(e) {
    e.preventDefault()
  }

  function handleChange(e) {
    const { name, value } = e.target

    setFieldsValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  function handleCheck(e) {
    const { name } = e.target

    setFieldsValues((prev) => ({
      ...prev,
      [name]: !fieldValues[name],
    }))
  }

  return (
    <div className={`${state.overlay ? "visible" : ""} overlay`}>
      {state.overlay ? (
        <form
          className={`${
            state.modal ? "show" : ""
          } add-accountant-modal popup-primary large`}
          noValidate
          onSubmit={handleSave}
        >
          <div className="body">
            <h3 className="head">
              Моят счетоводител
              <img
                className="close-icon"
                alt="close"
                src={closeIcon}
                onClick={hide}
              />
            </h3>
            <p>
              Lorem Ipsum е елементарен примерен текст, използван в
              типографската индустрия
            </p>
            <div className="fields row">
              <div className="col">
                <b>Данни за вход</b>
                <div className="row">
                  <RedditTextField
                    variant="outlined"
                    fullWidth
                    label="Име"
                    name="name"
                    value={fieldValues.name || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <RedditTextField
                    variant="outlined"
                    fullWidth
                    label="Е-поща"
                    name="email"
                    value={fieldValues.email || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <RedditTextField
                    variant="outlined"
                    fullWidth
                    label="Тел. номер"
                    name="phone"
                    value={fieldValues.phone || ""}
                    onChange={handleChange}
                    type="number"
                  />
                </div>
                <div className="row">
                  <RedditTextField
                    variant="outlined"
                    fullWidth
                    label="Адрес"
                    name="address"
                    value={fieldValues.address || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="with-create">
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{
                          padding: "0",
                          marginRight: "5px",
                        }}
                        name="create"
                        color="primary"
                        value="1"
                        checked={fieldValues.create || false}
                        onChange={handleCheck}
                      />
                    }
                    label="Създаване на профил на счетоводителя"
                  />
                  <p>
                    Lorem Ipsum е елементарен примерен текст, използван в
                    печатарската и индустрия.
                  </p>
                </div>
                <b>Лични данни</b>
                <div className="row">
                  <RedditTextField
                    variant="outlined"
                    fullWidth
                    label="Е-поща"
                    name="email"
                    value={fieldValues.email || ""}
                    onChange={handleChange}
                    disabled={!fieldValues.create}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <RedditTextField
                      variant="outlined"
                      fullWidth
                      label="Парола"
                      name="password"
                      value={fieldValues.password || ""}
                      onChange={handleChange}
                      type="password"
                      disabled={!fieldValues.create}
                    />
                  </div>
                  <div className="col">
                    <RedditTextField
                      variant="outlined"
                      fullWidth
                      label="Повтори паролата"
                      name="confirm_password"
                      value={fieldValues.confirm_password || ""}
                      onChange={handleChange}
                      type="password"
                      disabled={!fieldValues.create}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <Button onClick={hide} className="cancel">
              {props.cancelBtnText || "Отказ"}
            </Button>
            <SaveButton
              loading={state.loading}
              text={state.id ? 'Запазване' : 'Добавяне'}
              className="save"
            />
          </div>
        </form>
      ) : (
        <></>
      )}
    </div>
  )
}

export default forwardRef(AddEditAccountant)

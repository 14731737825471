export const DOCUMENT_TYPE = {
    OFFER: 1,
    PROFORMA: 2,
    INVOICE: 3,
    CREDIT_NOTE: 4,
    DEBIT_NOTE: 5,
    PROTOCOL: 6,
    STOCK: 7,
};

export const PAYORDER_TYPE = {
    BUDGET_PAYMENT: 1,
    BUDGET_PAYMENT_MULTI: 2,
    CREDIT_ORDER: 3,
    DEBIT_ORDER: 4,
    PAYMENT_NOTE: 5,
    CASH_ORDER_INCOME: 6,
    CASH_ORDER_DEBIT: 7,
};

export const PERIOD_FILTER_TYPES = {
    'prev-year': 'Предходна година',
    'this-week': 'Текуща седмица',
    'this-month': 'Текущ месец',
    'prev-month': 'Предходен месец',
    'last-3-months': 'Последни 3 месеца',
    'last-6-months': 'Последни 6 месеца',
    'last-year': 'Текуща година'
}

export const SENT_FILTER_TYPES = {
    '0': 'Всички',
    '1': 'Изпратени',
    '2': 'Неизпратени'
}

export const PAID_FILTER_TYPES = {
    '0': 'Всички',
    'not-paid': 'Неплатени',
    'paid': 'Платени',
    'partially': 'Частично платени'
}

export const DOC_FILTER_TYPES = {
    [DOCUMENT_TYPE.INVOICE]: {
        name: 'Фактури',
        shortName: 'Фактури'
    },
    [DOCUMENT_TYPE.PROFORMA]: {
        name: 'Проформи фактури',
        shortName: 'Пр. фактури'
    },
    [DOCUMENT_TYPE.DEBIT_NOTE]: {
        name: 'Дебитни известия',
        shortName: 'Дб. известия'
    },
    [DOCUMENT_TYPE.CREDIT_NOTE]: {
        name: 'Кредитни известия',
        shortName: 'Кр. известия'
    }
}
import { createContext, useState, useContext, useEffect } from "react"
import { cloneDeep } from "lodash"

//helpers
import { useIsMount } from "helpers/IsMount"
import { useNestedState } from "helpers/NestedState"
import { usePresenceSocketChannel } from "helpers/Socket"
import { useQuery } from "helpers/Url"

//providers
import { useAuthDataContext } from "providers/Auth"
import { useHistory } from "react-router-dom"
import Api from "helpers/Api"

export const DocumentContext = createContext({})

const DocumentProvider = props => {
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()
    const isMount = useIsMount()

    const filter = {
        page: 1,
        start_date: '',
        end_date: '',
        search: '',
        sent: '',
        period: '',
        status: '',
        payment_method_id: '',
        type_id: ''
    }

    const [state, setState] = useState({
        loading: true,
        forceLoading: true,
        refresh: false,
        refreshPreview: false,
        setFilter: false,
        // data: [],
        // pages: 0,
        // total: 0,
        filter: {

        },
        docCount: {}
    })

    const [documents, setDocuments] = useNestedState({})

    const [documentsEditing, setDocumentsEditing] = useState({})

    const [documentsCount, setDocumentsCount] = useState({
        offer: 0,
        proforma: 0,
        invoice: 0,
        creditnote: 0,
        debitnote: 0,
        protocol: 0,
        stock: 0
    })

    const [documentTabs, setDocumentTabs] = useState({
        data: {},
        type: null
    })

    const handlePreviewInvoice = (data = {}) => {
        setDocumentTabs(prev => ({
            ...prev,
            data,
            type: data?.id ? 1 : null,
        }))
        history.push('/invoices')
    }

    const handleEditInvoice = (data = {}) => {
        setDocumentTabs(prev => ({
            ...prev,
            data,
            type: data?.id ? 3 : null,
        }))
        history.push('/invoices')
    }


    useEffect(() => {
        if (!isMount) return

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter.page,
                page: 1,
            },
            refresh: new Date(),
            // setFilter: new Date(),
            forceLoading: true,
        }))
    }, [auth])

    usePresenceSocketChannel({
        channel: 'companies',
        channelId: auth.getUser()?.getCompany()?.getId(),
        event: '.blackbox\\documents\\events\\InvoiceChanged',
        onEvent: (payload) => {
            setState(prev => ({
                ...prev,
                // [payload.type_id]: {},
                refresh: new Date(),
                setFilter: new Date(),
                forceLoading: false,
                refreshPreview: new Date()
            }))

            // да се изчисти стейта на запазения документ,
            // за да може да се направи нова заявка за него, 
            // когато се отвори страницата за редакция
            if (documents[payload.id]) {
                setDocuments(prev => ({
                    ...prev,
                    [payload.id]: null
                }))
            }
        },
    }, [auth])

    const channel = usePresenceSocketChannel({
        channel: 'documents',
        channelId: auth.getUser()?.getCompany()?.getId(),
        onJoin: members => {
            // console.log(members);
        },
        onJoining: member => {
            // console.log(member);
        },
        onLeaving: member => {
            removeDocumentsEditingUser(member.id)
        }
    }, [auth])

    useEffect(() => {
        if (!channel) {
            return
        }

        channel.listenForWhisper('editing', e => {
            setDocumentsEditing(prev => ({
                ...prev,
                [e.id]: prev[e.id] ? {
                    ...prev[e.id],
                    [e.user.id]: e.user
                } : {
                    [e.user.id]: e.user
                }
            }))
        })

        channel.listenForWhisper('stopEditing', e => {
            removeDocumentsEditingUser(e.user.id, e.id)
        })

        channel.whisper('triggerEditing')

    }, [channel])

    const removeDocumentsEditingUser = (userId, documentId = null) => {
        let data = cloneDeep(documentsEditing)

        if (documentId) {
            if (data[documentId]) {
                if (data[documentId][userId]) {
                    delete data[documentId][userId]
                }

                if (Object.keys(data[documentId]).length === 0) {
                    delete data[documentId]
                }
            }
        } else {
            Object.entries(data).map(e => {
                if (data[e[0]][userId]) {
                    delete data[e[0]][userId]
                }

                if (Object.keys(data[e[0]]).length === 0) {
                    delete data[e[0]]
                }
            })
        }

        setDocumentsEditing(data)
    }

    const setDocumentData = (id, data) => {
        setDocuments(id, data, null)
    }

    const clearFilter = () => {
        setState(prev => ({
            ...prev,
            filter: filter,
            setFilter: new Date().getTime()
        }))
    }

    const cleanUp = () => {
        setState(prev => ({
            ...prev,
            refresh: false,
            setFilter: false,
        }))
    }

    const loadDocumentsCount = async () => {
        return await Api.get('documents/count')
            .then(res => {
                let data = {}
                let docCount = {}
                res.data.map(r => {
                    data[r.name] = r.count
                    docCount[r.type_id] = r
                })

                setDocumentsCount(data)
                setState(prev => ({
                    ...prev,
                    docCount
                }))
            })
    }

    const data = {
        state,
        setState,
        documents,
        setDocuments,
        setDocumentData,
        documentsEditing,
        clearFilter,
        cleanUp,
        loadDocumentsCount,
        documentsCount,
        handlePreviewInvoice,
        documentTabs,
        handleEditInvoice,
    }

    return <DocumentContext.Provider value={data} {...props} />
}

export const useDocumentContext = () => useContext(DocumentContext)

export default DocumentProvider
import { forwardRef, useImperativeHandle } from 'react'

//MUI components
import { Button } from '@material-ui/core'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//images
import closeIcon from 'assets/img/app/icons/close.png'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'
import Autocomplete from 'components/misc/Autocomplete'

function AddOrEditOffice({ companyId, refresh }, ref) {
    const auth = useAuthDataContext()
    const app = useAppContext()

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        data: null,
        onSuccess: null
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        open: data => {
            show(data)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = data => {
        setState(prev => ({
            ...prev,
            overlay: true,
            data
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                data: null,
                overlay: false,
                modal: false,
            }))
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)

        setValidations(null)

        const url = state.data?.id ? 'addresses/edit' : 'addresses/add'
        const data = new FormData(e.target)
        if (state.data?.id) data.append('id', state.data?.id)

        Api.post(url, data)
            .then(res => {
                app.handleSuccess('Офисът беше добавен успешно!')
                auth.getUser().addCompany(res.data)

                hide()
                refresh()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
    }


    const handleSettlementChange = settlement => {
        setState(prev => ({
            ...prev,
            settlement
        }))
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                    <input hidden name="company_id" value={companyId} />
                    <h2 className="head">
                        {state.data?.id ? 'Редакция' : 'Добавяне'} на офис
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </h2>

                    <div className="body">

                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Наименование"
                                    name="name"
                                    value={state.data?.name || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations?.name)}
                                    helperText={validations && validations?.name && (validations?.name[0] || validations?.name)}
                                    autoFocus
                                    required
                                />
                            </div>
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="МОЛ"
                                    name="mol"
                                    value={state.data?.mol || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations?.mol)}
                                    helperText={validations && validations?.mol}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Autocomplete
                                    // inputPlaceholder="Населено място"
                                    inputName={`settlement[name]`}
                                    inputIdName={`settlement[id]`}
                                    url="settlements/all"
                                    params={{
                                        municipality_id: state.data?.municipality?.id
                                    }}
                                    selected={state.data?.settlement}
                                    renderOption={option => {
                                        return [
                                            option.name,
                                            option.municipality ? 'общ. ' + option.municipality.name : null,
                                            option.municipality && option.municipality.district ? 'обл. ' + option.municipality.district.name : null
                                        ].filter(o => {
                                            return o !== null
                                        }).join(', ')
                                    }}
                                    error={Boolean(validations && validations?.settlement)}
                                    helperText={validations && validations?.settlement}
                                    inputPlaceholder="Населено място"
                                    onChange={data => handleSettlementChange(data)}
                                    onInputChange={data => handleSettlementChange(data)}
                                    required={true}
                                />
                            </div>
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Адрес"
                                    name="address"
                                    value={state.data?.address || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations?.address)}
                                    helperText={validations && validations?.address}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            className="save"
                            loading={state.loading}
                        />
                    </div>
                </form >
            </div >
        </>
    )
}

export default forwardRef(AddOrEditOffice)
import React from 'react'
import ReactPaginate from 'react-paginate';

//images
import prevPageIcon from 'assets/img/app/icons/prev-page.png'
import nextPageIcon from 'assets/img/app/icons/next-page.png'

function Pagination(props) {
    const page = (props.page || 1)
    return (
        props.pages > 1 ?
            <ReactPaginate
                previousLabel={<img src={prevPageIcon} />}
                nextLabel={<img src={nextPageIcon} />}
                breakLabel={'...'}
                // breakClassName={'break-me'}
                pageCount={props.pages}
                initialPage={page - 1}
                forcePage={page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={props.handlePage}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                disableInitialCallback={true}
            />
            :
            ''

    )
}

export default Pagination
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { cloneDeep } from 'lodash'
import printJS from 'print-js'

//constants
import { DOCUMENT_TYPE } from 'constants/Document'

//modals
import Question from 'components/modals/Question'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'
import { isProd } from 'helpers/mode'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useDocumentContext } from './Provider'
import { useAppContext } from 'providers/App'

//MUI components
import { Checkbox, Tooltip, Button } from '@material-ui/core'

//images
import checkIcon from 'assets/img/app/icons/check.png'
import mailIcon from 'assets/img/app/icons/mail.png'
import downloadIcon from 'assets/img/app/icons/download.png'
import printIcon from 'assets/img/app/icons/print.png'

//partials
import MultipleActions from './partials/MultipleActions'
import RepeatOptions from './partials/RepeatOptions'
import MakePaid from './partials/payment/MakePaid'
import Options from './partials/Options'
import AddPayment from './partials/payment/AddPayment'
import Filter from './partials/filter/Index'
import Send from 'components/partials/documents/Send'
import Export from './partials/export/Export.jsx'
import Config from './partials/payment/Config'
import AdditionalOptions from './partials/additional_options/Index'

//miscs
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import EmptyList from 'components/misc/EmptyList'
import Pagination from 'components/misc/Pagination'
import MenuOptions from 'components/misc/MenuOptions'
import axios from 'axios'

let timeout
function Index(props) {
    const APP_URL = process.env[isProd ? 'REACT_APP_URL' : 'REACT_APP_URL_DEV']

    const {
        handleAddNewSubtab,
        activeSubtabIndex,
        typeId,
        activeTabInfo,
        handleChangeTabs
    } = props
    const location = useLocation()
    const query = useQuery()
    const auth = useAuthDataContext()
    const app = useAppContext()
    const deleteModalRef = useRef(null)
    const makePaidModalRef = useRef(null)
    const makeUnpaidModalRef = useRef(null)
    const addPaymentModalRef = useRef(null)
    const sendModalRef = useRef(null)
    const repeatOptionsModalRef = useRef(null)
    const exportModalRef = useRef(null)
    const context = useDocumentContext()

    const { state, setState } = context

    const [localState, setLocalState] = useState({
        selected: {},
        printLoading: false,
        successTooltip: false,
        successMessage: '',
        configPaymentPopover: null
    })
    useEffect(() => {
        //всички методи на плащане
        app.getPaymentMethods()
    }, [])

    useEffect(() => {
        if (state.setFilter) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: query.get('page') || 1,
                    start_date: query.get('start_date') || '',
                    end_date: query.get('end_date') || '',
                    search: query.get('search') || '',
                },
                refresh: new Date().getTime(),
            }))
        }
    }, [location.search])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh])

    useEffect(() => {
        if (state[typeId]?.data) return

        loadData()

        return () => {
            context.cleanUp()
        }
    }, [typeId])


    //Трябва да се направи с React Query и данните да се кешират там.
    //Проблема с презареждането на документите е, че при смяна на на табовете на документите се задейства филтъра, защото се получава промяна в URL-a.
    //Тази промяна задължително трябва да задейства филтъра, защото в нопции за филтър също има промяна на типа документ,а тогава задължително трябва да презарежда.
    const loadData = () => {
        if (state.forceLoading) loading(true)

        let url = `documents/all?type_id=${typeId}`
        let params = {}
        Object.entries(state.filter).map(filter => {
            if ((String(filter[1]) || '').includes(',')) {
                filter[1] = filter[1].split(',')
            }

            params = {
                ...params,
                [filter[0]]: filter[1]
            }
        })


        if (state.ajaxRequest) state.ajaxRequest.cancel()
        const ajaxRequest = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            ajaxRequest
        }))

        Api.get(url, {
            params,
            cancelToken: ajaxRequest.token
        })
            .then(res => {
                const { items, total, pages, totals } = res.data

                setState(prev => ({
                    ...prev,
                    [typeId]: {
                        data: items,
                        total,
                        pages,
                        totals
                    }
                }))

                if (res.data.pages && res.data.pages < state.filter.page) handleSearch('page', res.data.pages, 0)
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading,
            forceLoading: true,
        }))
    }

    const refresh = (reset = true, forceLoading = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: new Date().getTime(),
            forceLoading
        }))
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }


    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('documents/delete', {
            id
        })
            .then(res => {
                if (res.data.success) {
                    success('Документа беше изтрит успешно!')
                    document.querySelector(`.tabs .active li[data-id="${id}"] i.close`)?.click()
                    refresh()
                }
            })
            .catch(() => {
                error()
            })
    }

    const handleMakePaid = id => {
        const modal = makePaidModalRef.current
        modal.open(id)
        modal.onSuccess(() => {
            refresh()
            success()
        })
    }

    const handleMakeUnpaid = id => {
        const modal = makeUnpaidModalRef.current
        modal.open()
        modal.onSuccess(() => {
            makeUnpaid(id)
        })
    }

    const makeUnpaid = id => {
        Api.post('documents/make-unpaid', {
            id
        })
            .then(res => {
                if (res.data.success) {
                    refresh()
                    success('Документа беше върнат за плащане успешно!')
                }
            })
    }

    const handleAddPayment = id => {
        const modal = addPaymentModalRef.current
        modal.open(id)
        modal.onSuccess(() => {
            refresh()
            success()
        })
    }

    const handlePreview = (type, data) => {
        handleAddNewSubtab(type, data)
    }

    const handleSend = id => {
        let email = null

        if (!Array.isArray(id)) {
            const document = (state[typeId]?.data || []).find(d => Number(d.id) === Number(id))

            if (document) {
                email = document?.customerinfo?.email
            }
        }

        const modal = sendModalRef.current
        modal.open(id, email)
        modal.onSuccess(() => {
            success()
        })
        modal.onError(() => {
            error()
        })
    }

    const handleSendSelected = () => {
        const ids = Object.keys(localState.selected)

        if (ids.length) handleSend(ids)
    }

    const handleExport = id => {
        const modal = exportModalRef.current

        modal.open(id)
        modal.onSuccess(() => {
            success('Експортирането завърши успешно!')
        })
        modal.onError(() => {
            error()
        })
    }

    const handleExportSelected = () => {
        const ids = Object.keys(localState.selected)

        if (ids.length) handleExport(ids)
    }

    const handleConvertToProforma = id => {
        Api.post('documents/convert-to-proforma', {
            id
        })
            .then(() => {
                refresh(true)
            })
    }

    const handleConvertToInvoice = id => {
        Api.post('documents/convert-to-invoice', {
            id
        })
            .then(res => {
                refresh(true)

                handleChangeTabs(2, 0, 3)
                handleAddNewSubtab(1, res.data, false, 2, 0, 3)
            })
    }

    const handleConvertToCreditNote = id => {
        const subTabToBeActive = (Number(document.querySelector(`.subtabs li[data-type-id="${DOCUMENT_TYPE.CREDIT_NOTE}"] .count`)?.innerHTML) || 1) - 1
        Api.post('documents/convert-to-creditnote', {
            id
        })
            .then(res => {
                handleChangeTabs(3, subTabToBeActive, DOCUMENT_TYPE.CREDIT_NOTE)
                handleAddNewSubtab(3, res.data, false, 3, subTabToBeActive, DOCUMENT_TYPE.CREDIT_NOTE)
                refresh(true)
            })
    }

    const handleConvertToDebitNote = id => {
        const subTabToBeActive = (Number(document.querySelector(`.subtabs li[data-type-id="${DOCUMENT_TYPE.CREDIT_NOTE}"] .count`)?.innerHTML) || 1) - 1
        Api.post('documents/convert-to-debitnote', {
            id
        })
            .then(res => {
                handleChangeTabs(4, subTabToBeActive, DOCUMENT_TYPE.DEBIT_NOTE)
                handleAddNewSubtab(3, res.data, false, 4, subTabToBeActive, DOCUMENT_TYPE.DEBIT_NOTE)
                refresh(true)
            })
    }

    /**
     * @param {string} pdfUrl връзка към .pdf файла
     */
    const handlePrint = pdfUrl => {
        printJS({
            printable: pdfUrl,
            onLoadingStart: () => {
                handlePrintLoading(true)
            },
            onLoadingEnd: () => {
                handlePrintLoading(false)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    const handlePrintLoading = printLoading => {
        setLocalState(prev => ({
            ...prev,
            printLoading
        }))
    }

    const handleShowRepeatOptions = data => {
        const modal = repeatOptionsModalRef.current

        modal.open(data)
        modal.onSuccess(() => {
            refresh()
            success()
        })

        modal.onError(() => {
            error()
        })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }))
    }

    const clearFilter = () => context.clearFilter()

    /**
     * 
     * @param {object} document избрания документ 
     */
    const handleSelect = document => {
        const selected = cloneDeep(localState.selected)

        if (document) {
            if (selected[document.id]) {
                delete selected[document.id]
            } else {
                selected[document.id] = 1
            }
        }

        setLocalState(prev => ({
            ...prev,
            selected
        }))
    }

    const handleSelectAll = () => {
        const selected = {}

        if (!areAllSelected()) {
            (state[typeId]?.data || []).map(d => {
                selected[d.id] = 1
            })
        }

        setLocalState(prev => ({
            ...prev,
            selected
        }))
    }

    /**
     * 
     * @returns колко избрани елемента има
     */
    const getSelectedCount = () => Object.values(localState.selected).length

    /**
     * 
     * @returns дали всички елементи са избрани
     */
    const areAllSelected = () => getSelectedCount() === getDataCount()

    /**
     * 
     * @param {number} id id на документа 
     * @returns дали докуемнта се редактира
     */
    const isCurrentlyEdited = id => (context.documentsEditing[id] || false)

    /**
     * 
     * @param {number} id id на документа 
     * @returns масив с потребители, които редактират докуемнта
     */
    const getCurrentlyEditingUsers = id => {
        const users = context.documentsEditing[id]

        if (users) {
            return Object.values(users)
        }

        return []
    }

    /**
     * 
     * @param {number} id id на подраздела 
     * @returns елемента на подраздела в списъка с раздели
     */
    const getCurrentSubtab = id => {
        const subtab = document.querySelector(`.tabs .subtabs li[data-id="${id}"]`) || null

        return subtab
    }

    /**
     * 
     * @param {string} event събитието (leave, enter) 
     * @param {number} id id на подраздела 
     */
    const handleSubtabIndicator = (event, id) => {
        const subtab = getCurrentSubtab(id)

        if (subtab) {
            if (event === 'enter') subtab.classList.add('indicate')
            else if (event === 'leave') subtab.classList.remove('indicate')
        }
    }

    /**
     * 
     * @param {node} target елемента, върху който е кликнато 
     * @returns дали реда е кликаем
     */
    const isRowNotClickable = target => {
        let clickable = true

        // това не работи онлайн...

        //Този случай се ползва, когато кликнеш върху падащото меню с плащанията в "Отбелязване като платена". Ако я няма проверката дава грешка в по-долните редове.
        if (typeof target === 'object' && target.value) {
            return false
        }

        if (target) {
            if (
                target.classList?.contains('MuiButtonBase-root') ||
                (target?.getAttribute('aria-hidden') === 'true') ||
                (document.querySelector('body').getAttribute('style') === 'overflow: hidden;') ||
                target?.classList?.contains('paid-indicator') ||
                document.querySelector('.popover-complexed')
            ) {
                clickable = false
            }
        }

        return clickable
    }

    /**
     * 
     * @param {number} id id на документа 
     */
    const handleSuccessTooltip = (id, message) => {
        setLocalState(prev => ({
            ...prev,
            successTooltip: id,
            successMessage: message,
        }))
        setTimeout(() => {
            setLocalState(prev => ({
                ...prev,
                successTooltip: false,
                message: ''
            }))
        }, 2000)
    }

    /**
     * 
     * @param {number} langId id на езика 
     */
    const download = pdfUrl => {
        window.open(pdfUrl)
    }

    /**
     * 
     * @param {number} count броя избрани документи, според който се определя дали ще се тегли .pdf (ако е 1 документ) или .zip (ако са повече от 1 документ) 
     * @returns прекъсва кода надолу
     */
    const handleDownloadSelected = count => {
        if (count === 1) {
            const id = Object.keys(localState.selected)[0] || null

            const downloadPdfUrl = document.querySelector(`table.type-outer tr[data-id="${id}"]`).getAttribute('data-download-pdf-url')
            window.open(downloadPdfUrl)

            return
        }
        const ids = Object.keys(localState.selected)

        if (ids.length) {
            let url = `${APP_URL}documents/download?`
            ids.map((id, i) => {
                url += `${i === 0 ? '' : '&'}ids[]=${id}`
            })

            url += `&token=${auth.getToken()}`

            window.open(url, '_blank')
        }
    }

    const handlePrinttSelected = () => {
        //Все още не е направена. documents/print съм си го измислил.
        const ids = Object.keys(localState.selected)

        if (ids.length) {
            let url = `${APP_URL}documents/bulk-print?`
            ids.map((id, i) => {
                url += `${i === 0 ? '' : '&'}ids[]=${id}`
            })

            url += `&token=${auth.getToken()}`

            handlePrint(url)
        }
    }

    /**
     * 
     * @returns колко документи има в таблицата
     */
    const getDataCount = () => (state[typeId]?.data?.length || 0)

    const handleDublicate = id => {
        app.handleInfo('Издаване на дубликат')
        Api.get(`documents/duplicate?id=${id}`)
            .then(res => {
                handleSuccessTooltip(id, 'Документа беше дублиран.')
                success('Документа беше дублиран.')

                // context.handleEditInvoice(res.data)
                handleAddNewSubtab(3, res.data)
            })
            .catch(() => {
                app.handleError()
            })
            .finally(() => {
                app.handleHideInfo()
            })
    }

    /**
     * 
     * @param {object} data данните, който се заместват в реда на таблицата, когато някакво плащане бъде извършено по документ
     */
    const appendDataFromPaymentReq = data => {
        const items = state[typeId]?.data.map(d => {
            if (d.id === data.id) {
                d = data
            }
            return d
        })

        setState(prev => ({
            ...prev,
            [typeId]: {
                ...prev[typeId],
                data: items,
            }
        }))
    }

    /**
     * връща описателния текст, когато няма намерен резултат
     * @returns {string}
     */
    const generateNoResultsInfoText = () => {
        const { filter } = state
        const hasSearch = () => (filter?.search || '')?.length
        const hasSent = () => (filter?.sent || '')?.length
        const hasPeriod = () => (filter?.period || '')?.length
        const hasStatus = () => (filter?.status || '')?.length
        const hasPaymentMethod = () => typeof filter?.payment_method_id === 'number'
        const hasType = () => ((Number(filter?.type_id) !== Number(typeId)) && filter?.type_id?.length)

        /**
         * дали има маркиран филтър, какъвто и да е той
         * @returns {boolean}
         */
        const areAnyFiltersApplied = () => {
            if (hasSearch()
                || hasSent()
                || hasPeriod()
                || hasStatus()
                || hasPaymentMethod()
                || hasType()
            ) {
                return true
            }

            return false
        }

        return `${areAnyFiltersApplied() ? `Не бяха открити документи ${hasSearch() ? `с тези ключови думи${hasPaymentMethod() || hasType() || hasPeriod() || hasSent() ? ',' : ''}` : ''}${hasSent() ? ` с този статус${hasPaymentMethod() || hasType() || hasPeriod() ? ',' : ''}` : ''}${hasPeriod() ? ` за този период${hasPaymentMethod() || hasType() ? ',' : ''}` : ''}${hasPaymentMethod() ? ` с такъв метод на плащане ${hasType() ? ',' : ''} ` : ''}${hasType() ? ` от този тип` : ''}.` : 'Все още няма запазени документи в този списък.'} ${activeTabInfo.notFoundText}`.replace(/ +(?= )/g, '').replace(' .', '.')
    }
    return (
        <>
            {/* <PageLoader show={state.loading} /> */}
            {getSelectedCount() ?
                <MultipleActions
                    selectedCount={getSelectedCount()}
                    handleSendSelected={handleSendSelected}
                    handleExportSelected={handleExportSelected}
                    handleDownloadSelected={handleDownloadSelected}
                    handlePrinttSelected={handlePrinttSelected}
                    printLoading={localState?.printLoading}
                />
                : ''
            }
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Question
                ref={makeUnpaidModalRef}
                mainMessage="Върни за плащане"
                secondaryMessage="След Вашето потвърждение, всички плащания към документа ще бъдат премахнати от системата."
                agreeBtnText="Продължете"
            />

            <MakePaid
                ref={makePaidModalRef}
            />

            <AddPayment
                ref={addPaymentModalRef}
            />

            <Send
                ref={sendModalRef}
                documentsType="documents"
            />

            <Export
                ref={exportModalRef}
                typeId={typeId}
            />

            <RepeatOptions
                ref={repeatOptionsModalRef}
            />

            <section id="documents">
                {!auth.getUser().getCompany()
                    ?
                    <NoSelectedCompany />
                    :
                    <div className="panel table-panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                            clearFilter={clearFilter}
                            typeId={typeId}
                        />
                        {state.loading
                            ?
                            <Skeleton
                                count={5}
                                height={60}
                            />
                            :
                            getDataCount() === 0 ?
                                <EmptyList
                                    infoText={generateNoResultsInfoText()}
                                    btnText={activeTabInfo.name}
                                />
                                :
                                <>
                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Tooltip
                                                        title="Маркиране на всички документи от тази страница"
                                                    >
                                                        <Checkbox
                                                            checked={areAllSelected()}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </Tooltip>
                                                </th>
                                                <th>
                                                    Тип
                                                </th>
                                                <th>
                                                    Получател
                                                </th>
                                                <th>
                                                    Номер
                                                </th>
                                                <th>
                                                    Дата
                                                </th>
                                                <th className="text-center">
                                                    Периодичен
                                                </th>
                                                <th className="text-right">
                                                    Сума
                                                </th>
                                                <th className="text-right">
                                                    ДДС
                                                </th>
                                                <th className="text-right">
                                                    Общо
                                                </th>
                                                <th className="text-right">
                                                    За плащане
                                                </th>
                                                <th className="text-center">
                                                    Платено
                                                </th>
                                                <th className="text-center">
                                                    Опции
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state[typeId]?.data.map(d =>
                                                <tr
                                                    key={'doc-' + d.id}
                                                    className={isCurrentlyEdited(d.id) ? 'editing' : ''}
                                                    // onClick={e => {
                                                    //     if (isRowNotClickable(e.target)) handleAddNewSubtab(1, d)
                                                    // }}
                                                    onMouseEnter={() => {
                                                        handleSubtabIndicator('enter', d.id)
                                                    }}
                                                    onMouseLeave={() => {
                                                        handleSubtabIndicator('leave', d.id)
                                                    }}
                                                    data-id={d.id}
                                                    data-download-pdf-url={d?.download_pdf_url || ''}
                                                >
                                                    <td>
                                                        <Tooltip
                                                            title={`Маркиране на ${d.type?.translation?.name.toLowerCase()}`}
                                                        >
                                                            <Checkbox
                                                                id={`document-cb-${d.id}`}
                                                                checked={localState.selected[d.id] ? true : false}
                                                                color="primary"
                                                                onClick={() => {
                                                                    handleSelect(d)
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            handleAddNewSubtab(1, d)
                                                        }}
                                                    //показване на менюто при right click
                                                    // onContextMenu={e => {
                                                    //     console.log(`X: ${e.clientX}`)
                                                    //     console.log(`Y: ${e.clientY}`)
                                                    //     e.preventDefault()

                                                    //     document.querySelector(`.options-holder .options-menu-button[data-id="${d.id}"]`).click()

                                                    //     const paper = document.querySelector('.custom-menu-options .MuiPopover-paper')
                                                    //     paper.style.visibility = 'hidden'
                                                    //     setTimeout(() => {
                                                    //         // paper.style.visibility = 'visible'
                                                    //         paper.style.left = `${e.clientX}px`
                                                    //         paper.style.top = `${e.clientY}px`
                                                    //     }, 300)
                                                    // }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <span>{d.type?.translation?.name}</span>
                                                            {getCurrentlyEditingUsers(d.id).map(user =>
                                                                <Tooltip
                                                                    key={'u-' + user.id}
                                                                    title={`Този документ в момента се редактира от ${user.name}`}
                                                                >
                                                                    <img src={user.avatar} alt="" width={20} style={{ marginLeft: '5px' }} />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)}>
                                                        {d?.customerinfo?.name || ''}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)}>
                                                        {d.no}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)}>
                                                        {d.date_dmy}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)} className="text-center">
                                                        {d.is_repeat
                                                            ?
                                                            <img src={checkIcon} alt="" height={12} />
                                                            :
                                                            '-'
                                                        }
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)} className="text-right price">
                                                        {d.net_price_formatted} {d?.currency?.name}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)} className="text-right">
                                                        {d.vat_price_formatted} {d?.currency?.name}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)} className="text-right price">
                                                        {d.gross_price_formatted} {d?.currency?.name}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(1, d)} className="text-right price">
                                                        {d.unpaid_price_formatted} {d?.currency?.name}
                                                    </td>
                                                    <td className="text-center">
                                                        <Config
                                                            doc={d}
                                                            handleMakeUnpaid={handleMakeUnpaid}
                                                            refresh={refresh}
                                                            appendData={appendDataFromPaymentReq}
                                                        />
                                                    </td>

                                                    <Tooltip
                                                        title={localState.successMessage}
                                                        open={localState.successTooltip === d.id ? true : false}
                                                    >
                                                        <td className="text-center">
                                                            <div className="options-holder">
                                                                <Tooltip
                                                                    title="Изпращане на документа"
                                                                >
                                                                    <Button
                                                                        className="options-menu-button"
                                                                        onClick={() => {
                                                                            handleSend(d.id)
                                                                        }}
                                                                    >
                                                                        <img src={mailIcon} />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title="Сваляне на документа"
                                                                >
                                                                    <Button
                                                                        className="options-menu-button"
                                                                        onClick={() => {
                                                                            download(d.download_pdf_url)
                                                                        }}
                                                                    >
                                                                        <img src={downloadIcon} />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title="Принтиране на документа"
                                                                >
                                                                    <Button
                                                                        className="options-menu-button"
                                                                        onClick={() => {
                                                                            handlePrint(d.print_url)
                                                                        }}
                                                                    >
                                                                        <img src={printIcon} className={localState?.printLoading ? 'disabled' : ''} />
                                                                    </Button>
                                                                </Tooltip>
                                                                <MenuOptions
                                                                    id={d.id}
                                                                >
                                                                    <Options
                                                                        data={d}
                                                                        handleAddNewSubtab={handleAddNewSubtab}
                                                                        activeSubtabIndex={activeSubtabIndex}
                                                                        handleDelete={handleDelete}
                                                                        handleMakePaid={handleMakePaid}
                                                                        handleMakeUnpaid={handleMakeUnpaid}
                                                                        handleAddPayment={handleAddPayment}
                                                                        handleSend={handleSend}
                                                                        handleExport={handleExport}
                                                                        handlePreview={handlePreview}
                                                                        handleConvertToProforma={handleConvertToProforma}
                                                                        handleConvertToInvoice={handleConvertToInvoice}
                                                                        handleConvertToCreditNote={handleConvertToCreditNote}
                                                                        handleConvertToDebitNote={handleConvertToDebitNote}
                                                                        handleShowRepeatOptions={handleShowRepeatOptions}
                                                                        handleSuccessCopied={handleSuccessTooltip}
                                                                        handlePrint={handlePrint}
                                                                        handleDublicate={handleDublicate}
                                                                        handleDownload={pdfUrl => {
                                                                            download(pdfUrl)
                                                                        }}
                                                                    />
                                                                </MenuOptions>
                                                            </div>
                                                        </td>
                                                    </Tooltip>
                                                </tr>
                                            )}
                                            <AdditionalOptions
                                                typeId={typeId}
                                                handleExport={handleExport}
                                            />
                                        </tbody>
                                    </table>
                                    <Pagination
                                        page={state.filter.page}
                                        pages={state[typeId].pages}
                                        handlePage={handlePage}
                                    />
                                </>
                        }
                    </div>
                }
            </section>
        </>
    )
}

export default Index

import { useState } from 'react'

import ClickAwayListener from 'react-click-away-listener'

//constants
import { PERIOD_FILTER_TYPES } from 'constants/Document'

//Plugins
import moment from "moment"

import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

//helpers
import { useQuery } from 'helpers/Url'

//images
import prevYearIcon from 'assets/img/documents/filter/icons/prev-year.svg'
import currentWeekIcon from 'assets/img/documents/filter/icons/current-week.png'
import currentMonthIcon from 'assets/img/documents/filter/icons/current-month.png'
import lastMonthIcon from 'assets/img/documents/filter/icons/last-month.png'
import lastThreeMonthsIcon from 'assets/img/documents/filter/icons/last-three-months.png'
import lastSixMonthsIcon from 'assets/img/documents/filter/icons/last-six-months.png'
import lastYearIcon from 'assets/img/documents/filter/icons/last-year.png'
import customPeriodIcon from 'assets/img/documents/filter/icons/custom-period.png'

const Period = props => {
    const {
        period,
        dates,
        handleSearch
    } = props
    const {
        start_date,
        end_date
    } = dates
    const query = useQuery()

    const [state, setState] = useState({
        periodFilter: {
            visible: false,
            dateRangeVisible: false,
        }
    })

    const handlePeriodFilterVisibility = () => {
        setState(prev => ({
            ...prev,
            periodFilter: {
                ...prev.periodFilter,
                visible: !prev.periodFilter.visible,
                dateRangeVisible: false
            }
        }))
    }

    /**
     * избор на фиксиран период
     * @param {number} value 
     */
    const handleChangePeriod = value => {
        handleSearch('period', value)
        handleSearch('start_date', '')
        handleSearch('end_date', '')
        handlePeriodFilterVisibility()
    }


    /**
     * конфигурация на перосанализиран период от изкачащ календар
     * има опция за избор на дата ОТ - ДО
     * @param {number} value 
     */
    const handleChangeRange = dateRange => {
        handleSearch('start_date', moment(dateRange.selection.startDate).format('YYYY-MM-DD'))
        handleSearch('end_date', moment(dateRange.selection.endDate).format('YYYY-MM-DD'))
        handleSearch('period', '')
    }

    const handleDateRangeVisible = () => {
        setState(prev => ({
            ...prev,
            periodFilter: {
                ...prev.periodFilter,
                dateRangeVisible: !prev.periodFilter.dateRangeVisible
            }
        }))
    }

    /**
     * връща периода ОТ
     * @returns {dates}
     */
    const getStartDate = () => moment(start_date || getSelectedStartDate() || new Date()).toDate()

    /**
     * връща периода ДО
     * @returns {dates}
     */
    const getEndDate = () => moment(end_date || getSelectedEndDate() || new Date()).toDate()

    const getSelectedStartDate = () => query.get('start_date')
    const getSelectedEndDate = () => query.get('end_date')

    function getSelectedPeriod() {
        if (getSelectedStartDate() && getSelectedEndDate()) {
            return `${moment(getSelectedStartDate(), 'YYYY-MM-DD').format('DD.MM.YYYY')} - ${moment(getSelectedEndDate(), 'YYYY-MM-DD').format('DD.MM.YYYY')}`
        }

        return null
    }

    return (
        <div className="col">
            <div
                className="custom-filter-select period"
                onClick={e => {
                    if (!state.periodFilter.visible && !e.target.classList.contains('custom-period')) handlePeriodFilterVisibility()
                }}
            >
                <div className="option">
                    {PERIOD_FILTER_TYPES[period] || getSelectedPeriod() || 'Изберете период'}
                </div>
                {state.periodFilter.visible ?
                    <ClickAwayListener
                        onClickAway={handlePeriodFilterVisibility}
                    >
                        <div className="period-options">
                            <ul className="first-options">
                                <li
                                    onClick={() => {
                                        handleChangePeriod('prev-month')
                                    }}
                                >
                                    <img src={lastMonthIcon} />
                                    {PERIOD_FILTER_TYPES['prev-month']}
                                </li>
                                {/* <li
                                    onClick={() => {
                                        handleChangePeriod('this-week')
                                    }}
                                >
                                    <img src={currentWeekIcon} />
                                    {PERIOD_FILTER_TYPES['this-week']}
                                </li> */}
                                <li
                                    onClick={() => {
                                        handleChangePeriod('this-month')
                                    }}
                                >
                                    <img src={currentMonthIcon} />
                                    {PERIOD_FILTER_TYPES['this-month']}
                                </li>
                            </ul>
                            <ul className="second-options">
                                <li
                                    onClick={() => {
                                        handleChangePeriod('prev-year')
                                    }}
                                >
                                    <img src={prevYearIcon} />
                                    {PERIOD_FILTER_TYPES['prev-year']}
                                </li>
                                <li
                                    onClick={() => {
                                        handleChangePeriod('last-3-months')
                                    }}
                                >
                                    <img src={lastThreeMonthsIcon} />
                                    {PERIOD_FILTER_TYPES['last-3-months']}
                                </li>
                                <li
                                    onClick={() => {
                                        handleChangePeriod('last-6-months')
                                    }}
                                >
                                    <img src={lastSixMonthsIcon} />
                                    {PERIOD_FILTER_TYPES['last-6-months']}
                                </li>
                                <li
                                    onClick={() => {
                                        handleChangePeriod('last-year')
                                    }}
                                >
                                    <img src={lastYearIcon} />
                                    {PERIOD_FILTER_TYPES['last-year']}
                                </li>
                            </ul>
                            <span
                                className="custom-period"
                                onClick={handleDateRangeVisible}
                            >
                                <img src={customPeriodIcon} />
                                Избор на период
                            </span>
                            {state.periodFilter.dateRangeVisible ?
                                <DateRangePicker
                                    ranges={[{
                                        startDate: getStartDate(),
                                        endDate: getEndDate(),
                                        key: 'selection'
                                    }]}
                                    onChange={handleChangeRange}
                                />
                                :
                                <>
                                </>
                            }
                        </div>
                    </ClickAwayListener>
                    :
                    <>
                    </>
                }
            </div>
        </div>
    )
}

export default Period

import { useEffect, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//helpers
import { useQuery } from 'helpers/Url'

//MUI components
import { Checkbox, FormControlLabel } from '@material-ui/core'

//constants
import { DOC_FILTER_TYPES, DOCUMENT_TYPE } from 'constants/Document'

const ACCOUNTING_SERVICE_TYPE_IDS = [ DOCUMENT_TYPE.INVOICE, DOCUMENT_TYPE.CREDIT_NOTE, DOCUMENT_TYPE.DEBIT_NOTE ]

const DocType = props => {
    const query                       = useQuery()
    const { handleSearch, typeIds, typeId }   = props

    const [state, setState] = useState({
        visible   : false,
        initial   : null
    })
    const handleVisibilty = visible => {
        setState(prev => ({
            ...prev,
            visible
        }))
    }

    /**
     * зачиства избраните филтри.
     * ще прези, ако трябва да се направи филтъра да помни при смяна на табове
     */
    useEffect(() => {
        handleSearch('type_id', String(typeId))
    }, [typeId])

    /**
     * връща type id-тата от GET параметъра като масив с цел премахване/добавяне от/към масива и презаписване на GET параметъра
     * в случай, че в параметъра бъде подаден масив, той бива конвертирън към масив, за да няма грешка при метода split()
     * @param {string} url 
     * @returns {array}
     */
    const getTypeIdsFromparam = url => {
        if (Array.isArray(url)) url.join()

        return url?.split(',') || []
    }

    /**
     * дали checkbox е маркиран
     * @param {number} typeId 
     * @returns {boolean}
     */
    const isChecked = typeId => {
        const url   = typeIds || query.get('type_id') || ''
        if (getTypeIdsFromparam(url).includes(String(typeId))) {
            return true
        }

        return false
    }

    /**
     * масив от маркираните type id-та
     * @returns {array}
     */
    const getInitialQueryArray = () => {
        const queryString   = typeIds || query.get('type_id') || ''
        if (!queryString.length) {
            return []
        }
        const queryArray    = queryString.split(',')

        return queryArray
    }

    /**
     * дали елемента да е първончално-маркиран
     * @param {number} typeId 
     * @returns {boolean}
     */
    const isDefaultChecked = typeId => {
        if (getInitialQueryArray().includes(typeId)) {
            return true
        }

        return false
    }

    const handleChange = e => {
        let url           = typeIds || query.get('type_id') || ''
        const { value }   = e.target

        if (isChecked(value)) {
            const queryArray = getTypeIdsFromparam(url).filter(u => {
                return Number(u) !== Number(value)
            })

            url           = queryArray.join()
        } else {
            url           += url.length ? `,${value}` : value
        }

        handleSearch('type_id', url)
        setInitial(new Date().getTime())
    }

    const setInitial = initial => {
        setState(prev => ({
            ...prev,
            initial
        }))
    }

    /**
     * връща текста, който се визуализира в полето над списъка с типове документи
     * @returns {string}
     */
    const generateText = () => {
        let text                = ''
        const arrayOfTypeIds    = getTypeIdsFromparam(typeIds || query.get('type_id') || '')
        if (arrayOfTypeIds.length > 1) {
            text                = `${DOC_FILTER_TYPES[arrayOfTypeIds[0]]?.shortName} + ${arrayOfTypeIds.length - 1 === 1 ? `1 друг` : `${arrayOfTypeIds.length - 1} други`}`
        } else {
            text                = DOC_FILTER_TYPES[arrayOfTypeIds[0]]?.shortName || 'Типове документи'
        }

        return text
    }

    const handleApplyAll = () => {
        handleSearch('type_id', areAllChecked() ? String(typeId) : getAllTypeIdsAsString())
    }

    const handleApplyAccounting = () => {
        handleSearch('type_id', isAccountingChecked() ? String(typeId) : getAccountingTypeIdsAsString())
    }

    /**
     * връща type id, които се използват за счетоводната опция
     * @returns {boolean}
     */
    const getAccountingTypeIdsAsString = () => ACCOUNTING_SERVICE_TYPE_IDS?.join() || ''

    /**
     * връща всички type id под формата на стринг и разделени със запетая
     * @returns {boolean}
     */
    const getAllTypeIdsAsString = () => Object.keys(DOC_FILTER_TYPES)?.join() || ''

    /**
     * дали всички checkbox са маркирани
     * @returns {boolean}
     */
    const areAllChecked = () => {
        if (getInitialQueryArray().length === Object.keys(DOC_FILTER_TYPES).length) {
            return true
        }

        return false
    }

    /**
     * дали счетоводната опция е маркирана
     * @returns {boolean}
     */
    const isAccountingChecked = () => {
        let arrComparingSuccess       = true
        ACCOUNTING_SERVICE_TYPE_IDS.map(a => {
            if (!arrComparingSuccess) return

            if (!isChecked(a)) {
                arrComparingSuccess   = false
            }
        })
        if ((ACCOUNTING_SERVICE_TYPE_IDS.length === getInitialQueryArray().length) && arrComparingSuccess) {
            return true
        }

        return false
    }

    /**
     * връща дали това е текущия тип документи
     * @param {string|number} tId 
     * @returns {boolean}
     */
    const isCurrentTypeId = tId => Number(typeId) === Number(tId)

    return (
        <div className="col">
            <div
                className   = "custom-filter-select doc-type"
                onClick={() => {
                    handleVisibilty(true)
                }}
            >
                <div className="option">
                    {generateText()}
                </div>
                {state.visible ?
                    <ClickAwayListener
                        onClickAway={() => {
                            handleVisibilty(false)
                        }}
                    >
                        <div className="doc-type-options">
                            <ul className="all">
                                <li>
                                    <FormControlLabel
                                        control       = {<Checkbox
                                            style     = {{ padding: 0, marginRight: '5px' }}
                                            checked   = {areAllChecked()}
                                            onChange  = {handleApplyAll}
                                        />}
                                        label         = "Всички"
                                    />
                                </li>
                            </ul>
                            <ul className="first-options">
                                {Object.values(DOC_FILTER_TYPES).map((d, i) =>
                                    <li
                                        key={d.name}
                                    >
                                        <FormControlLabel
                                            control       = {<Checkbox
                                                style     = {{ padding: 0, marginRight: '5px' }}
                                                checked   = {isCurrentTypeId(Object.keys(DOC_FILTER_TYPES)[i]) ? true :  !state.initial ? isDefaultChecked(Object.keys(DOC_FILTER_TYPES)[i]) : isChecked(Object.keys(DOC_FILTER_TYPES)[i])}
                                                onChange  = {handleChange}
                                                value     = {Object.keys(DOC_FILTER_TYPES)[i]}
                                                name      = "type_id"
                                                disabled  = {isCurrentTypeId(Object.keys(DOC_FILTER_TYPES)[i])}
                                                className = {isCurrentTypeId(Object.keys(DOC_FILTER_TYPES)[i]) ? 'disabled' : ''}
                                            />}
                                            label         = {d.name}
                                        />

                                    </li>
                                )}
                            </ul>
                            {Number(typeId) !== 2 ?
                                <ul className="accounting">
                                    <li>
                                        <FormControlLabel
                                            control={<Checkbox
                                                style={{ padding: 0, marginRight: '5px' }}
                                                checked={isAccountingChecked()}
                                                onChange={handleApplyAccounting}
                                            />}
                                            label={`${DOC_FILTER_TYPES[ACCOUNTING_SERVICE_TYPE_IDS[0]].name}, ${DOC_FILTER_TYPES[ACCOUNTING_SERVICE_TYPE_IDS[1]].name} и ${DOC_FILTER_TYPES[ACCOUNTING_SERVICE_TYPE_IDS[2]].name}`}
                                        />
                                    </li>
                                </ul>
                                :
                                <>
                                </>
                            }
                        </div>
                    </ClickAwayListener>
                    :
                    <>
                    </>
                }
            </div>
        </div>
    )
}

export default DocType
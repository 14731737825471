import { forwardRef, useImperativeHandle, useMemo, useRef, useEffect, useState } from 'react'

//plugins
import moment from 'moment'
import { cloneDeep } from 'lodash'

//helpers
import { useNestedState } from 'helpers/NestedState'
import { useValidation } from 'helpers/Validation'
import Api from 'helpers/Api'

//MUI components
import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core'

//misc
import DatePicker from 'components/misc/DatePicker'
import SaveButton from 'components/misc/Button'
import TextField from 'components/misc/TextField'
import RedditTextField from 'components/misc/RedditTextField'
import Autocomplete from 'components/misc/Autocomplete'

//providers
import { useCurrencyContext } from 'providers/Currency'
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

//images
import deleteIcon from 'assets/img/app/icons/delete.png'
import Category from './Category'
import { translateToNumberField } from 'components/pages/settings/partials/categories/expenses/helpers/amountField'

function AddOrEdit({ handleTab }, ref) {
    const sumFieldRef = useRef()

    const auth = useAuthDataContext()
    const app = useAppContext()
    const currencies = useCurrencyContext()
    const currenciesRef = useRef()
    const companyId = auth.getUser()?.getCompany().getId()
    const [validations, setValidations] = useValidation()

    const data = cloneDeep(useMemo(() => {
        return {
            date_ymd: moment().utc().format('YYYY-MM-DD'),
            amount: '',
            currency_id: currencies.data.find(c => c.default)?.id || null,
            files: [],
            category: '',
            description: '',
            customer_name: '',
            customer: '',
        }
    }, []))

    const INITIAL_STATE = {
        overlay: false,
        modal: false,
        onSuccess: null,
        data,
        showMenu: {
            category: false,
            currency: false,
        },
    }

    const [state, setState] = useState(INITIAL_STATE)

    useImperativeHandle(ref, () => ({
        add: () => {
            handleModal()._show()
        },
        edit: id => {
            handleModal()._show(id)
        },
        hide: () => {
            handleModal()._hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        if (state.modal) sumFieldRef.current.focus()
    }, [state.modal])

    const handleModal = () => {
        const _show = id => {

            setState(prev => ({
                ...prev,
                overlay: true,
                id
            }))
            if (id) loadData(id)

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    modal: true,
                }))
            }, 50)
        }

        const _hide = () => {
            setState(prev => ({
                ...prev,
                modal: false,
            }))

            setTimeout(() => {
                setState(INITIAL_STATE)
                setValidations({})
            }, 150)
        }

        return {
            _hide,
            _show
        }
    }
    console.log(state)
    const loadData = id => {
        if (!id) return

        Api.get(`expenses/show?id=${id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
    }

    const handleForm = () => {
        const _save = e => {
            e.preventDefault()
            if (!e.target.classList.contains('add-form')) return
            setLoading(true)
            setValidations({})

            const data = new FormData(e.target)
            const url = state.id ? 'expenses/edit' : 'expenses/add'
            if (state.id) data.append('id', state.id)
            if (companyId) data.append('company_id', companyId)
            state.data.files.map(f => {
                if (f.was_recently_attached) data.append('files[]', f)
            })

            Api.post(url, data)
                .then(res => {
                    if (typeof state.onSuccess === 'function') state.onSuccess(res.data)
                    app.handleSuccess('Разхода беше добавен успешно!')
                    handleModal()._hide()
                    handleTab(null, 'expenses')
                })
                .catch(err => {
                    const _err = err.response
                    if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        const _validate = () => {

        }

        const _change = () => {
            const _date = e => {
                const { name, value } = e.target

                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        [`${name}_ymd`]: value
                    }
                }))
            }

            const _fields = e => {
                const { name, value } = e.target
                if (name === 'amount') {
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            [name]: translateToNumberField(value)
                        }
                    }))

                    return
                }
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        [name]: value
                    }
                }))
            }

            /**
             * 
             * @param {Number} currencyId 
             */
            const _currencyId = currencyId => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        currency_id: currencyId
                    }
                }))
            }

            return {
                _date,
                _fields,
                _currencyId
            }
        }

        const _format = () => {
            const _price = () => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        amount: state.data.amount
                    }
                }))
            }

            return {
                _price
            }
        }

        const _files = () => {
            const _attach = e => {
                let files = [...e.target.files]

                files.forEach((f, i) => {
                    const url = URL.createObjectURL(f)

                    files[i].id = Math.random().toString(7).substring(2)
                    files[i].was_recently_attached = true
                    files[i].url = url
                })

                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        files: [...prev.data.files, ...files]
                    }
                }))
            }

            const _detach = id => {
                const removeFile = () => {
                    const files = state.data.files.filter((f => Number(f.id) !== Number(id)))
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            files
                        }
                    }))
                }

                const file = state.data.files.filter(f => Number(f.id) === Number(id))[0]
                console.log(id)
                if (!file.was_recently_attached) {
                    Api.post('incomes/remove-file', {
                        id
                    })
                        .then(res => {
                            if (res.data.success) removeFile()
                        })
                } else removeFile()
            }

            return {
                _attach,
                _detach
            }
        }

        return {
            _save,
            _validate,
            _change,
            _format,
            _files
        }
    }

    const getCurrencyName = useMemo(() => {
        return currencies.data.find(d => d.id === state.data.currency_id).name
    }, [state.data.currency_id, currencies.data])

    const getCurrenciesList = useMemo(() => {
        return currencies.data.map(d =>
            <MenuItem
                key={`currency-${d.id}`}
                onClick={() => {
                    handleForm()._change()._currencyId(d.id)
                    handleMenu()._currency(false)
                }}
            >
                {d.name}
            </MenuItem>
        )
    }, [currencies.data])

    const handleMenu = () => {
        /**
         * 
         * @param {Boolean} show 
         */
        const _category = show => {
            _set('category', show)
        }

        /**
         * 
         * @param {Boolean} show 
         */
        const _currency = show => {
            _set('currency', show)
        }

        /**
         * 
         * @param {String} key 
         * @param {Boolean} value 
         */
        const _set = (key, value) => {
            setState(prev => ({
                ...prev,
                showMenu: {
                    ...prev.showMenu,
                    [key]: value
                }
            }))
        }

        return {
            _category,
            _currency,
        }
    }

    const handleFocusFields = (e, type) => {
        const { name, value } = e.target
        const target = document.querySelector(`.expense-overlay [data-name="${name}"]`)
        if (value.length) {
            target.classList.remove('shorten')

            return
        }

        if (target.classList.contains('shorten')) target.classList.remove('shorten')
        else {
            if (type === 'focus') {
                target.classList.remove('shorten')

                return
            }
            if (value.length) target.classList.remove('shorten')
            else target.classList.add('shorten')
        }
    }

    function setLoading(loading) {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} scrollable overlay expense-overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary medium  add-form`} noValidate onSubmit={handleForm()._save}>
                    <div className="income-head">
                        <h2>
                            Добавяне на Разход
                        </h2>
                        <p>
                            Добавете Вашият разход по контрагент и категория
                        </p>
                    </div>

                    <div className="income-body">
                        <div className="row">
                            <div className="col">
                                <DatePicker
                                    name="date"
                                    value={state.data.date_ymd}
                                    onChange={handleForm()._change()._date}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col"
                                style={{
                                    maxWidth: '160px'
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    name="amount"
                                    type="text"
                                    min="0"
                                    step="0.01"
                                    label="Сума"
                                    // placeholder="Сума"
                                    value={state.data.amount}
                                    onChange={handleForm()._change()._fields}
                                    onBlur={handleForm()._format()._price}
                                    error={Boolean(validations && validations.amount)}
                                    helperText={validations && validations.amount && (validations.amount[0] || validations.amount)}
                                    inputRef={sumFieldRef}
                                    autoComplete="off"
                                />
                                <Button
                                    className="currency"
                                    onClick={() => {
                                        handleMenu()._currency(true)
                                    }}
                                    ref={currenciesRef}
                                >
                                    {getCurrencyName}
                                </Button>
                                <input type="hidden" name="currency_id" value={state.data.currency_id} />
                                <Menu
                                    anchorEl={currenciesRef.current}
                                    keepMounted
                                    open={state.showMenu.currency}
                                    onClose={() => {
                                        handleMenu()._currency(false)
                                    }}
                                >
                                    {getCurrenciesList}
                                </Menu>
                            </div>
                            <div className="col">
                                {state.modal || state.overlay ?
                                    <Category
                                        name="product"
                                        productIdName="product_id"
                                        value={state.data.product?.translation?.name || state.data.product?.name || ''}
                                        onChange={handleForm()._change()._fields}
                                        error={Boolean(validations && validations.product)}
                                        helperText={validations && validations.product && (validations.product[0] || validations.product)}
                                        onSelect={product => {
                                            console.log(product)
                                        }}
                                        companyId={companyId}
                                        productId={state.data.product?.id || null}
                                    />
                                    :
                                    <>
                                    </>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <Tooltip title="Добавяне на описание" placement="bottom-start">
                                <div className={`col shorten ${state.data?.description?.length ? 'not-shorten' : ''}`} data-name="description">
                                    <RedditTextField
                                        variant="outlined"
                                        fullWidth
                                        label="Описание"
                                        name="description"
                                        value={state.data.description}
                                        multiline
                                        rows="2"
                                        onChange={handleForm()._change()._fields}
                                        onFocus={e => {
                                            handleFocusFields(e, 'focus')
                                        }}
                                        onBlur={e => {
                                            handleFocusFields(e, 'blur')
                                        }}
                                        error={Boolean(validations && validations.description)}
                                        helperText={validations && validations.description && (validations.description[0] || validations.description)}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Tooltip title="Добавяне на контрагент" placement="bottom-start">
                                    <div className={`col shorten ${state.data?.customer ? 'not-shorten' : ''}`} data-name="customer_name" key={state.overlay}>
                                        <Autocomplete
                                            variant="outlined"
                                            fullWidth
                                            inputPlaceholder="Контрагент"
                                            inputName="customer_name"
                                            inputIdName="customer_id"
                                            url="customers/all"
                                            selected={state.data?.customer || null}
                                            freeSolo={false}
                                            getOptionLabel={option => option?.info?.name || option?.name || ''}
                                            renderOption={option => option?.info?.name || option?.name || ''}
                                            onInputFocus={e => {
                                                handleFocusFields(e, 'focus')
                                            }}
                                            onInputBlur={e => {
                                                handleFocusFields(e, 'blur')
                                            }}
                                            error={Boolean(validations && validations.customer_name)}
                                            helperText={validations && validations.customer_name && (validations.customer_name[0] || validations.customer_name)}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="col">
                                <div className="upload">
                                    {/* <img src={uploadIcon} /> */}
                                    <div className="right">
                                        <h6>
                                            {state.data?.files[0] ?
                                                'Качване на още файлове'
                                                :
                                                'Качване на файлове'
                                            }
                                        </h6>

                                        <p>
                                            Допустими формати: .pdf
                                        </p>
                                    </div>
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        name="files"
                                        onChange={handleForm()._files()._attach}
                                        multiple
                                    />
                                </div>
                                <ul
                                    className="uploaded-files"
                                >
                                    {(state.data?.files || []).map(f =>
                                        <li
                                            key={'f-' + f.id}
                                            className="row"
                                        >
                                            <a
                                                href={f.url}
                                                target="_blank"
                                            >
                                                {f.name}
                                            </a>
                                            <div
                                                onClick={() => {
                                                    handleForm()._files()._detach(f.id)
                                                }}
                                            >
                                                <Tooltip title="Премахване на файла">
                                                    <img src={deleteIcon} />
                                                </Tooltip>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleModal()._hide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
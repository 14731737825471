import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from 'components/Layout'

//partals
import Documents from './Documents'
import Document from './Document'
import Tabs from './partials/Tabs'
import AddButton from './partials/AddButton'
import Home from './Home'
import AccountingButton from './partials/AccountingButton'

//constatns
import { DOCUMENT_TYPE } from 'constants/Document'

//MUI components
import { Tooltip, Button } from '@material-ui/core'

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'

//providers
import { useDocumentContext } from './Provider'

//plugins
import moment from 'moment'
import axios from 'axios'

//providers
import { useAuthDataContext } from 'providers/Auth'

// modals
import Import from './partials/import/Import'

//models
import User from 'models/User'
import PageLoader from 'components/misc/PageLoader'

const Index = props => {
    const auth = useAuthDataContext()
    const history = useHistory()
    const query = useQuery()
    const context = useDocumentContext()
    const contextState = context.state
    const { pathname } = history.location
    const today = moment().format('DD.MM.YYYY')
    const typeIdPassedByRouter = props?.location?.state?.typeId || null
    const indexPassedByRouter = props?.location?.state?.index || null
    const importModalRef = useRef(null)
    const companyId = auth.getUser()?.getCompany()?.getId()

    const homeTab = {
        name: 'Начало',
        fullName: 'Нов документ',
        typeId: 'home',
        description: 'Към начало',
        info: 'Lorem Ipsum е елементарен примерен текст.',
        firstLetter: null,
        docType: 'home',
        subtabs: [
            {
                number: null,
                date: null,
                deletable: false
            },
        ],
    }

    const invoicesTabs = [
        homeTab,
        {
            name: 'Проформа фактури',
            fullName: 'Проформа',
            typeId: DOCUMENT_TYPE.PROFORMA,
            description: 'Създаване на проформа',
            homeListName: 'Проформа фактури',
            info: 'Създайте Проформа фактура бързо и лесно, като натиснете върху квадратното поле.',
            notFoundText: 'За да създадете нова Проформа фактура натиснете зеления бутон отдолу.',
            firstLetter: 'P',
            docType: 'invoice',
            path: '/invoices',
            tabIndex: 0,
            subtabs: [
                {
                    number: null,
                    date: null,
                    deletable: false
                },
            ],
        },
        {
            name: 'Фактури',
            fullName: 'Фактура',
            typeId: DOCUMENT_TYPE.INVOICE,
            description: 'Създаване на фактура',
            homeListName: 'Фактури',
            info: 'Създайте нова Фактура бързо и лесно, като натиснете върху квадратното поле.',
            notFoundText: 'За да създадете нова Фактура натиснете зеления бутон отдолу.',
            firstLetter: 'I',
            docType: 'invoice',
            path: '/invoices',
            tabIndex: 0,
            subtabs: [
                {
                    number: null,
                    date: null,
                    deletable: false
                },
            ]
        },
        {
            name: 'Кр. известия',
            fullName: 'Кредитно известие',
            typeId: DOCUMENT_TYPE.CREDIT_NOTE,
            description: 'Създаване на кредитно известие',
            homeListName: 'Кредитни известия',
            info: 'Създайте Кредитно известие бързо и лесно, като натиснете върху квадратното поле.',
            notFoundText: 'За да създадете ново Кредитно известие натиснете зеления бутон отдолу.',
            firstLetter: 'C',
            docType: 'invoice',
            path: '/invoices',
            tabIndex: 0,
            subtabs: [
                {
                    number: null,
                    date: null,
                    deletable: false
                },
            ],
        },
        {
            name: 'Дб. известия',
            fullName: 'Дебитно известие',
            typeId: DOCUMENT_TYPE.DEBIT_NOTE,
            description: 'Създаване на дебитно известие',
            homeListName: 'Дебитни известия',
            info: 'Създайте Дебитно известие бързо и лесно, като натиснете върху квадратното поле.',
            notFoundText: 'За да създадете ново Дебитно известие натиснете зеления бутон отдолу.',
            firstLetter: 'D',
            docType: 'invoice',
            path: '/invoices',
            tabIndex: 0,
            subtabs: [
                {
                    number: null,
                    date: null,
                    deletable: false
                },
            ],
        },
    ]

    const notesTabs = [
        homeTab,
        {
            name: 'Ст. разписки',
            fullName: 'Стокова разписка',
            typeId: DOCUMENT_TYPE.STOCK,
            description: 'Създаване на стокова разписка',
            info: 'Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия.',
            firstLetter: 'S',
            docType: 'notes',
            path: '/notes',
            tabIndex: 1,
            subtabs: [
                {
                    number: null,
                    date: null,
                    deletable: false
                },
            ],
        },
        {
            name: 'Протоколи',
            fullName: 'Протокол',
            typeId: DOCUMENT_TYPE.PROTOCOL,
            description: 'Създаване на протокол',
            info: 'Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия.',
            firstLetter: 'P',
            docType: 'notes',
            path: '/notes',
            tabIndex: 1,
            subtabs: [
                {
                    name: null,
                    deletable: false
                },
            ],
        },
    ]

    const offersTabs = [
        homeTab,
        {
            name: 'Оферти',
            fullName: 'Оферта',
            typeId: DOCUMENT_TYPE.OFFER,
            description: 'Създаване на оферта',
            info: 'Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия.',
            firstLetter: 'O',
            docType: 'offers',
            path: '/offers',
            tabIndex: 2,
            subtabs: [
                {
                    number: null,
                    date: null,
                    deletable: false
                },
            ],
        }
    ]

    const allTabs = [...invoicesTabs, /*...notesTabs, ...offersTabs*/]

    /**
     * 
     * @returns главните раздели според зависи пътя, на който сме.
     */
    const appendTabs = () => {
        let tabs = []
        if (pathname === '/invoices') {
            tabs = invoicesTabs
        } else if (pathname === '/notes') {
            tabs = notesTabs
        } else if (pathname === '/offers') {
            tabs = offersTabs
        }

        return tabs
    }

    /**
     * 
     * @returns маркирания раздел и подраздел + type_id по подразбиране
     */

    const getDefaultSelectedTab = () => {
        let active = {}
        let documentsCount = context.documentsCount

        if (pathname === '/invoices') {
            active = {
                activeTabIndex: documentsCount?.invoice > 0 ? 2 : 0 /*да се направи проверка, ако има фактура, активния раздел да е 2 */,
                activeSubtabIndex: 0,
                activeTypeId: documentsCount?.invoice > 0 ? DOCUMENT_TYPE.INVOICE : 'home' /*Същата проверка, както на горния ред - DOCUMENT_TYPE.INVOICE*/,
            }
        } else if (pathname === '/notes') {
            active = {
                activeTabIndex: 0,
                activeSubtabIndex: 0,
                activeTypeId: 'home' /*Същата проверка, както на горния ред - DOCUMENT_TYPE.STOCK*/,
            }
        } else if (pathname === '/offers') {
            active = {
                activeTabIndex: 0,
                activeSubtabIndex: 0,
                activeTypeId: 'home' /*Същата проверка, както на горния ред - DOCUMENT_TYPE.OFFER*/,
            }
        }

        return active
    }

    const [stateManager, setStateManager] = useState({
        initial: null
    })

    const [state, setState] = useState({
        tabs: appendTabs(),
        ...getDefaultSelectedTab(),
        nextDocumentNumber: 0,
        ajaxRequest: null
    })

    const typeIdPassedByFilter = context.state.filter.type_id || query.get('type_id')

    useEffect(() => {
        handlePreviewInvoiceFromDiffPage()
    }, [context.documentTabs])

    const handlePreviewInvoiceFromDiffPage = async () => {
        const tabsState = context.documentTabs
        if (tabsState.type) {
            handleAddNewSubtab(tabsState.type, tabsState.data)
            await context.handlePreviewInvoice()
        }

        if (tabsState.data.type_id) document.querySelector(`.active .last[data-type-id="${tabsState.data.type_id}"]`).click()
    }

    useEffect(() => {
        if (typeIdPassedByRouter) {
            //TODO смяна на раздели от "Списък с документи" не работи.
            //Оставено е защото засега ще има само един раздел в менюто за документи.
            //Да се довърши, когато се пусне поне още един раздел в менюто за документи.

            //Връща данни за избрания раздел
            //typeIdPassedByRouter се подава през Link рутер
            // const getSelectedDocument = appendTabs().find(d => d.typeId === typeIdPassedByRouter) || []

            //indexPassedByRouter може да е излиешн когато се направи да работи с повече от един раздел.
            handleChangeTabs(indexPassedByRouter, 0, typeIdPassedByRouter)
        }
    }, [typeIdPassedByRouter])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            tabs: appendTabs(),
            ...getDefaultSelectedTab(),
        }))

        // getNextNumber()
    }, [pathname])

    useEffect(() => {
        Object.entries(contextState.filter).map(filter => {
            // if (filter[1]) {
            query.set(filter[0], filter[1])
            // }
        })

        if (contextState.setFilter) history.push(`?${query.toString()}`)

        // това е закоментирано, защото при редирект от друга страница, например от финанси с избран филтър
        // се нулира филтъра и не работи правилно
        // else history.replace(`?${query.toString()}`)
    }, [contextState.setFilter])

    function getOnlyVisibleTabs() {
        return getCurrentTabData().tab.subtabs.filter(s => s.date)
    }

    useEffect(() => {
        // getNextNumber()
    }, [getOnlyVisibleTabs().length, typeIdPassedByFilter])
    const getNextNumber = async (typeId) => {
        const params = {
            company_id: auth.getUser()?.getCompany()?.id,
            type_id: typeId || typeIdPassedByFilter,
            company_address_id: auth.getUser()?.getCompany()?.address?.id,
        }
        if (state.ajaxRequest) state.ajaxRequest.cancel()
        const ajaxRequest = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            ajaxRequest,
        }))
        loadingNextNumber(true)

        let nextNumber = 1

        await Api.get('documents/next-number',
            {
                params,
                cancelToken: ajaxRequest.token
            }
        )
            .then(res => {
                setState(prev => ({
                    ...prev,
                    nextDocumentNumber: res.data || 1
                }))
                loadingNextNumber(false)

                if (res.data) {
                    nextNumber = res.data
                }
            })

        return nextNumber
    }

    function loadingNextNumber(loadingNextNumber) {
        setState(prev => ({
            ...prev,
            loadingNextNumber
        }))
    }

    /**
     * 
     * @param {number} activeTabIndex индекса на активния раздел
     * @param {number} activeSubtabIndex индекса на активния подраздел
     * @param {number} activeTypeId type_id на документа
     */
    const handleChangeTabs = (activeTabIndex, activeSubtabIndex, activeTypeId) => {
        setState(prev => ({
            ...prev,
            activeTabIndex,
            activeSubtabIndex,
            activeTypeId
        }))

        context.setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                type_id: activeTypeId,
            },
            setFilter: new Date().getTime()
        }))
    }

    /**
     * 
     * @returns данните на текущия раздел и избрания подраздел
     */
    function getCurrentTabData() {
        const tab = state.tabs[state.activeTabIndex]
        const subtab = tab.subtabs[state.activeSubtabIndex]

        return {
            tab,
            activeTabIndex: state.activeTabIndex,
            subtab,
            activeSubtabIndex: state.activeSubtabIndex
        }
    }

    /**
     * 
     * @param {string} mode режим на съдъражението на подраздела (преглед, редакция, нов)
     * @param {number} id id на подраздела 
     * @returns дали има нужда от отварянето на нов подраздел или ще се активира някой от вече отворените. Ако случаят е втория, то се връща индекса на подраздела, който трябва да се отвори
     */
    const checkIfNewSubtabNeeded = (mode, id) => {
        const tabIndex = state.activeTabIndex

        let newSubtabNeeded = false

        state.tabs[tabIndex].subtabs.map((t, i) => {
            if ((t.id === id) && (t.mode === mode)) {
                newSubtabNeeded = i
            }
        })

        return newSubtabNeeded
    }

    /**
     * 
     * @param {number} id id на документа 
     * @param {number} modeIndex режим на отварятне
     * @returns модифицирано id, за да няма възможност за дублиране, ако един и същи докуемнт е отворен в режимите - редакция и преглед
     */
    const checkId = (id, modeIndex) => {
        if (modeIndex === 2) {
            id = `'e-'${id}`
        }

        return id
    }

    const modes = [
        'new',
        'preview',
        'edit',
    ]

    function getNewTabNumber(filteredTabs, generatedNumber) {
        return Number(filteredTabs[filteredTabs.length - 1]?.number?.split(' ')[1] || generatedNumber - 1 || 0) + 1 || Number(generatedNumber)
    }
    /**
     * 
     * @param {number} modeIndex избор на режим на документа (нов, преглед или редакция) 
     * @param {object} data данните на документа, ако ще е в режим преглед или редакция 
     * @param {boolean} openInCurrentTab дали докуемнта да се отвори в същия таб, ако вече го има отворен или в нов
     */
    const handleAddNewSubtab = (modeIndex = 0, data = {}, openInCurrentTab = false, tabIndex = state.activeTabIndex, subtabIndex = state.activeSubtabIndex, typeId = state.tabs[tabIndex].typeId) => {
        getNextNumber(typeId)
            .then(nextNumber => {
                const { id, no, date_dmy } = data
                const generatedNumber = nextNumber || state.nextDocumentNumber

                //прави се филтър, за да премахне empty от масива
                const filteredTabs = state.tabs[tabIndex].subtabs.filter(s => s?.mode === 'new')

                //ако modeIndex == 0 (нов подраздел), то винаги трябва да се отваря нов подраздел
                if (id, modeIndex) {
                    openInCurrentTab = checkIfNewSubtabNeeded(modes[modeIndex], checkId(id, modeIndex))
                }
                if (!openInCurrentTab) {
                    const newSubtabData = {
                        number: `№ ${Number(no) || Number(handleConstructNumber(getNewTabNumber(filteredTabs, generatedNumber))) || '1'}`,
                        date: date_dmy || `${today} г.`,
                        jsx: <Document
                            handleAddNewSubtab={handleAddNewSubtab}
                            data={data}
                            tabIndex={tabIndex}
                            activeSubtabIndex={subtabIndex}
                            mode={modes[modeIndex]}
                            typeId={typeId}
                            generatedNumber={handleConstructNumber(getNewTabNumber(filteredTabs, generatedNumber))}
                            nextNumberFromServer={generatedNumber}
                            justAdded={true}
                            generatedDocId={checkId(id, modeIndex) || new Date().getTime()}
                            handleTabNumberChange={handleTabNumberChange}
                            hasWritePermission={hasWritePermission()}
                            handleChangeTabs={handleChangeTabs}
                        />,
                        deletable: true,
                        //Добавя се 'e-' при редакция, защото ако се отворят 2 радела (рекдаиця, преглед) 
                        //им се закача едно и също id и това проблем при манипулацията на подраздела
                        id: checkId(id, modeIndex) || new Date().getTime(),
                        mode: modes[modeIndex],
                        companyId
                    }

                    //[!] не е сигурно колко е издържано такова презаписване на стейа   [!]
                    //[!] с цел да се избегне обхождане на всички раздели с цикъл       [!]
                    state.tabs[tabIndex].subtabs.push(newSubtabData)

                    setState(prev => ({
                        ...prev,
                        //Записват се всички текущи подраздели + новия
                        tabs: state.tabs,
                        //След създаване на нов подраздел (който отива на последно място), същият се маркира като активен
                        activeSubtabIndex: state.tabs[tabIndex].subtabs.length - 1,
                        //ако е преглед/редакция да не се покачва номера
                        // nextDocumentNumber: id ? prev.nextDocumentNumber : Number(newTabNumber)
                    }))
                } else {
                    setState(prev => ({
                        ...prev,
                        activeSubtabIndex: openInCurrentTab
                    }))
                }
            })
    }

    /**
     * Подсигурява, че номера на документа е винаги с 10 цифри, като допълва с нули лявата част
     * @param {number|string} number 
     * @returns {string}
     */
    const handleConstructNumber = number => String(number || 1).padStart(10, '0')

    /**
     * 
     * @param {number} tabIndex индекса на раздела, в който ще се извърши действието
     * @param {number} subtabIndex индекса на подраздела, който ще се затвори
     */
    const handleCloseSubtab = (tabIndex, subtabIndex) => {
        const closingTabId = state.tabs[tabIndex].subtabs[subtabIndex].id
        document.querySelector(`.subtabs li[data-id="${closingTabId}"]`).classList.remove('opening')
        setTimeout(() => {
            document.querySelector(`.subtabs li[data-id="${closingTabId}"]`).classList.add('closing')
        }, 100)

        setTimeout(() => {
            delete state.tabs[tabIndex].subtabs[subtabIndex]
            const generatedNumber = Number(state.nextDocumentNumber)

            setState(prev => ({
                ...prev,
                //Записват се всички текущи подраздели без премахнатия
                tabs: state.tabs,
                //След премахването на подраздел, 0-вия се маркира като активен
                activeSubtabIndex: 0,
                //Не се знае затворени раздел дали е бил нов или редакция/преглед
                // nextDocumentNumber: generatedNumber - 1
            }))
        }, 500)
    }

    const handleTabNumberChange = (number, tabIndex, activeSubtabIndex) => {
        const tabs = state.tabs.map((t, i) => {
            if (i === tabIndex) {
                t.subtabs.map((st, j) => {
                    if (j === Number(activeSubtabIndex) + 1) {
                        st.number = number
                    }

                    return st
                })
            }

            return t
        })

        setState(prev => ({
            ...prev,
            tabs
        }))
    }

    const handleShowImportModal = () => {
        const modal = importModalRef.current

        if (modal) modal.open()
    }

    /**
     * 
     * @returns дали текущия раздел е - начало
     */
    const isHomeActive = () => {
        if (state.activeTypeId === 'home') {
            return true
        }

        return false
    }

    /**
     * 
     * @param {number} i индекса на докуемнта 
     * @returns дали документа е активен (избран)
     */
    const isActiveDocument = i => {
        if ((state.activeSubtabIndex != i) || (state.activeSubtabIndex === 0)) {
            return true
        }

        return false
    }

    function hasWritePermission() {
        return auth.getUser().permission("documents:write")
    }

    return (
        <>
            {state.loadingNextNumber ?
                <PageLoader show={true} />
                :
                <>
                </>
            }
            <Import
                ref={importModalRef}
            />
            <Layout>
                <div id="tabs-head" className="row space-between">
                    <Tabs
                        tabs={state.tabs}
                        active={{
                            tabIndex: state.activeTabIndex,
                            subtabIndex: state.activeSubtabIndex,
                            typeId: state.activeTypeId
                        }}
                        handleChange={handleChangeTabs}
                        handleCloseSubtab={handleCloseSubtab}
                        companyId={companyId}
                    />
                    <div
                        className="row right"
                        style={{
                            width: 'fit-content'
                        }}
                    >
                        <AccountingButton />
                        {hasWritePermission() ?
                            <AddButton
                                tabs={state.tabs}
                                handleAddNewSubtab={handleAddNewSubtab}
                                currentTab={getCurrentTabData()}
                                isHomeActive={isHomeActive()}
                                handleChangeTabs={handleChangeTabs}
                            />
                            :
                            <>
                            </>
                        }
                        {/* <Tooltip
                            title="Импорт на документи"
                        >
                            <Button
                                className   = "normal"
                                onClick     = {handleShowImportModal}
                                style       = {{ marginLeft: '10px' }}
                            >
                                Импорт
                            </Button>
                        </Tooltip> */}
                        {/* {Object.keys(context.state.docCount)[0] ?
                            !context.state.docCount[state.activeTypeId]?.count && !context.state.docCount[state.activeTypeId]?.has_serial_number ?
                                <Tooltip
                                    title="Към регистър на номерата"
                                >
                                    <Button
                                        className="normal"
                                        onClick={() => {
                                            history.push('/settings?menu=1&submenu=1_1')
                                        }}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Регистър на номера
                                    </Button>
                                </Tooltip>
                                :
                                <>
                                </>
                            :
                            <>
                            </>
                        } */}
                    </div>
                </div>
                {isHomeActive() ?
                    <Home
                        tabs={state.tabs}
                        allTabs={allTabs}
                        handleChangeTabs={handleChangeTabs}
                        handleAddNewSubtab={handleAddNewSubtab}
                    />
                    :
                    <>
                        {state.activeSubtabIndex === 0 ?
                            <Documents
                                handleAddNewSubtab={handleAddNewSubtab}
                                activeSubtabIndex={state.activeSubtabIndex}
                                activeTabInfo={state.tabs[state.activeTabIndex]}
                                tabs={state.tabs}
                                typeId={state.activeTypeId}
                                handleChangeTabs={handleChangeTabs}
                            />
                            :
                            <>
                            </>
                        }

                        {state.tabs[state.activeTabIndex].subtabs.map((s, i) =>
                            i === 0 ?
                                <>
                                </>
                                :
                                state.loadingNextNumber ?
                                    <>
                                    </>
                                    :
                                    <div
                                        className={isActiveDocument(i) ? '' : 'active-doc'}
                                        style={isActiveDocument(i) || (s.companyId !== companyId) ? { display: 'none' } : {}}
                                        key={s.typeId}
                                    >
                                        {s.jsx}
                                    </div>

                        )}
                    </>
                }
            </Layout>
        </>
    )
}

export default Index



import { useState, useRef } from 'react'

//MUI components
import { Button, MenuItem, Tooltip, Menu } from '@material-ui/core'

//providers
import { useDocumentContext } from '../Provider'

//helpers
import { useQuery } from 'helpers/Url'

//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'

function AddButton(props) {
    const {
        handleAddNewSubtab,
        tabs,
        currentTab,
        isHomeActive,
        handleChangeTabs
    } = props
    const { tab, subtab } = currentTab
    const context = useDocumentContext()
    const { setState } = context
    const [anchorEl, setAnchorEl] = useState(null)
    const addBtnRef = useRef(null)

    const handleLocation = typeId => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                type_id: typeId,
                page: 1,
            },
            setFilter: new Date().getTime()
        }))
    }

    const openMenu = () => {
        setAnchorEl(addBtnRef.current)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }


    return (
        <div>
            <Tooltip
                title="Създаване на нов документ"
            >
                <Button
                    onClick={e => {
                        if (!e.target.classList.contains('menu')) {
                            if (isHomeActive) {
                                openMenu()

                                return
                            }

                            handleAddNewSubtab()
                        }
                    }}
                    className="add with-dropdown"
                    ref={addBtnRef}
                >
                    {tab?.fullName}
                    <i
                        className="menu"
                        onClick={openMenu}
                    >
                        <img src={dropArrowIcon} />
                    </i>
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical    : 'bottom',
                    horizontal  : 'left',
                }}
                transformOrigin={{
                    vertical    : 'top',
                    horizontal  : 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                onClick={closeMenu}
                className="create-documents-menu"
            >
                {tabs.map((t, i) =>
                    t.firstLetter ?
                        <Tooltip
                            title={t.description}
                            key={`tab2_${i}`}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleChangeTabs(i, 0, t.typeId)
                                    handleAddNewSubtab(0, {}, false, i, 0, t.typeId)
                                }}
                                className={tab.typeId === t.typeId ? 'current' : ''}
                            >
                                <span className="image">
                                    {t.firstLetter}
                                </span>
                                {t.fullName}
                            </MenuItem>
                        </Tooltip>
                        :
                        <div>
                        </div>
                )}
            </Menu>
        </div>
    )
}

export default AddButton
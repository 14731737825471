import { createContext, useContext, useMemo, useState } from 'react'

export const PublicPreviewContext = createContext({})

const PublicPreviewProvider = props => {
    // document
    const [document, setDocument] = useState({
        data                            : {},
        address                         : '',
        eik                             : '',
        name                            : '',
        mol                             : '',
        'settlement[name]'              : '',
        'settlement[id]'                : '',
        'settlement[municipality_id]'   : ''
    })
    
    const getCustomerName               = () => document?.name || ''
    const getCustomerAddress            = () => document?.address || ''
    const getSettlement                 = () => document['settlement[name]'] || ''
    const getSettlementId               = () => document['settlement[id]'] || ''
    const getSettlementMunicipalityId   = () => document['settlement[municipality_id]'] || ''
    const getMol                        = () => document?.mol || ''
    const getEik                        = () => document?.eik || ''

    // login/register
    const [auth, setAuth] = useState({
        //modes - login, register
        mode: 'register'
    })

    const setMode = mode => {
        setAuth(prev => ({
            ...prev,
            mode
        }))
    }
    const getMode = () => auth.mode

    const value = {
        document,
        setDocument,
        getCustomerAddress,
        getCustomerName,
        getMol,
        getEik,
        getSettlement,
        getSettlementId,
        getSettlementMunicipalityId,
        auth,
        setMode,
        getMode
    }

    return <PublicPreviewContext.Provider value={value} {...props} />
}

export const usePublicPreviewContext = () => useContext(PublicPreviewContext)

export default PublicPreviewProvider
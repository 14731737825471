import { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
// import ReactGA from 'react-ga'
import { createBrowserHistory } from "history"
import ReactGA from "react-ga4";

//helpers
import { useQuery } from './helpers/Url'

//pages
import Register from './components/pages/auth/register/Index'
import Verify from './components/pages/auth/Verify/Verify'
import VerifySuccess from './components/pages/auth/Verify/Success'
import VerifyFail from './components/pages/auth/Verify/Fail'
import ForgotPassword from './components/pages/auth/Reset/Index'
import ResetPassword from './components/pages/auth/Reset/Reset'
import Login from './components/pages/auth/Login'
import Logout from './components/pages/auth/Logout'

import IntegrationsOAuthCallback from './components/pages/settings/partials/integrations/OAuthCallback'
import IntegrationsConnectSlack from './components/pages/settings/partials/integrations/ConnectSlack'
import IntegrationsSlackCallback from './components/pages/settings/partials/integrations/SlackCallback'
import IntegrationsConnectMessenger from './components/pages/settings/partials/integrations/ConnectMessenger'
import IntegrationsSuccess from './components/pages/settings/partials/integrations/result/Success'
import IntegrationsFail from './components/pages/settings/partials/integrations/result/Fail'

import Customers from './components/pages/customers/Index'
import Customer from './components/pages/customers/Customer'

import Documents from './components/pages/documents/Index'
import Document from './components/pages/documents/Document'
import Import from './components/pages/documents/Import'

import PayOrders from './components/pages/payorders/Index'
import PayOrder from './components/pages/payorders/Document'

import CashOrders from './components/pages/cashorders/Index'
import CashOrder from './components/pages/cashorders/Document'

import Categories from './components/pages/products/categories/Index'
import Products from './components/pages/products/Index'

import Finances from './components/pages/finances/Index'

import Accounts from './components/pages/accounts/Index'

import Projects from './components/pages/projects/projects/Index'
import ProjectsTypes from './components/pages/projects/types/Index'
import ProjectsTeams from './components/pages/projects/teams/Index'

import Machines from './components/pages/machines/machines/Index'
import MachinesTypes from './components/pages/machines/types/Index'

import Tasks from './components/pages/tasks/tasks/Index'
import TasksTypes from './components/pages/tasks/types/Index'
import TasksStatuses from './components/pages/tasks/statuses/Index'

import Settings from './components/pages/settings/Index'

import PublicDocumentPreview from './components/pages/documents/partials/public_preview/Index';

import PaymentComplete from './components/pages/payments/Complete'
import PaymentCancel from './components/pages/payments/Cancel'

import CompanyDeleteRequestSuccess from './components/pages/companies/delete/Success'
import CompanyDeleteRequestFail from './components/pages/companies/delete/Fail'

import e401 from './components/pages/errors/401'
import e404 from './components/pages/errors/404'

// middlewares
import Authed from './middlewares/Authed'
import Guest from './middlewares/Guest'
import Verified from './middlewares/Verified'
import NotVerified from './middlewares/NotVerified'
import Container from './Container'

//providers
import { useAuthDataContext } from './providers/Auth'
import { useLanguageContext } from './providers/Language'
import { useCurrencyContext } from './providers/Currency'
import { useAppContext } from 'providers/App'
import { useDocumentContext } from 'components/pages/documents/Provider'
import RegisterProvider from 'components/pages/auth/register/RegisterProvider'
import PublicPreviewProvider from 'components/pages/documents/partials/public_preview/PublicPreviewProvider'

//components
import ProtectedRoute from './components/ProtectedRoute'
import PageLoader from './components/misc/PageLoader'
import SnackBar from './components/misc/SnackBar'

// style
import './assets/scss/app.scss'
import './assets/scss/modal-primary.scss'

function App() {
	const app = useAppContext()
	const auth = useAuthDataContext()
	const langs = useLanguageContext()
	const currency = useCurrencyContext()
	const documents = useDocumentContext()
	const history = useHistory()
	const location = useLocation()
	const query = useQuery()
	const container = Container.getInstance()

	const [booted, setBooted] = useState(false)
	const [bootedServices, setBootedServices] = useState({
		user: false,
		langs: false,
		currency: false,
		documentsCount: false,
	})

	container.setAuth(auth)
	container.setHistory(history)
	container.setLocation(location)
	container.setQuery(query)


	useEffect(() => {
		if (auth.isLogged()) {
			auth.loadData()
				.then(() => {
					makeBooted('user')
				})
		} else makeBooted('user')

		langs.loadData()
			.then(() => {
				makeBooted('langs')
			})

		currency.loadData()
			.then(() => {
				makeBooted('currency')
			})

		const contentEditableElements = document.querySelectorAll('[contenteditable]')

		if (contentEditableElements.length) {
			for (let i = 0; i < contentEditableElements.length; i++) contentEditableElements[i].setAttribute('contenteditable', 'false')
		}
	}, [])

	useEffect(() => {
		ReactGA.initialize([
			{
				trackingId: 'G-NFQK8QJ0LX',
				gaOptions: {
					userId: auth?.getUser()?.getId()
				}
			}
		])
		const currentHistory = createBrowserHistory()

		ReactGA.send({
			hitType: `${currentHistory.location.pathname}${history.location.search}`,
			page: `${currentHistory.location.pathname}${history.location.search}`
		})
		documents.loadDocumentsCount()
	}, [history.location])

	useEffect(() => {
		if (auth.isLogged()) {
			documents.loadDocumentsCount()
				.then(() => {
					makeBooted('documentsCount')
				})
		} else {
			makeBooted('documentsCount')
		}
	}, [auth.isLogged()])

	useEffect(() => {
		const all = Object.values(bootedServices).length
		const booted = Object.values(bootedServices).filter(booted => booted === true).length

		setBooted(all === booted)
	}, [bootedServices])

	const makeBooted = (service, booted = true) => {
		setBootedServices(prev => ({
			...prev,
			[service]: booted
		}))
	}

	if (!booted) {
		return <PageLoader show />
	}

	const routes = (
		<Route render={({ location }) => (
			<Switch location={location}>
				<Route path="/" exact render={() => <Redirect to="/invoices" />} />

				<ProtectedRoute path="/login" exact component={Login} />
				<ProtectedRoute path="/register" middleware={Guest} exact component={() => <RegisterProvider><Register /></RegisterProvider>} />
				<ProtectedRoute path="/verify" exact middleware={[Authed, NotVerified]} component={Verify} />
				<ProtectedRoute path="/verify/success" exact component={VerifySuccess} />
				<ProtectedRoute path="/verify/fail" exact component={VerifyFail} />
				<ProtectedRoute path="/password/forgot" exact middleware={Guest} component={ForgotPassword} />
				<ProtectedRoute path="/password/reset" exact middleware={Guest} component={ResetPassword} />
				<ProtectedRoute path="/logout" exact middleware={Authed} component={Logout} />

				<ProtectedRoute path="/customers" exact middleware={[Authed, Verified]} component={Customers} />
				<ProtectedRoute path="/customers/add" exact middleware={[Authed, Verified]} component={Customer} />
				<ProtectedRoute path="/customers/edit/:id" exact middleware={[Authed, Verified]} component={Customer} />

				<ProtectedRoute path="/products/categories" exact middleware={[Authed, Verified]} component={Categories} />
				<ProtectedRoute path="/products" exact middleware={[Authed, Verified]} component={Products} />

				<ProtectedRoute path="/invoices" exact middleware={[Authed, Verified]} module="documents" component={Documents} />
				{/* <ProtectedRoute path="/notes" exact middleware={[Authed, Verified]} module="documents" component={Documents} />
				<ProtectedRoute path="/offers" exact middleware={[Authed, Verified]} module="documents" component={Documents} /> */}
				<ProtectedRoute path="/imports/:id" exact middleware={[Authed, Verified]} module="documents" component={Import} />

				<ProtectedRoute path="/payorders" exact middleware={[Authed, Verified]} module="documents" component={PayOrders} />
				<ProtectedRoute path="/payorders/add" exact middleware={[Authed, Verified]} permission="documents:write" component={PayOrder} />
				<ProtectedRoute path="/payorders/edit/:id" exact middleware={[Authed, Verified]} permission="documents:write" component={PayOrder} />

				<ProtectedRoute path="/cashorders" exact middleware={[Authed, Verified]} module="documents" component={CashOrders} />
				<ProtectedRoute path="/cashorders/add" exact middleware={[Authed, Verified]} permission="documents:write" component={CashOrder} />
				<ProtectedRoute path="/cashorders/edit/:id" exact middleware={[Authed, Verified]} permission="documents:write" component={CashOrder} />

				<ProtectedRoute path="/integrations/oauth/callback" exact middleware={Authed} component={IntegrationsOAuthCallback} />
				<ProtectedRoute path="/integrations/slack/connect" exact middleware={Authed} component={IntegrationsConnectSlack} />
				<ProtectedRoute path="/integrations/slack/callback" exact middleware={Authed} component={IntegrationsSlackCallback} />
				<ProtectedRoute path="/integrations/messenger/connect" exact middleware={Authed} component={IntegrationsConnectMessenger} />
				<ProtectedRoute path="/integrations/success" exact middleware={Authed} component={IntegrationsSuccess} />
				<ProtectedRoute path="/integrations/fail" exact middleware={Authed} component={IntegrationsFail} />

				<ProtectedRoute path="/finances/:tab" exact middleware={[Authed, Verified]} component={Finances} />
				<ProtectedRoute path="/accounts" exact middleware={[Authed, Verified]} component={Accounts} />

				<ProtectedRoute path="/payments/complete" exact component={PaymentComplete} />
				<ProtectedRoute path="/payments/cancel" exact component={PaymentCancel} />

				<ProtectedRoute path="/projects" exact middleware={[Authed, Verified]} component={Projects} />
				<ProtectedRoute path="/projects/types" exact middleware={[Authed, Verified]} component={ProjectsTypes} />
				<ProtectedRoute path="/projects/teams" exact middleware={[Authed, Verified]} component={ProjectsTeams} />

				<ProtectedRoute path="/machines" exact middleware={[Authed, Verified]} component={Machines} />
				<ProtectedRoute path="/machines/types" exact middleware={[Authed, Verified]} component={MachinesTypes} />

				<ProtectedRoute path="/tasks" exact middleware={[Authed, Verified]} component={Tasks} />
				<ProtectedRoute path="/tasks/types" exact middleware={[Authed, Verified]} component={TasksTypes} />
				<ProtectedRoute path="/tasks/statuses" exact middleware={[Authed, Verified]} component={TasksStatuses} />

				<ProtectedRoute path="/settings" exact middleware={[Authed, Verified]} component={Settings} />

				<Route path="/company/delete/success" exact component={CompanyDeleteRequestSuccess} />
				<Route path="/company/delete/fail" exact component={CompanyDeleteRequestFail} />

				<Route path="/inv/:hash" exact component={() => <PublicPreviewProvider><PublicDocumentPreview /></PublicPreviewProvider>} />

				<Route path="/unauthorized" component={e401} />

				<Route path="*" component={e404} />
			</Switch>
		)} />
	)

	const common = (
		<>
			<SnackBar
				severity="success"
				open={app.state.messages.success.show}
				message={app.state.messages.success.message}
			/>
			<SnackBar
				severity="info"
				open={app.state.messages.info.show}
				message={app.state.messages.info.message}
			/>
			<SnackBar
				severity="error"
				open={app.state.messages.error.show}
				message={app.state.messages.error.message}
			/>
		</>
	)

	return (
		<>
			{routes}
			{common}
		</>
	)
}

export default App


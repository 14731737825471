const MenuIndicator = props => {
    const { active, style } = props

    return (
        <div
            className={`custom-menu ${active ? 'active' : ''}`}
            style={style}
        >
            <span>

            </span>
            <span>

            </span>
            <span>

            </span>
        </div>
    )
}

export default MenuIndicator
import { Link } from 'react-router-dom'

//MUI components
import { Tooltip } from '@material-ui/core'

const Home = props => {
    const { tabs, allTabs, handleAddNewSubtab, handleChangeTabs } = props

    return (
        <section id="documents-home">
            <div className="settings-panel">
                <div className="menu">
                    <b>
                        Списък с документи
                    </b>
                    <ul>
                        {allTabs.map((t, i) =>
                            t.firstLetter ?
                                <Tooltip
                                    title   = {`Към ${t.homeListName.toLowerCase()}`}
                                    key     = {`tab2_${i}`}
                                >
                                    <li
                                        className="active"
                                    >
                                        <Link
                                            to={{
                                                pathname    : t.path,
                                                state       : {
                                                    typeId  : t.typeId,
                                                    index   : i
                                                }
                                            }}
                                        >
                                            <span className="image">
                                                {t.firstLetter}
                                            </span>
                                            <span className="text">
                                                {t.homeListName}
                                            </span>
                                        </Link>
                                    </li>
                                </Tooltip>
                                :
                                <>
                                </>
                        )}
                    </ul>
                </div>
                <div className="content">
                    <h2>
                        Създайте документ
                    </h2>
                    <p>
                    За да създадете нов документ е необходимо да изберете и натиснете един от посочените по долу документи и да започнете въвеждането на данни.
                    </p>
                    {/* <b>
                        Основни модули
                    </b> */}
                    <ul className="main">
                        {tabs.map((t, i) =>
                            t.firstLetter ?
                                <Tooltip
                                    title   = {t.description}
                                    key     = {`tab2_${i}`}
                                >
                                    <li
                                        key   = {i}
                                        onClick={() => {
                                            handleChangeTabs(i, 0, t.typeId)
                                            handleAddNewSubtab(0, {}, false, i, 0, t.typeId)
                                        }}
                                    >
                                        <span className="image">
                                            {t.firstLetter}
                                        </span>
                                        <span className="text">
                                            {t.fullName}
                                        </span>
                                        <p>
                                            {t.info}
                                        </p>
                                    </li>
                                </Tooltip>
                                :
                                <>
                                </>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Home
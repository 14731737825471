import { Column } from '@ant-design/plots';
import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import RedditSelect from 'components/misc/RedditSelect';
import Api from 'helpers/Api';
import { useCallback, useEffect, useRef, useState } from 'react';
import calendarIcon from 'assets/img/app/icons/calendar.png'
import DateOptions from './DateOptions';
import DatePicker from 'components/misc/DatePicker';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import IncomesFilter from './IncomesFilter';

let timeout;

function Index() {
    const [state, setState] = useState({
        data: {
            customers: [],
        },
        originalCustomers: [],
        comparedCustomers: [],
        path: [],
        filter: {
            group: 'month',
            start: null,
            end: null,
            force_start: null,
            force_end: null,
            category_id: [],
            customer_id: [],
        },
        chart: null,
        loading: true,
        refresh: false,
        request: null,
    });

    const [data, setData] = useState([]);

    const dateOptionsRef = useRef(null);


    useEffect(() => {
        loadData();
    }, [state.refresh]);

    useEffect(() => {

        let data = cloneDeep(state.originalCustomers);

        if (state.comparedCustomers.length) {
            data = data.filter(customer => {
                return state.comparedCustomers.indexOf(customer.data.id) > -1;
            });
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customers: data,
            },
        }))
    }, [state.comparedCustomers, state.originalCustomers]);

    useEffect(() => {
        if (state.comparedCustomers.length === 0 && state.data.customers.length > 0) {
            setState(prev => ({
                ...prev,
                comparedCustomers: prev.data.customers.map(c => c.data.id),
            }));
        }
    }, [state.data.customers, state.comparedCustomers])

    useEffect(() => {
        if (state.loading) {
            return;
        }

        let data = [];

        let customers = state.data.customers;

        state.data.dates.map(date => {
            customers.map(customer => {
                let group = {
                    xField: date.date,
                    yField: customer.stats.dates[date.date]?.gross || 0,
                    seriesField: customer.data.info.name
                };

                data.push(group);
            });
        });

        // console.log(data)

        setData(data);
    }, [state.data])

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        Api.post('accounts/incomes-by-category', state.filter, {
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                originalCustomers: res.data.customers,
                // comparedCustomers: res.data.customers.map(c => c.data.id),
                filter: {
                    ...prev.filter,
                    group: res.data.group,
                    force_start: res.data.start,
                    end: res.data.end
                },
                loading: false,
            }));

        })
    };

    const handleChangeCustomer = e => {
        let values = e.target.value;

        changeSelectedCustomer(values);
    }

    const changeSelectedCustomer = data => {
        if (data.length === 0) {
            return;
        }

        setState(prev => ({
            ...prev,
            comparedCustomers: data
        }))
    }

    const changeSelectedCategory = data => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                category_id: data
            },
            refresh: new Date()
        }))
    }

    const findCustomer = id => {
        return state.data.customers.find(c => Number(c.data.id) === Number(id))
    }

    const isSelectedCustomer = id => {
        return state.comparedCustomers.indexOf(id) > -1;
    }

    const setChart = chart => {
        setState(prev => ({
            ...prev,
            chart: chart
        }));
    }

    const handleFilterChange = (name, value, delay = 0) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [name]: value
            },
            comparedCustomers: [],
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                refresh: new Date()
            }))
        }, delay)
    }

    const handleShowCalendar = e => {
        dateOptionsRef.current.show(e);
    }

    const getDateTotal = (date) => {
        let total = 0;

        state.data.customers.map(customer => {
            total += customer.stats.dates[date].gross;
        });

        total = total.toFixed(2);

        return total
    }

    const getTotal = () => {
        let total = 0;

        state.data.customers.map(customer => {
            total += customer.stats.gross;
        });

        total = total.toFixed(2);

        return total;
    }

    const config = {
        data,
        xField: 'xField',
        yField: 'yField',
        seriesField: 'seriesField',
        // color: ['l(270) 0:#C871F3 0.5:#C871F3 1:#F871CF', 'l(270) 0:#0DCFF5 0.5:#40E0D4 1:#83F8A9'],
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
        // xAxis: {
        //     type: 'time',
        //     mask: state.filter.group === 'day' ? 'DD-MM-YYYY' : 'MM-YYYY',
        // },
        yAxis: {
            label: {
                formatter: (v) => Number(v).toFixed(2),
            },
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.seriesField,
                    value: datum.yField.toFixed(2),
                };
            },
        },
        legend: {
            marker: {
                symbol: 'circle'
            },
            position: 'top-right',
            itemSpacing: 10,
        },
    };

    return (
        <>

            <div className="row">
                <div className="col chart">
                    <div className="head">
                        <div>
                            <div className="title">
                                Сравняване на приходите по клиенти
                            </div>
                            <div className="subtitle">
                                Сравнете приходите по клиенти, като използвате зададен от Вас период.
                            </div>
                        </div>
                        <div className="dates">
                            <div className={`btn ${state.filter.group === 'day' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'day')}>
                                Д
                            </div>
                            <div className={`btn ${state.filter.group === 'week' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'week')}>
                                С
                            </div>
                            <div className={`btn ${state.filter.group === 'month' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'month')}>
                                М
                            </div>
                            <div className={`btn`} onClick={e => handleShowCalendar(e)}>
                                <img src={calendarIcon} alt="" />
                            </div>
                            <DateOptions
                                ref={dateOptionsRef}
                            >
                                <MenuItem>
                                    <DatePicker
                                        reddit
                                        label="От дата"
                                        value={state.filter.force_start || state.filter.start || ''}
                                        onChange={e => handleFilterChange('start', e.target.value, 300)}
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <DatePicker
                                        reddit
                                        label="До дата"
                                        value={state.filter.end || ''}
                                        onChange={e => handleFilterChange('end', e.target.value, 300)}
                                    />
                                </MenuItem>
                            </DateOptions>
                        </div>
                    </div >
                    {/* <div className="filter">
                        <div className="item category">
                            <RedditSelect
                                label="Клиенти"
                                value={state.comparedCustomers}
                                renderValue={(selected) => selected.map(id => findCustomer(id)).filter(c => c).map(c => c?.data?.info?.name).join(', ')}
                                onChange={handleChangeCustomer}
                                multiple
                            >
                                {(state.originalCustomers || []).map(customer =>
                                    <MenuItem key={'c' + customer.data.id} value={customer.data.id}>
                                        <Checkbox checked={isSelectedCustomer(customer.data.id)} />
                                        <ListItemText primary={customer.data.info.name} />
                                    </MenuItem>
                                )}
                            </RedditSelect>
                        </div>
                    </div> */}

                    <IncomesFilter
                        primaryFilter="customer"
                        filter={state.filter}
                        selectedCustomers={state.comparedCustomers}
                        onCustomerChange={data => changeSelectedCustomer(data)}
                        onCategoryChange={data => changeSelectedCategory(data)}
                    />

                    <div className="graph">
                        {state.loading
                            ?
                            <Skeleton style={{ height: '100%' }} />
                            :
                            <Column {...config} onReady={e => setChart(e)} />
                        }
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col">
                    {state.loading
                        ?
                        <Skeleton count={4} height={30} />
                        :
                        <div className="scrollable">
                            <table className="type-outer">
                                <thead>
                                    <tr>
                                        <th>
                                            Клиент
                                        </th>
                                        {state.data.dates.map(date =>
                                            <th key={'th-' + date.date} style={{ textAlign: 'right' }}>
                                                {date.label}
                                            </th>
                                        )}
                                        <th style={{ textAlign: 'right' }}>
                                            Общо
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.customers.map(customer =>
                                        <tr key={'tr-' + customer.data.id}>
                                            <td>
                                                {customer.data.info.name}
                                            </td>
                                            {state.data.dates.map(date =>
                                                <td key={'td-' + date.date} style={{ textAlign: 'right' }}>
                                                    {customer.stats.dates[date.date].gross.toFixed(2)}
                                                </td>
                                            )}
                                            <td style={{ textAlign: 'right' }}>
                                                {customer.stats.gross.toFixed(2)}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            Общо
                                        </td>
                                        {state.data.dates.map(date =>
                                            <td key={'tdd-' + date.date} style={{ textAlign: 'right' }}>
                                                {getDateTotal(date.date)}
                                            </td>
                                        )}
                                        <td style={{ textAlign: 'right' }}>
                                            {getTotal()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Index;

import { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import Layout from '../../Layout'

//partials
import MyProfile from './partials/profile/Index'
import NumbersMain from './partials/numbers/Index'
import Categories from './partials/categories/Index'
import Comapnies from './partials/companies/Index'
import Integrations from './partials/integrations/Index'
import Users from './partials/users/Index'
import Templates from './partials/templates/Index'
import MailSigns from './partials/mail-signs/Index'
import Payments from './partials/payments/Index'
import Notifications from './partials/notifications/Index'

//helpers
import { useQuery } from 'helpers/Url'

//styles
import 'assets/scss/pages/settings.scss'

//images
import profileIcon from 'assets/img/settings/icons/profile.png'
import numbersIcon from 'assets/img/settings/icons/numbers.png'
import accountIcon from 'assets/img/settings/icons/account.png'
import categoriesIcon from 'assets/img/settings/icons/categories.png'
import companiesIcon from 'assets/img/settings/icons/companies.png'
import integrationsIcon from 'assets/img/settings/icons/integrations.png'
import paymentsIcon from 'assets/img/settings/icons/payments.png'
import signaturesIcon from 'assets/img/settings/icons/signatures.png'
import templatesIcon from 'assets/img/settings/icons/templates.png'
import notificationsIcon from 'assets/img/settings/icons/notifications.png'

//providers
import { useAuthDataContext } from 'providers/Auth'


const MENU_ITEMS = [
    {
        id: '1',
        title: 'Регистър на номера',
        icon: numbersIcon,
    },
    {
        id: 'templates',
        title: 'Шаблони',
        icon: templatesIcon,
    },
    {
        id: 'mail-signs',
        title: 'Подписи',
        icon: signaturesIcon,
    },
    {
        id: '2',
        title: 'Моят профил',
        icon: profileIcon,
    },
    // {
    //     id: 'notifications',
    //     title: 'Известия',
    //     icon: notificationsIcon,
    // },
    {
        id: '3',
        title: 'Категории',
        icon: categoriesIcon,
        submenus: [
            {
                id: '3_1',
                title: 'Приходи',
            },
            {
                id: '3_2',
                title: 'Разходи',
            },
        ]
    },
    {
        id: '4',
        title: 'Моите фирми',
        icon: companiesIcon,
        innerScreens: [
            {
                id: '4>1',
                title: 'Настройки',
            },
        ]
    },
    // {
    //     id: '5',
    //     title: 'Интеграции',
    //     icon: integrationsIcon,
    // },
    {
        id: '6',
        title: 'Потребители',
        icon: accountIcon,
    },
    {
        id: 'payments',
        title: 'Плащания',
        icon: paymentsIcon,
    },
]

function Index() {
    const auth = useAuthDataContext()
    const history = useHistory()
    const query = useQuery()
    const isAdmin = auth.getUser()?.isAdmin()
    const activeMenuId = query.get('menu') || '1_1'

    const [state, setState] = useState({
        activeMenuId,
        // activeSubmenuId,
        refresh: false
    })

    /**
     * 
     * @param {number} activeMenuId id на екрана
     */
    const handleMenu = activeMenuId => {
        const url = `?menu=${activeMenuId}`

        setState(prev => ({
            ...prev,
            activeMenuId
        }))

        history.push(url)
    }

    useEffect(() => {
        setState(prev => ({
            ...prev,
            activeMenuId,
        }))
    }, [history.location])

    /**
     * 
     * @param {number} screenId id на екрана 
     * @returns дали има достъп до екрана
     */
    const checkAccess = screenId => {
        const checkIsAdmin = () => isAdmin

        if (screenId === '4') {
            //Моите фирми
            return checkIsAdmin()
        } else if (screenId === '6') {
            //Потребители
            return checkIsAdmin()
        } else {
            return true
        }
    }

    const handleClickMenuOption = (id, submenus) => {
        if (state.activeMenuId !== id) {
            if (submenus) handleMenu(submenus[0].id)
            else handleMenu(id)
        }
    }

    const handleClickSubmenuOption = id => {
        if (state.activeMenuId !== id) handleMenu(id)
    }

    /**
     * 
     * @param {number} screenId id на екрана
     * @returns дали екрана е активен
     */
    const checkScreen = screenId => {
        if ((state.activeMenuId === screenId) || state.activeMenuId.includes(`${screenId}_`)) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <Layout>
            <section id="settings">
                <div id="head" className="row space-between">
                    <div className="title">
                        Настройки
                    </div>
                </div>
                <div className="settings-panel">
                    <ul className="menu">
                        {MENU_ITEMS.map(m =>
                            checkAccess(m.id) ?
                                <li
                                    key={m.id}
                                    className={(state.activeMenuId === m.id) || state.activeMenuId.includes(`${m.id}_`) ? 'active' : ''}
                                >

                                    <span
                                        onClick={() => {
                                            handleClickMenuOption(m.id, m?.submenus)
                                        }}
                                    >
                                        <img src={m.icon} />
                                        {m.title}
                                    </span>
                                    {m?.submenus && ((state.activeMenuId === m.id) || state.activeMenuId.includes(`${m.id}_`)) ?
                                        <ul className="submenu">
                                            {m.submenus.map(s =>
                                                <li
                                                    key={s.id}
                                                    onClick={() => {
                                                        handleClickSubmenuOption(s.id)
                                                    }}
                                                    className={(state.activeMenuId === s.id) || state.activeMenuId.includes(`${s.id}_`) ? 'active' : ''}
                                                >
                                                    <span>
                                                        {s.title}
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                        :
                                        <>
                                        </>
                                    }
                                </li>
                                :
                                <>
                                </>
                        )}
                    </ul>
                    <div className="content">
                        {
                            checkScreen('1') ?
                                <NumbersMain />
                                :
                                checkScreen('templates')
                                    ?
                                    <Templates />
                                    :
                                    checkScreen('mail-signs')
                                        ?
                                        <MailSigns />
                                        :
                                        checkScreen('2') ?
                                            <MyProfile />
                                            :
                                            checkScreen('3') ?
                                                <Categories
                                                    screen={state.activeMenuId}
                                                />
                                                :
                                                checkScreen('4') && isAdmin ?
                                                    <Comapnies />
                                                    :
                                                    checkScreen('5') ?
                                                        <Integrations />
                                                        :
                                                        checkScreen('6') && isAdmin ?
                                                            <Users />
                                                            :
                                                            checkScreen('payments') && isAdmin ?
                                                                <Payments />
                                                                :
                                                                checkScreen('notifications') ?
                                                                    <Notifications />
                                                                    :
                                                                    <Redirect
                                                                        to="/settings?menu=1"
                                                                    />
                        }
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Index
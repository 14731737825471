
import { useEffect, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//constants
import { PAID_FILTER_TYPES } from 'constants/Document'
import { PAYMENT_EXTRA_DATA } from 'constants/PaymentMethod'

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'

//images
import allIcon from 'assets/img/documents/filter/icons/all.png'
import paidIcon from 'assets/img/documents/filter/icons/paid.png'
import unpaidIcon from 'assets/img/documents/filter/icons/unpaid.png'
import partlyPaidIcon from 'assets/img/documents/filter/icons/partly-paid.png'
import downIcon from 'assets/img/documents/filter/icons/down.png'

//providers
import { useAppContext } from 'providers/App'

const Paid = props => {
    const app = useAppContext()
    const query = useQuery()
    const { data, handleSearch } = props
    const { payment_method_id, status } = data

    const [state, setState] = useState({
        visible: false,
        paymentMethods: [],
        usedPaymentMethods: [],
        moreMethods: false
    })

    useEffect(() => {
        app.getPaymentMethods()
            .then(data => {
                setPaymentMethods(data)
            })

        getUsedPaymentMethods()
    }, [])

    /**
     * Запазва наличните начини на плащане
     * @param {array} paymentMethods 
     */
    const setPaymentMethods = paymentMethods => {
        setState(prev => ({
            ...prev,
            paymentMethods
        }))
    }

    /**
     * Запазва използваните в документи начини на плащане
     * @param {array} usedPaymentMethods 
     */
    const setUsedPaymentMethods = usedPaymentMethods => {
        setState(prev => ({
            ...prev,
            usedPaymentMethods
        }))
    }

    const handleVisibilty = () => {
        setState(prev => ({
            ...prev,
            visible: !prev.visible
        }))

        setMoreMethods(false)
    }

    /**
     * избор на статус - плащане
     * @param {number} value 
     */
    const handleSelectStatus = value => {
        handleSearch('status', value)
        handleVisibilty()
    }

    /**
     * избор на начин на плащане
     * @param {number} value 
     */
    const handleSelectMethod = value => {
        handleSearch('payment_method_id', value)
        handleVisibilty()
    }

    /**
     * връща иконата на избрания филтър
     * @param {string} selection 
     * @returns {string}
     */
    const getIconBasedOnSelection = selection => {
        if (selection === '0') {
            return allIcon
        } else if (selection === 'not-paid') {
            return unpaidIcon
        } else if (selection === 'paid') {
            return paidIcon
        } else if (selection === 'partially') {
            return partlyPaidIcon
        }

        return ''
    }

    const getUsedPaymentMethods = () => {
        Api.get('documents/used-payment-methods')
            .then(res => {
                setUsedPaymentMethods(res.data)
            })
    }

    /**
     * връща използвани и неизползвани методи на плащане в документите
     * @returns {object}
     */
    const handleSeparatePayentMethods = () => {
        let usedIds = []
        let notUsedIds = []

        state.usedPaymentMethods.map(u => {
            usedIds = [...usedIds, u.id]
        })

        state.paymentMethods.map(p => {
            if (!usedIds.includes(p.id)) {
                notUsedIds = [...notUsedIds, p.id]
            }
        })

        const notUsed = (state.paymentMethods || []).filter(p => notUsedIds.includes(p.id)) || []
        const used = state.usedPaymentMethods || []

        return {
            notUsed,
            used
        }
    }

    const setMoreMethods = moreMethods => {
        setState(prev => ({
            ...prev,
            moreMethods
        }))
    }

    return (
        <div className="col">
            <div
                className="custom-filter-select paid"
                onClick={() => {
                    if (!state.visible) handleVisibilty()
                }}
            >
                <div className="option">
                    <img src={getIconBasedOnSelection(status || query.get('status') || '0')} />
                    {PAID_FILTER_TYPES[status || query.get('status') || 0]}
                </div>
                {state.visible ?
                    <ClickAwayListener
                        onClickAway={handleVisibilty}
                    >
                        <div className="paid-options">
                            <ul className="first-options">
                                {Object.values(PAID_FILTER_TYPES).map((t, i) =>
                                    <li
                                        key={`paid-${i}`}
                                        onClick={() => {
                                            handleSelectStatus(Object.keys(PAID_FILTER_TYPES)[i])
                                        }}
                                    >
                                        <img src={getIconBasedOnSelection(Object.keys(PAID_FILTER_TYPES)[i])} />
                                        {t}
                                    </li>
                                )}
                            </ul>
                            <ul className="second-options">
                                {((handleSeparatePayentMethods()?.used || []).length ? handleSeparatePayentMethods()?.used : handleSeparatePayentMethods()?.notUsed).map(p =>
                                    <li
                                        key={p.id}
                                        onClick={() => {
                                            handleSelectMethod(p.id)
                                        }}
                                        className={Number(payment_method_id) === Number(p.id) ? 'active' : ''}
                                    >
                                        <img src={PAYMENT_EXTRA_DATA[p.name].logo || PAYMENT_EXTRA_DATA[p.name].icon} />
                                        Платено чрез - {p.translation.name}
                                    </li>
                                )}
                            </ul>
                            {(handleSeparatePayentMethods()?.notUsed || []).length && (handleSeparatePayentMethods()?.used || []).length ?
                                !state.moreMethods ?
                                    <span
                                        onClick={() => {
                                            setMoreMethods(true)
                                        }}
                                        className="more"
                                    >
                                        Виж други методи
                                        <img src={downIcon} />
                                    </span>
                                    :
                                    <ul className="second-options extra">
                                        {(handleSeparatePayentMethods()?.notUsed || []).map(p =>
                                            <li
                                                key={p.id}
                                                onClick={() => {
                                                    handleSelectMethod(p.id)
                                                }}
                                                className={Number(payment_method_id) === Number(p.id) ? 'active' : ''}
                                            >
                                                <img src={PAYMENT_EXTRA_DATA[p.name].logo || PAYMENT_EXTRA_DATA[p.name].icon} />
                                                Платено чрез - {p.translation.name}
                                            </li>
                                        )}
                                    </ul>
                                :
                                <>
                                </>
                            }
                        </div>
                    </ClickAwayListener>
                    :
                    <>
                    </>
                }
            </div>
        </div>
    )
}

export default Paid
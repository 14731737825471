import { useState, useImperativeHandle, forwardRef } from 'react'
import Button from '@material-ui/core/Button';
import SaveButton from '../../../../misc/Button';
import TextField from '@material-ui/core/TextField';
import closeIcon from '../../../../../assets/img/modals/icons/close.png';
import { useValidation } from '../../../../../helpers/Validation';
import Api from '../../../../../helpers/Api';
import Autocomplete from '../../../../misc/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

function AddPayment(props, ref) {
    const [state, setState] = useState({
        id: null,
        data: null,
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: id => {
            handleOpen(id);
        },
        close: () => {
            handleHide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleOpen = id => {
        setState(prev => ({
            ...prev,
            overlay: true,
            id
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50);
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);

        resetData();
        setValidations(null);

        if (typeof state.onClose === 'function') state.onClose()
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: null,
        }));
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleDateChange = date => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                date: moment(date).format('YYYY-MM-DD'),
            }
        }));
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = 'documents/add-payment'

        const data = new FormData(e.target)

        data.append('id', state.id)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                handleHide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading,
        }))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Добавяне на частично плащане
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    <TextField
                        variant="standard"
                        size="small"
                        margin="normal"
                        fullWidth
                        type="number"
                        min="0"
                        label="Сума"
                        name="price"
                        value={state.data?.price || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.price)}
                        helperText={validations && validations.price && (validations.price[0] || validations.price)}
                        autoFocus
                    />

                    <Autocomplete
                        freeSolo={false}
                        variant="standard"
                        size="small"
                        inputPlaceholder="Начин на плащане"
                        inputName={`payment_method`}
                        inputIdName={`payment_method_id`}
                        url="payment-methods/all"
                        getResultData={data => data}
                        getOptionLabel={option => option?.translation?.name || ''}
                        renderOption={option => option?.translation?.name || ''}
                        error={Boolean(validations && validations.payment_method_id && (validations.payment_method_id[0] || validations.payment_method_id))}
                        helperText={validations && validations.payment_method_id && (validations.payment_method_id[0] || validations.payment_method_id)}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardDatePicker
                                size="small"
                                disableToolbar
                                variant="inline"
                                fullWidth
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Дата"
                                disableFuture
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={state.data?.date || ''}
                                onChange={handleDateChange}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>

                    <input type="hidden" name="date" value={state.data?.date || ''} />
                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddPayment);
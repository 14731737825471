import { fade, makeStyles } from '@material-ui/core/styles';
import { FormHelperText, Select, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStylesReddit = makeStyles((theme) => ({
    root: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
    },
}));

function RedditSelect(props) {
    const classes = useStylesReddit();

    // const theme = useTheme();

    return (
        <FormControl
            variant="filled"
            margin="dense"
            size="small"
            fullWidth
            classes={classes}
            error={props.error}
        >
            <InputLabel>
                {props.label}
            </InputLabel>
            <Select
                {...props}
                disableUnderline
                endAdornment={
                    props.helperText &&
                    <Tooltip title={props.helperText} placement="top" arrow>
                        <span className="error-info">

                        </span>
                    </Tooltip>
                }
                IconComponent={() => props.error ? null : <ArrowDropDownIcon />}
                style={{
                    backgroundColor: '#fff',
                }}
            >
                {props.children}
            </Select>
            {/* <FormHelperText>
                {props.helperText}
            </FormHelperText> */}
        </FormControl >
    );
}

export default RedditSelect;
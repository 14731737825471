import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

//plugins
import { cloneDeep, get, set } from 'lodash'

//components
import SaveButton from '../../../../misc/Button'

//MUI components
import { Button, FormControlLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

function Index(props, ref) {
    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        loading: false,
        dataLoading: false,
        onSuccess: null,
        onClose: null,
    })

    const [selected, setSelected] = useState(null)

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        show: () => {
            show()
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        },
    }))

    useEffect(() => {
        let data = {}

        if (props.product && Array.isArray(props.product.categories)) {
            props.product.categories.map(category => {
                data[category.id] = category
            })
        }

        setSelected(data)
    }, [props.product])

    useEffect(() => {
        if (state.modal && state.data.length === 0) loadData()
    }, [state.modal])

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)

    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }))
        }, 150)
    }

    const handleHide = e => {
        e.preventDefault()
        setValidations(null)
        hide()

        if (typeof state.onClose === 'function') state.onClose()
    }

    const loadData = () => {
        setDataLoading(true)

        Api.get('categories/all?resource=1', {
            params: {
                company_id: props.companyId
            }
        })
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }))
            })
            .finally(() => {
                setDataLoading(false)
            })
    }

    const setDataLoading = dataLoading => {
        setState(prev => ({
            ...prev,
            dataLoading
        }))
    }

    const handleSave = e => {
        e.preventDefault()

        hide()

        if (typeof state.onSuccess === 'function') {
            state.onSuccess(
                Object.values(selected)
            )
        }

        if (typeof state.onClose === 'function') state.onClose()
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data')
    }

    const handleChange = category => {
        const data = cloneDeep(selected)

        if (category) {
            if (data[category.id]) {
                delete data[category.id]
            } else {
                data[category.id] = category
            }
        }

        setSelected(data)
    }

    const loadMore = (id, lastChildId, level, pathIndex) => {
        Api.get('categories/more', {
            params: {
                id,
                last_id: lastChildId,
            }
        })
            .then(res => {
                addCategory(level, pathIndex, res.data)
            })
    }

    const addCategory = (level, pathIndex, category, wasRecentlyCreated = false) => {
        let categories = cloneDeep(state.data)

        if (wasRecentlyCreated) {
            category.wasRecentlyCreated = true
        }

        const { path, parentPath } = getNestedPath(level, pathIndex)

        // добавяме новата категория (или категории) към останалите от същото ниво
        const childList = cloneDeep(get(categories, path))

        categories = set(categories, path, childList.concat(category))

        // ако категория е добавена току-що да обнови children_count на главната категория
        if (wasRecentlyCreated) {
            const parent = cloneDeep(get(categories, parentPath))

            if (parent) {
                parent.children_count++

                categories = set(categories, parentPath, parent)
            }
        }

        setState(prev => ({
            ...prev,
            data: categories
        }))
    }

    const getNestedPath = (level, pathIndex) => {
        const categories = cloneDeep(state.data)
        let temp = categories

        let i = 0

        let index
        let path = ''
        let parentPath = ''

        while (i < level) {
            const id = pathIndex[i]

            if (id) {
                index = temp.findIndex(c => c.id == id)
                temp = temp[index]?.children
                path += `[${index}][children]`
            }

            i++
        }

        const lastChildrenPos = path.lastIndexOf('[children]')

        parentPath = path.substring(0, lastChildrenPos)

        return { path, parentPath }
    }

    const findLastNotRecentlyCreatedChildId = category => {
        const filtered = category.children.filter(c => c.wasRecentlyCreated !== true)
        const last = filtered[filtered.length - 1]

        return last.id
    }

    const render = (items, level = 1, index = []) => {
        return items.map(c =>
            <React.Fragment key={'c-' + c.id}>
                <div
                    style={{
                        paddingLeft: (level - 1) * 20,
                        marginBottom: c.children.length ? '10px' : '0'
                    }}
                >
                    {c.children.length ?
                        <div className="heading of-list">
                            {c?.translation?.name}
                        </div>
                        :
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selected[c.id] ? true : false}
                                    onChange={() => handleChange(c)}
                                    color="primary"
                                    margin="normal"
                                    inputProps={{ 'aria-label': 'checkbox' }}
                                    name={`categories[]`}
                                    value={c.id}
                                />
                            }
                            label={c?.translation?.name}
                        />
                    }
                </div>

                {c.children.length ? render(c.children, level + 1, index.concat(c.id)) : ''}

                {c.children_count > c.children.length
                    ?
                    <button
                        className="normal link"
                        onClick={e => {
                            e.preventDefault()
                            loadMore(c.id, findLastNotRecentlyCreatedChildId(c), level, index.concat(c.id))
                        }}
                        style={{
                            margin: '0 auto',
                            display: 'block',
                        }}
                    >
                        покажи още за {c.translation?.name} (общо {c.children_count}, показани {c.children.length})
                    </button>
                    :
                    ''
                }
            </React.Fragment>
        )
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary large`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Изберете категория
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    {state.dataLoading
                        ?
                        <Skeleton count={10} height={60} />
                        :
                        render(state.data)
                    }
                </div>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Index)
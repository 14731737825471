import { createContext, useContext, useMemo, useState } from 'react'

export const RegisterContext = createContext({})

const RegisterProvider = props => {
    const [companyDataState, setCompanyDataState] = useState({
        fields: false,
        selectedCompany: false,
        editMode: false,
        loading: false,
    })
    const [authDataState, setAuthDataState] = useState({
        data: {
            email: null,
            password: null,
            terms: null
        },
        loading: false
    })
    const [screensState, setScreensState] = useState({
        currentScreen: 1
    })
    const [validation, setValidation] = useState({})
    const [loading, setLoading] = useState({
        vatNumber: true
    })

    /**
     * 
     * @param {boolean} vatNumber 
     */
    const setVatNumberLoading = vatNumber => {
        setLoading(prev => ({
            ...prev,
            vatNumber
        }))
    }

    /**
     * дали да се визуализира зареждачка на мястото на ДДС номер
     * @returns {boolean}
     */
    const getVatNumberLoading = () => loading.vatNumber

    const companyData = useMemo(() => (companyDataState), [companyDataState])
    const authData = useMemo(() => (authDataState), [authDataState])
    const screens = useMemo(() => (screensState), [screensState])
    const validationData = useMemo(() => (validation), [validation])

    const value = {
        companyData,
        setCompanyDataState,
        authData,
        setAuthDataState,
        screens,
        setScreensState,
        validationData,
        setValidation,
        setVatNumberLoading,
        getVatNumberLoading
    }

    return <RegisterContext.Provider value={value} {...props} />
}

export const useRegisterContext = () => useContext(RegisterContext)

export default RegisterProvider